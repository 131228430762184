import { useTracker } from '~/shared/analytics';
import {
  BodyPrimaryText,
  CardBase,
  Icon,
  Title,
  AppMarginDesktopOnly,
} from '~/shared/ui/kit';
import { Login } from '~/widgets/login';
import { useTranslation } from 'react-i18next';
import { ChangeLanguage } from '~/features/i18n/change-language';
import { AppLogoFilled, whiteLabelConfig } from '~/white-label';
import { EVENT_ID, pushDeferredEvent } from '~/shared/analytics/analytics';

export function LoginPage() {
  const tracker = useTracker();
  const { t } = useTranslation('common');

  return (
    <AppMarginDesktopOnly>
      <CardBase
        css={{
          maxWidth: 520,
          margin: '52px auto 32px auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 48,
          }}
        >
          <div css={{ width: 80 }}></div>
          <Icon
            css={{
              width: 32,
              height: 32,
            }}
          >
            <AppLogoFilled />
          </Icon>
          <ChangeLanguage
            css={{
              width: 80,
            }}
          />
        </div>
        <Title h={3} css={{ textAlign: 'center', marginBottom: 8 }} as="h3">
          {t('login.welcomeTitle', {
            appName: whiteLabelConfig.name,
          })}
        </Title>
        <BodyPrimaryText css={{ textAlign: 'center', marginBottom: 24 }}>
          {t('login.welcomeDescription', {
            appName: whiteLabelConfig.name,
          })}
        </BodyPrimaryText>
        <Login
          css={{ width: '100%' }}
          onBeforeLoginRedirect={(provider) => {
            pushDeferredEvent(EVENT_ID.LOGIN, 'login.success', {
              provider: provider,
            });
          }}
          onLoginFailure={(provider, message) => {
            tracker.track('login.failure', {
              provider: provider,
              message: message,
            });
          }}
          onLoginStart={(provider) => {
            tracker.track('login.start', {
              provider: provider,
            });
          }}
          onLoginSuccess={(provider) =>
            tracker.track('login.success', {
              provider: provider,
            })
          }
        ></Login>
      </CardBase>
    </AppMarginDesktopOnly>
  );
}
