import styled from '@emotion/styled';
import { CopyButton } from '~/shared/ui/kit';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { toast } from '~/shared/toasts';
import { useTranslation } from 'react-i18next';

const CopyAddressContainer = styled.div((props) => ({
  display: 'inline-flex',
  alignItems: 'center',

  [CopyButton.toString()]: {
    marginLeft: props.theme.spacer,
  },
}));

export function CopyAddress({
  address,
  maxShownLength = 10,
}: {
  address: string;
  maxShownLength?: number;
}) {
  const { t } = useTranslation('common');

  return (
    <CopyAddressContainer>
      <span>{trimMiddle(address, maxShownLength)}</span>
      <CopyButton
        copyText={address}
        onCopied={() => {
          toast.success(t('glossary.addressCopied'));
        }}
      />
    </CopyAddressContainer>
  );
}
