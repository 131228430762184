import { Controller, useForm } from 'react-hook-form';
import { isAddress } from '@ethersproject/address';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Buttons, InputBase, InputText, Title } from '~/shared/ui/kit';
import { FormCard, Label } from './styles';
import invariant from 'tiny-invariant';
import { MerchantResponse, useMerchantContext } from '~/entities/merchant';
import { useTranslation } from 'react-i18next';

type FormValues = {
  webhook: string;
  address: string;
};

const settingsSchema = yup.object().shape({
  webhook: yup.string().url().required('Webhook is required'),
  address: yup
    .string()
    .required('Address is required')
    .test(
      'is-address',
      'Address is not valid',
      (value) => !!value && isAddress(value)
    ),
});

const resolver = yupResolver(settingsSchema);

export function MerchantSettingsForm({
  webhook,
  address,
  onMerchantUpdated,
}: FormValues & {
  onMerchantUpdated: (data: MerchantResponse) => void;
}) {
  const { t } = useTranslation('merchant');
  const { api } = useMerchantContext();
  invariant(api, 'Merchant api is not available');

  const form = useForm({
    values: {
      webhook,
      address,
    },
    defaultValues: {
      webhook,
      address,
    },
    resolver,
  });

  const submit = async ({
    webhook,
    address,
  }: {
    webhook: string;
    address: string;
  }) => {
    const res = await api.updateMerchant({
      webhookUrl: webhook,
      receivingAddress: address,
    });
    onMerchantUpdated(res);
  };

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <FormCard>
        <Title h={3} as="div" css={{ marginBottom: 20 }}>
          {t('dashboard.webhookLink')}
        </Title>
        <Label css={{ marginBottom: 4 }}>{t('dashboard.link')}</Label>
        <Controller
          control={form.control}
          name="webhook"
          render={({ field, fieldState }) => {
            return (
              <InputBase errorMessage={fieldState.error?.message}>
                <InputText {...field}></InputText>
              </InputBase>
            );
          }}
        ></Controller>
      </FormCard>

      <FormCard css={{ marginTop: 20, marginBottom: 20 }}>
        <Title h={3} as="div" css={{ marginBottom: 20 }}>
          {t('dashboard.receivingAddress')}
        </Title>
        <Label css={{ marginBottom: 4 }}>{t('dashboard.address')}</Label>
        <Controller
          control={form.control}
          name="address"
          render={({ field, fieldState }) => {
            return (
              <InputBase errorMessage={fieldState.error?.message}>
                <InputText {...field}></InputText>
              </InputBase>
            );
          }}
        ></Controller>
      </FormCard>

      <Buttons nButtons={1}>
        <Button semanticType="primary" isLoading={form.formState.isSubmitting}>
          {t('dashboard.save')}
        </Button>
      </Buttons>
    </form>
  );
}
