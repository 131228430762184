import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Recaptcha } from '~/shared/recaptcha';

import { Button, Buttons, InputBase, InputText } from '~/shared/ui/kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncAction } from '~/shared/hooks/useAsyncStatus';
import { useNavigate } from 'react-router-dom';
import { sendLoginLink } from '~/entities/merchant';
import { ErrorMessage } from '~/shared/ui/kit/input-base/input-base';
import { isValidEmail } from '~/shared/validation/is-valid-email';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '~/app/routing';

const schema: yup.ObjectSchema<{
  email: string;
  captchaToken: string;
}> = yup.object().shape({
  email: yup
    .string()
    .required('Type your email here')
    .email("That's not a valid email")
    .test(isValidEmail()),
  captchaToken: yup.string().required("You must prove you're not a robot"),
});

const resolver = yupResolver(schema);

export function MerchantLoginForm() {
  const { t } = useTranslation('merchant');
  const submitEmail = useAsyncAction(sendLoginLink);
  const navigate = useNavigate();

  const form = useForm({
    resolver,
    defaultValues: {
      email: '',
      captchaToken: '',
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: { email: string; captchaToken: string }) => {
    try {
      const result = await submitEmail.run(data);
      navigate(RoutePath.MerchantLoginSuccess(), {
        state: {
          ticket: result.data.ticket,
        },
      });
    } catch (error) {
      navigate(RoutePath.MerchantLoginFail());
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Controller
        control={form.control}
        name="email"
        render={({ field, fieldState }) => {
          return (
            <InputBase errorMessage={fieldState.error?.message}>
              <InputText
                label={t('login.emailFormLabel') || ''}
                {...field}
              ></InputText>
            </InputBase>
          );
        }}
      ></Controller>
      <Controller
        control={form.control}
        name="captchaToken"
        render={({ field }) => {
          return (
            <Recaptcha
              onChange={field.onChange}
              css={{ marginTop: 24 }}
              size="normal"
            />
          );
        }}
      ></Controller>

      {submitEmail.isError && <ErrorMessage>{}</ErrorMessage>}
      <Buttons nButtons={1} css={{ marginTop: 56 }}>
        <Button
          semanticType="primary"
          disabled={!form.formState.isValid}
          isLoading={submitEmail.isLoading}
        >
          {t('login.sendMeLink')}
        </Button>
      </Buttons>
    </form>
  );
}
