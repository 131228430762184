export const TokenMaticIcon = () => (
  <svg
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_194_7175)">
      <path
        d="M18.7845 7.03786C18.3413 6.78457 17.7714 6.78457 17.2648 7.03786L13.7187 9.1275L11.3125 10.4573L7.82977 12.5469C7.38651 12.8002 6.81661 12.8002 6.31003 12.5469L3.58717 10.9005C3.14391 10.6472 2.8273 10.1407 2.8273 9.57075V6.40463C2.8273 5.89806 3.08059 5.39148 3.58717 5.07487L6.31003 3.49181C6.75329 3.23852 7.32319 3.23852 7.82977 3.49181L10.5526 5.13819C10.9959 5.39148 11.3125 5.89806 11.3125 6.46796V8.5576L13.7187 7.1645V5.01154C13.7187 4.50496 13.4655 3.99839 12.9589 3.68177L7.89309 0.705623C7.44983 0.452333 6.87993 0.452333 6.37335 0.705623L1.18092 3.7451C0.674341 3.99839 0.421051 4.50496 0.421051 5.01154V10.9638C0.421051 11.4704 0.674341 11.977 1.18092 12.2936L6.31003 15.2698C6.75329 15.5231 7.32319 15.5231 7.82977 15.2698L11.3125 13.2435L13.7187 11.8504L17.2015 9.82404C17.6447 9.57075 18.2146 9.57075 18.7212 9.82404L21.4441 11.4071C21.8873 11.6604 22.2039 12.167 22.2039 12.7369V15.903C22.2039 16.4096 21.9507 16.9161 21.4441 17.2328L18.7845 18.8158C18.3413 19.0691 17.7714 19.0691 17.2648 18.8158L14.5419 17.2328C14.0987 16.9795 13.7821 16.4729 13.7821 15.903V13.8767L11.3758 15.2698V17.3594C11.3758 17.866 11.6291 18.3726 12.1357 18.6892L17.2648 21.6653C17.7081 21.9186 18.278 21.9186 18.7845 21.6653L23.9136 18.6892C24.3569 18.4359 24.6735 17.9293 24.6735 17.3594V11.3438C24.6735 10.8372 24.4202 10.3306 23.9136 10.014L18.7845 7.03786Z"
        fill="#1D1C1E"
      />
    </g>
    <defs>
      <clipPath id="clip0_194_7175">
        <rect
          width="24.3158"
          height="21.213"
          fill="white"
          transform="translate(0.421051 0.578949)"
        />
      </clipPath>
    </defs>
  </svg>
);
