import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { Tracker } from './connector';

export const AnalyticsContext = createContext({
  track: (event: string, properties?: any) => {},
  identify: (id: string) => {},
});

export const AnalyticsPropsContext = createContext({
  props: {},
});

const createWrapperdAnalytics = (analytics: any, props: any) => {
  return {
    track: (event: string, properties?: any) => {
      analytics.track(event, { ...props, ...properties });
    },
    identify: (id: string) => {
      analytics.identify(id, props);
    },
  };
};

export const useTracker = () => {
  const analytics = useContext(AnalyticsContext);
  const { props } = useContext(AnalyticsPropsContext);

  const wrappedAnalytics = createWrapperdAnalytics(analytics, props);

  return wrappedAnalytics;
};

export const AnalyticsPropertiesProvider = ({
  children,
  props,
}: PropsWithChildren<{ props: any }>) => {
  return (
    <AnalyticsPropsContext.Provider value={{ props }}>
      {children}
    </AnalyticsPropsContext.Provider>
  );
};

export const analytics = {
  useOnMountEvent(event: string, properties?: any) {
    const analytics = useTracker();
    useEffect(() => {
      analytics.track(event, properties);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  },
};

export function AnalyticsProvider({ children }: PropsWithChildren) {
  return (
    <AnalyticsContext.Provider value={Tracker}>
      {children}
    </AnalyticsContext.Provider>
  );
}
