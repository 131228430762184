import { IconSize, OutlinedIcon } from '~/shared/ui/kit';
import { TokenMaticIcon } from '~/shared/ui/kit/icon/icons/tokens/matic';
import { UserToken } from '../tokens';

const tokenColors: Record<string, string> = {
  MATIC: '#E9DDFF',
};

export const TokenIcon = ({
  token,
  size = 'medium',
  ...rest
}: {
  token: UserToken;
  size?: IconSize | number;
}) => {
  const isMatic = token.symbol === 'MATIC';

  return (
    <OutlinedIcon
      size={size}
      color={tokenColors[token.symbol] || undefined}
      {...rest}
    >
      {token.logo ? (
        <img src={token.logo} alt={token.name} />
      ) : isMatic ? (
        <TokenMaticIcon />
      ) : (
        // TODO: add token icon logo
        <img src={''} />
      )}
    </OutlinedIcon>
  );
};
