import { MerchantConfig } from '~/features/top-up';

export function MerchantFavicon({
  merchant,
  ...rest
}: {
  merchant: MerchantConfig;
}) {
  return (
    <div {...rest}>
      <img
        src={merchant.address_icon || ''}
        alt={merchant.address_name || ''}
      />
    </div>
  );
}
