import styled from '@emotion/styled';
import { CardCarcass } from '../card-base/card-base';
import { px } from '~/shared/utils/css-helpers';
import { media } from '../styles/media-query';

export const ModalContentContainer = styled(CardCarcass)((props) => ({
  position: 'relative',
  padding: `${px(props.theme.spacer * 10)} ${px(props.theme.spacer * 6)}`,

  marginTop: '56px',
  borderRadius: 28,

  ...media(
    { max: 'md' },
    {
      padding: 24,
      marginLeft: 12,
      marginRight: 12,
      marginBottom: 12,
    }
  ),

  ...media('md', {
    padding: props.theme.spacer * 10,
    marginLeft: '12px',
    marginRight: '12px',
  }),
}));

export const ModalContentContainerMobileFullScreen = styled(CardCarcass)(
  (props) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    ...media(
      { max: 'md' },
      {
        padding: 16,
        margin: 12,
        marginBottom: 12,
        minHeight: 'calc(100% - 24px)',
        borderRadius: 24,
        maxWidth: 520,
      }
    ),
    ...media(
      { min: 'md', max: 'lg' },
      {
        padding: 20,
        borderRadius: 28,
        margin: 16,
        marginBottom: 16,
        minHeight: 'calc(100% - 32px)',
        maxWidth: 520,
      }
    ),
    ...media(
      { min: 'lg' },
      {
        padding: 40,
        borderRadius: 28,
        marginTop: '56px',
        marginLeft: '12px',
        marginRight: '12px',
      }
    ),
  })
);
