import { Navigate, Outlet } from 'react-router-dom';
import { useMerchantContext } from '~/entities/merchant';
import { MerchantHeader } from '~/widgets/header';
import { useMerchantSettings } from '../data-hooks';
import { InactiveMerchantBanner } from './ui/inactive-merchant-banner';

function MerchantAuthorized() {
  const merchant = useMerchantSettings();
  const isInactive = merchant.data?.data.is_active === false;

  return (
    <div>
      <MerchantHeader />
      {isInactive && <InactiveMerchantBanner />}
      <Outlet />
    </div>
  );
}

export function MerchantDashboard() {
  const { isAuthenticated } = useMerchantContext();

  if (!isAuthenticated) {
    return <Navigate to="/merchant/login" />;
  }

  return <MerchantAuthorized />;
}
