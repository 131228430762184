import { Token } from '~/entities/tokens';
import { TokenItem } from '~/entities/tokens/ui/TokenItem';
import { ListOfCards } from '~/shared/ui/kit/list-of-cards';

export function SelectToken({
  tokens,
  selectedToken,
  onSelectToken,
}: {
  tokens: Token[];
  selectedToken?: Token | null;
  onSelectToken: (token: Token) => void;
}) {
  return (
    <ListOfCards>
      {tokens.map((token) => (
        <TokenItem
          key={token.symbol}
          token={token}
          isClickable={true}
          onClick={(e) => {
            onSelectToken(token);
          }}
        />
      ))}
    </ListOfCards>
  );
}
