import {
  Icon,
  WalletIcon,
  LogoutIcon,
  ActionMenu,
  ActionMenuItem,
} from '~/shared/ui/kit';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useRef, useState } from 'react';
import { useClickOutside } from '~/shared/hooks/useClickOutside';
import { useMerchantContext } from '~/entities/merchant';
import { useTranslation } from 'react-i18next';
import { useMerchantSettings } from '~/pages/merchant-zone/data-hooks';
const AccountAddress = styled.div((props) => ({
  overflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 500,
  pointerEvents: 'none',
}));

export function AccountMenu() {
  const { t } = useTranslation('common');
  const { logout } = useMerchantContext();

  const settings = useMerchantSettings();

  const [isOpen, setIsOpen] = useState(false);
  const openerEl = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  useClickOutside({
    onClickedOutside: (e: MouseEvent) => {
      setIsOpen(false);
    },
    ref: openerEl,
    shouldListen: isOpen,
  });

  return (
    <ActionMenu
      actions={
        <>
          <ActionMenuItem
            color={theme.colors.critical[100]}
            icon={<LogoutIcon />}
            onClick={() => {
              logout();
              window.location.reload();
            }}
          >
            {t('glossary.logOut')}
          </ActionMenuItem>
        </>
      }
    >
      <Icon css={{ marginRight: 8 }}>
        <WalletIcon />
      </Icon>
      <AccountAddress>{settings.data?.data.email}</AccountAddress>
    </ActionMenu>
  );
}
