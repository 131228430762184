import { formatFiatValue } from '~/entities/tokens/utils';
import { Transaction } from '~/entities/transactions';
import {
  Button,
  Buttons,
  CheckOutlinedIcon,
  Delimiter,
  FormItemDisplay,
  Icon,
  ModalHeading,
  OutlinedIcon,
} from '~/shared/ui/kit';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useFiatValue } from '~/shared/fiat-converter';
import { ViewInExplorer } from '~/entities/explorer';
import { BigNumber } from '@ethersproject/bignumber';
import { SafeTransactionResponse } from '~/entities/transactions/lib/types';
import { useTranslation } from 'react-i18next';
import { TransactionReceipt as TTRansactionReceipt } from 'viem';
import { TransactionNetworkFee } from '~/shared/signing-transactions';
import { Nft, TransferredNft } from 'alchemy-sdk';
import { NftItemCard } from '~/entities/nfts/ui/NftItemCard';
import { NftStatus } from '~/entities/nfts/ui/NftStatus';

const ReceiptSummary = styled.div((props) => ({
  backgroundColor: props.theme.colors.success[20],
  borderRadius: 28,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

type TransactionReceiptErc721Props = React.PropsWithChildren<{
  nftTransaction: TransferredNft;
  receipt: TTRansactionReceipt;
  onClose: (e: React.MouseEvent) => void;
}>;

export function TransactionReceiptErc721({
  nftTransaction,
  receipt,
  onClose,
  ...rest
}: TransactionReceiptErc721Props) {
  const { t } = useTranslation('common');
  const theme = useTheme();

  // const fiatValue = useFiatValue({
  //   value: nftTransaction.value,
  //   symbol: nftTransaction.asset,
  // });

  const fee = receipt.gasUsed * receipt.effectiveGasPrice;
  return (
    <>
      {
        //TODO move title out
      }
      <ModalHeading css={{ marginBottom: 20 }}>
        {t('sendTokens.sentSuccesfully')}
      </ModalHeading>
      <NftStatus
        nft={nftTransaction}
        status="success"
        css={{
          marginTop: 16,
          marginBottom: 24,
        }}
      ></NftStatus>

      <FormItemDisplay
        name={t('glossary.from')}
        value={trimMiddle(nftTransaction.from, 20)}
        css={{ marginBottom: 24 }}
      />
      <FormItemDisplay
        name={t('glossary.to')}
        value={trimMiddle(nftTransaction.to || '', 20)}
        css={{ marginBottom: 24 }}
      />

      <Delimiter css={{ marginBottom: 24 }} />

      <TransactionNetworkFee
        feeMatic={BigNumber.from(fee.toString())}
        css={{ marginBottom: 24 }}
      />
      <ViewInExplorer
        txHash={nftTransaction.transactionHash}
        css={{ marginBottom: 24 }}
      />

      <Buttons nButtons={1}>
        <Button semanticType="primary" onClick={(e) => onClose(e)}>
          {t('sendTokens.backToWallet')}
        </Button>
      </Buttons>
    </>
  );
}
