import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import {
  BodyPrimaryText,
  CardBase,
  InputBase,
  InputText,
  KeyIcon,
  OutlinedIcon,
  Title,
} from '~/shared/ui/kit';
import { Label } from './styles';

export function ApiKeys({
  apiKey,
  secretKey,
  ...rest
}: {
  apiKey: string | undefined;
  secretKey: string | undefined;
}) {
  const { t } = useTranslation('merchant');
  const isMissingKeys = !apiKey || !secretKey;
  const theme = useTheme();

  const Keys = (
    <>
      <div css={{ marginBottom: 20 }}>
        <Label css={{ marginBottom: 4 }}>{t('dashboard.clientApiKey')}</Label>
        <InputBase>
          <InputText
            value={apiKey || t('dashboard.missingApiKey') || ''}
            disabled={!isMissingKeys}
            readOnly
          ></InputText>
        </InputBase>
      </div>
      <div>
        <Label css={{ marginBottom: 4 }}>{t('dashboard.secretKey')}</Label>
        <InputBase>
          <InputText
            value={secretKey || t('dashboard.missingSecretKey') || ''}
            disabled={!isMissingKeys}
            readOnly
          ></InputText>
        </InputBase>
      </div>
    </>
  );

  const MissingKeys = (
    <>
      <div css={{ textAlign: 'center' }}>
        <OutlinedIcon css={{ marginBottom: 30 }}>
          <KeyIcon />
        </OutlinedIcon>
        <BodyPrimaryText css={{ color: theme.colors.neutral[60] }} as={'p'}>
          {t('dashboard.missingKeysDescription')}
        </BodyPrimaryText>
      </div>
    </>
  );

  return (
    <CardBase {...rest}>
      <Title h={3} as="div" css={{ marginBottom: 20 }}>
        {t('dashboard.apiKeysTitle')}
      </Title>
      {isMissingKeys ? MissingKeys : Keys}
    </CardBase>
  );
}
