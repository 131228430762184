import { useTheme } from '@emotion/react';
import { MerchantConfig } from '~/features/top-up';
import { FormItemDisplay, Title } from '~/shared/ui/kit';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { MerchantLogo } from './MerchantLogo';
import { useTranslation } from 'react-i18next';

export function MerchantDetails({
  merchant,
  ...rest
}: {
  merchant: MerchantConfig;
}) {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <div
      css={{
        padding: '24px',
        border: `1px dashed ${theme.colors.neutral[40]}`,
        borderRadius: '20px',
      }}
      {...rest}
    >
      <div css={{ textAlign: 'center' }}>
        <MerchantLogo merchant={merchant} />
        <Title h={4} as={'div'}>
          {merchant.address_name || 'XXX'}
        </Title>
      </div>
      <div
        css={{
          borderTop: `1px dashed ${theme.colors.neutral[40]}`,
          margin: '16px 0',
        }}
      ></div>
      <FormItemDisplay
        name={t('glossary.to')}
        value={trimMiddle(merchant.receiving_address, 20)}
      ></FormItemDisplay>
    </div>
  );
}
