import styled from '@emotion/styled';
import { NftCollectionMetadata } from '../types';

const NftCollectionTitleWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  fontWeight: 600,
  color: theme.colors.neutral[80],
  [NftCollectionImage.toString()]: {
    marginRight: 12,
  },
}));

const NftCollectionImage = styled.div(({ theme }) => ({
  width: 24,
  height: 24,
  backgroundColor: theme.colors.neutral[40],
  borderRadius: '50%',
  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
  },
}));

export function NftCollectionTitle({
  collection,
  ...rest
}: {
  collection: NftCollectionMetadata;
}) {
  const { name, img } = collection;

  return (
    <NftCollectionTitleWrapper {...rest}>
      <NftCollectionImage>
        {img && <img src={img} alt={name} />}
      </NftCollectionImage>
      <span>{name}</span>
    </NftCollectionTitleWrapper>
  );
}
