import { useState } from 'react';
import { Nft } from 'alchemy-sdk';
import { makeAutoObservable } from 'mobx';

class NftModel {
  activeNft: Nft | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async setActiveNft(token: Nft) {
    this.activeNft = token;
  }
}

export const nftModal = new NftModel();

export function useActiveNft() {
  return useState(nftModal.activeNft);
}
