import { Children, useState } from 'react';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { media } from '../styles/media-query';

const TabSwitcherContainer = styled.div(({ theme }) => ({
  display: 'block',
  padding: 4,
  backgroundColor: theme.colors.neutral[20],
  borderRadius: 16,
}));

const TabSwitcherUl = styled.ul(({ n }: { n: number }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${n}, 1fr)`,
  gap: 8,
}));

const TabSwitcherItem = styled.li<{
  isActive: boolean;
}>(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px 8px',
  color: isActive ? theme.colors.neutral[100] : theme.colors.neutral[60],
  fontWeight: 600,
  borderRadius: 12,
  backgroundColor: isActive ? theme.colors.neutral[10] : 'transparent',
  cursor: isActive ? 'default' : 'pointer',
  boxShadow: isActive ? `2px 4px 20px 0px rgba(18, 23, 70, 0.06)` : 'none',

  fontSize: 16,
  ...media('lg', {
    fontSize: 20,
  }),
}));

export function TabSwitcher({
  activeTab,
  setActiveTab,
  children,
  ...rest
}: {
  children: React.ReactNode;
  activeTab: number;
  setActiveTab: (i: number) => void;
}) {
  const nodes = Children.map(children, (child, i) => {
    return <a onClick={() => setActiveTab(i)}>{child}</a>;
  })?.filter((v) => !!v);

  return (
    <TabSwitcherContainer {...rest}>
      <TabSwitcherUl n={nodes?.length || 0}>
        {nodes?.map((node, i) => (
          <TabSwitcherItem
            key={node.key}
            isActive={i === activeTab}
            onClick={() => setActiveTab(i)}
          >
            {node}
          </TabSwitcherItem>
        ))}
      </TabSwitcherUl>
    </TabSwitcherContainer>
  );
}
