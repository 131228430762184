import { TransactionLoader } from '~/entities/transactions/transaction-loader';
import { BodyPrimaryText, Title, LoadingDots } from '~/shared/ui/kit';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TransactionReceiptErc20 } from './transaction-receipt-erc20';
import { TransactionReceiptErc721 } from './transaction-receipt-erc721';

const Container = styled.div({
  minHeight: 400,
});

export function TransactionReceipt({
  txHash,
  onClose,
}: {
  txHash: string;
  onClose: (e: React.MouseEvent) => void;
}) {
  const { t } = useTranslation('common');

  return (
    <TransactionLoader
      txHash={txHash}
      renderTransaction={({ transaction, nftTransaction, receipt }) => {
        if (nftTransaction) {
          return (
            <Container>
              <TransactionReceiptErc721
                nftTransaction={nftTransaction}
                receipt={receipt}
                onClose={onClose}
              ></TransactionReceiptErc721>
            </Container>
          );
        } else if (transaction) {
          return (
            <Container>
              <TransactionReceiptErc20
                transaction={transaction}
                receipt={receipt}
                onClose={onClose}
              />
            </Container>
          );
        }

        // Should never happen, as TransactionLoader only invokes renderTransaction with one of the two
        return <div>null</div>;
      }}
      renderLoading={(status) =>
        status === 'waiting' ? (
          <Container>
            <Title
              as="h3"
              h={3}
              css={{ marginTop: 56, marginBottom: 24, textAlign: 'center' }}
            >
              {t('sendTokens.statusWaitingTitle')}
              <LoadingDots />
            </Title>
            <BodyPrimaryText as="p" css={{ textAlign: 'center' }}>
              {t('sendTokens.statusDescription')}
            </BodyPrimaryText>
          </Container>
        ) : (
          <Container>
            <Title
              as="h3"
              h={3}
              css={{ marginTop: 56, marginBottom: 24, textAlign: 'center' }}
            >
              {t('sendTokens.statusFetchingTitle')}
              <LoadingDots />
            </Title>
            <BodyPrimaryText as="p" css={{ textAlign: 'center' }}>
              {t('sendTokens.statusDescription')}
            </BodyPrimaryText>
          </Container>
        )
      }
    ></TransactionLoader>
  );
}
