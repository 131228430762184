import useSWR from 'swr';
import { useWeb3AuthContext } from './Web3AuthProvider';

export function useUserInfo() {
  const w3a = useWeb3AuthContext();

  const userInfo = useSWR(
    () => {
      if (!w3a.web3auth) return false;

      return 'user-info';
    },
    () => {
      return w3a.web3auth.getUserInfo();
    }
  );

  return userInfo;
}
