import { useTheme } from '@emotion/react';
import { PropsWithChildren } from 'react';
import { ToastContainer as _ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckOutlinedIcon, Icon } from '../ui/kit';
import './toast.css';

export function ToastContainer(props: PropsWithChildren) {
  const theme = useTheme();

  return (
    <_ToastContainer
      position="top-center"
      limit={1}
      closeButton={false}
      hideProgressBar={true}
      draggable={false}
      newestOnTop={true}
      pauseOnFocusLoss={false}
      style={
        {
          '--toastify-toast-background': theme.colors.neutral[80],
          '--toastify-color-light': theme.colors.neutral[80],
          '--toastify-text-color-light': theme.colors.neutral[10],
        } as any
      }
      {...props}
    />
  );
}

export const toastSuccess = (message: string) => {
  toast(
    <div
      css={{
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <span>{message}</span>
      <Icon
        size={24}
        css={{
          marginLeft: 12,
        }}
      >
        <CheckOutlinedIcon />
      </Icon>
    </div>
  );
};
