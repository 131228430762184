import { ENV_QUV_BACKEND } from '~/app/config';
export const baseURL = ENV_QUV_BACKEND;

interface ErrorDetails {
  loc: string[];
  msg: string;
  type: string;
}
interface ErrorResponse {
  detail: ErrorDetails[];
}

export interface FailedFetch<T = ErrorResponse> extends Error {
  isFailedFetch: true;
  response?: T;
}

export function isSimpleDetails(
  failedFetch: FailedFetch<any>
): failedFetch is FailedFetch<{
  detail: string;
}> {
  return (
    failedFetch.response !== undefined &&
    failedFetch.response.detail !== undefined &&
    typeof failedFetch.response.detail === 'string'
  );
}
export function errorResponseMessage(failedFetch: FailedFetch): string {
  if (!failedFetch.response) {
    return failedFetch.message;
  }

  const detail = failedFetch.response?.detail;

  if (!detail) {
    return failedFetch.message;
  } else if (isSimpleDetails(failedFetch)) {
    return failedFetch.response?.detail;
  }

  return firstErrorMessage(failedFetch.response?.detail ?? []);
}

export function firstErrorMessage(details: ErrorDetails[]) {
  if (details.length === 0) {
    return '';
  }

  return details[0].msg;
}
function isErrorResponse(response: any): response is ErrorResponse {
  return response.detail !== undefined;
}

export function isFailedFetch(error: any): error is FailedFetch {
  return error.isFailedFetch !== undefined && error.isFailedFetch === true;
}

async function makeFetchError(response: Response): Promise<FailedFetch> {
  const body = await response.json();
  let error = new Error(response.statusText) as FailedFetch;
  error.isFailedFetch = true;

  if (isErrorResponse(body)) {
    error.response = body;
  }

  return error;
}

export const baseHeaders = {
  'Content-Type': 'application/json',
};

export type ValidationErrorType = {
  loc: string[];
  msg: string;
  type: string;
};

export type ResponseType<T> = {
  data: T;
};

export type PaginationResponseType<T> = {
  data: T[];
  total: number;
  size: number;
};

export type ValidationResponseType = {
  detail: ValidationErrorType[];
};

export function endpoint(path: `/v1/${string}`) {
  return baseURL + path;
}

export const checkResponseCode = async (response: Response) => {
  if (!response.ok) {
    throw await makeFetchError(response);
  }

  return response;
};

export function isValidationError(
  response: ResponseType<any> | ValidationResponseType | undefined
): response is ValidationResponseType {
  return (
    !!response && (response as ValidationResponseType).detail !== undefined
  );
}

export function isResponse(
  response: ResponseType<any> | ValidationErrorType | undefined
) {
  return !!response && (response as ResponseType<any>).data !== undefined;
}
