import { QuvioOrder } from '~/features/top-up/api';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

// http://localhost:3000/buy?address=123&amount=1&symbol=MATIC

export function serializeParams(params: QuvioOrder) {
  return JSON.stringify(params);
}

export const isValidParams = (params: Partial<QuvioOrder>) => {
  return (
    params.order_id &&
    params.signature &&
    params.amount &&
    params.currency &&
    params.client_id
  );
};

function getPersistedParams(): QuvioOrder | null {
  const persistedQuery = localStorage.getItem('buy-params');
  if (!persistedQuery) {
    return null;
  }

  try {
    const params: QuvioOrder = JSON.parse(persistedQuery);
    if (isValidParams(params)) {
      return params;
    }
  } catch (error) {
    return null;
  }

  return null;
}

export const useQuvioParams = () => {
  const [query, setQuery] = useSearchParams();
  const quvioParams: Partial<QuvioOrder> = {
    order_id: query.get('order_id') || '',
    signature: query.get('signature') || '',
    amount: query.get('amount') || '',
    currency: query.get('currency') || '',
    client_id: query.get('client_id') || '',
    redirect_url: query.get('redirect_url') || '',
    email: query.get('email') || '',
    sumsub_token: query.get('sumsub_token') || '',
  };

  const queryParams: QuvioOrder | null = isValidParams(quvioParams)
    ? (quvioParams as QuvioOrder)
    : null;

  const serializedParams = queryParams ? serializeParams(queryParams) : null;
  const persistedParams: QuvioOrder | null = getPersistedParams();

  const params = queryParams || persistedParams;

  useEffect(() => {
    if (serializedParams !== null) {
      localStorage.setItem('buy-params', serializedParams);
    }
  }, [serializedParams]);

  useEffect(() => {
    if (serializedParams !== null) {
      console.debug('Set query', {
        query,
      });

      const currentQuery = Object.fromEntries(query.entries());

      // @ts-ignore
      setQuery((query) => ({
        order_id: queryParams?.order_id,
        signature: queryParams?.signature,
        amount: queryParams?.amount,
        currency: queryParams?.currency,
        client_id: queryParams?.client_id,
        redirect_url: queryParams?.redirect_url,
        email: queryParams?.email,
        sumsub_token: queryParams?.sumsub_token,
        ...currentQuery,
      }));
    }
  }, [serializedParams]);
  return params;
};
