import { MATIC_TOKEN } from '~/entities/tokens/tokens';
import { formatFiatValue, formatTokenAmount } from '~/entities/tokens/utils';
import { useFiatValue } from '~/shared/fiat-converter';
import { FormItemDisplay } from '~/shared/ui/kit';
import BigNumber from 'bignumber.js';
import { BigNumber as EBigNumber } from '@ethersproject/bignumber';
import { toEthers } from '~/shared/ethereum/units';
import { useTranslation } from 'react-i18next';

export function TransactionNetworkFee({
  feeMatic,
  ...rest
}: {
  feeMatic: BigNumber | EBigNumber;
}) {
  const { t } = useTranslation('common');

  const feeFiat = useFiatValue({
    value: toEthers(feeMatic.toString(), MATIC_TOKEN.decimals).toUnsafeFloat(),
    symbol: MATIC_TOKEN.symbol,
  });

  if (!feeFiat.price || !feeFiat.value) {
    return (
      <FormItemDisplay
        name={t('glossary.networkFee')}
        value={`– ${MATIC_TOKEN.name} (${feeFiat.symbol}–)`}
        {...rest}
      />
    );
  }
  return (
    <FormItemDisplay
      name={t('glossary.networkFee')}
      value={`${formatTokenAmount(feeMatic.toString(), MATIC_TOKEN.decimals)} ${
        MATIC_TOKEN.name
      } (${formatFiatValue(feeFiat.value, feeFiat.symbol)})`}
      {...rest}
    />
  );
}
