import { createBrowserRouter } from 'react-router-dom';
import { FF_PLAYNANCE_INTEGRATION } from '../feature-flags';

import { IframeApp } from '../entries/iframe-app';
import { WalletApp } from '../entries/wallet-app';
import { isNotNull } from '~/shared/ts-utilities';

interface RouteParams {
  search?: string | URLSearchParams;
}

const withSearch = (path: string, search?: string) => {
  if (!search) {
    return path;
  }

  return `${path}?${search}`;
};
export const RoutePath = {
  Onramp: (params?: RouteParams) =>
    withSearch(`/onramp`, params?.search?.toString()),
  Offramp: (params?: RouteParams) =>
    withSearch(`/offramp`, params?.search?.toString()),
  MerchantLogin: (params?: RouteParams) =>
    withSearch(`/merchant/login`, params?.search?.toString()),
  MerchantLoginSuccess: (params?: RouteParams) =>
    withSearch(`/merchant/login/success`, params?.search?.toString()),
  MerchantLoginFail: (params?: RouteParams) =>
    withSearch(`/merchant/login-fail`, params?.search?.toString()),
  MerchantDashboard: (params?: RouteParams) =>
    withSearch(`/merchant/dashboard`, params?.search?.toString()),
  PayWithQuvio: {
    Status: (
      params: RouteParams & {
        transferId: string;
      }
    ) =>
      withSearch(`/buy/${params.transferId}/status`, params.search?.toString()),
  },
};
export const router = createBrowserRouter(
  [
    FF_PLAYNANCE_INTEGRATION
      ? {
          path: '/iframe/*',
          element: <IframeApp />,
        }
      : null,
    {
      path: '/*',
      element: <WalletApp />,
    },
  ].filter(isNotNull),
  {}
);
