import { EventType, OnErrorEvent, WidgetEvent } from '@nearpay/nearpay-sdk';
import { useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { NearPayWidget } from '~/features/top-up';
import { analytics, useTracker } from '~/shared/analytics';
import { LinkButton } from '~/shared/ui/kit';
import { useBuyPageContext } from '../shared';
import { useReturnToMerchant } from '../ui/BackButton';
import { WertWidget, WertWidgetEvent } from '~/features/top-up/ui/wert-widget';
import { addWertStatusTimeParam } from '../fix-wert-status';

// 2c9b1ddef2b733a5577bfe65e2e9ff3da345ae53fdd22e64688040129f2786f6
// 4eef9c13-a867-4e43-a8f9-e296fe449f30
/*
export interface WidgetParams {
    toCurrency?: string;
    toAmount?: string;
    toWallet?: string;
    merchantOrderId?: string;
    apiKey?: string;
    contractCall?: ContractCall;
} 
*/
export function PaymentView() {
  const account = useAccount();

  const { params, config, merchant } = useBuyPageContext();
  const callback = useReturnToMerchant(params);
  const navigate = useNavigate();
  const routerParams = useParams();
  const transferId = routerParams.transferId;
  const [search] = useSearchParams();
  const tracker = useTracker();
  analytics.useOnMountEvent('buy_tokens.payment');

  const onNearpayEvent = useCallback(
    (e: WidgetEvent) => {
      if (
        e.type === EventType.Onoperationsuccess ||
        e.type === EventType.Onoperationfail
      ) {
        const outcome =
          e.type === EventType.Onoperationsuccess
            ? 'operation_success'
            : 'operation_failure';

        tracker.track('buy_tokens.payment.munzen-widget.' + outcome);
        navigate(`/buy/${transferId}/status?${search}`, {
          replace: true,
        });
      } else if (e.type === EventType.Onerror) {
        let error = e as OnErrorEvent;
        tracker.track('buy_tokens.payment.munzen-widget.widget_error', {
          error: error.payload.error,
        });
      } else {
        tracker.track('buy_tokens.payment.munzen-widget.' + e.type);
      }
    },
    [navigate, transferId, search, tracker, params.signature]
  );

  const onWertEvent = useCallback(
    (e: WertWidgetEvent) => {
      // console.debug('WERT EVENT', e.type, e);
      if (e.type === 'payment-status' && e.data.status !== undefined) {
        // Handle payment status update
        tracker.track('buy_tokens.payment.wert-widget.' + e.data.status);
        // Redirect user to QUV status page
        // future status update will be handled by QUV backend

        if (e.data.status === 'success') {
          navigate(
            addWertStatusTimeParam(`/buy/${transferId}/status?${search}`),
            {
              replace: true,
            }
          );
        }
      } else if (e.type === 'position' && e.data.step === 'error') {
        // Handle in-widget error (Wrong parameters, etc.)
        // We can not do much about this case, so just track it
        tracker.track('buy_tokens.payment.wert-widget.widget_error');
      } else if (e.type === 'position') {
        // Track widget position
        // Only used in analytics
        tracker.track('buy_tokens.payment.wert-widget.' + e.data.step);
      } else {
        // Track every other event
        tracker.track('buy_tokens.payment.wert-widget.' + e.type);
      }
    },
    [navigate, transferId, search, tracker]
  );

  if (!transferId || !account.address) {
    return <div></div>;
  }

  return (
    <div
      css={{
        width: '100%',
        maxWidth: '520px',
        minHeight: '500px',
        margin: '50px auto 20px auto',
        textAlign: 'center',
      }}
    >
      {merchant.widget === 'wert' ? (
        <WertWidget
          params={{
            commodity: config.wert.currencies[params.currency].commodity,
            network: config.wert.currencies[params.currency].network,
            commodity_amount: params.amount,
            address: account.address,
            email: params.email,
            sumsub_token: params.sumsub_token,
            click_id: transferId,
            partner_id: config.wert.partner_id,
          }}
          onEvent={onWertEvent}
          css={{
            marginBottom: '20px',
          }}
        />
      ) : (
        <NearPayWidget
          onEvent={onNearpayEvent}
          params={{
            toCurrency: params.currency,
            toAmount: params.amount,
            toWallet: account.address,
            email: params.email,
            sumsub_token: params.sumsub_token,
            merchantOrderId: transferId,
            apiKey: config.nearpay.api_key,
          }}
        />
      )}

      <LinkButton
        onClick={callback}
        css={{
          textAlign: 'center',
        }}
      >
        Cancel and return to Merchant
      </LinkButton>
    </div>
  );
}
