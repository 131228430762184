import { CopyIcon, Icon } from '../icon';
import copy from 'copy-to-clipboard';
import styled from '@emotion/styled';

const Button = styled.button<{ isLight: boolean }>(({ theme, isLight }) => {
  const colors = {
    base: isLight ? theme.colors.neutral[10] : theme.colors.neutral[100],
    hover: isLight ? theme.colors.neutral[40] : theme.colors.neutral[60],
    active: isLight ? theme.colors.neutral[20] : theme.colors.neutral[100],
  };

  return {
    color: colors.base,
    '&:hover': {
      color: colors.hover,
    },
    '&:active': {
      color: colors.active,
    },
    transition: 'color 0.08s ease-in-out',
    width: 20,
    height: 20,
    animation: 'none',
  };
});

function _CopyButton({
  copyText,
  onCopied,
  isLight = false,
  ...rest
}: {
  copyText: string;
  onCopied: (value: string) => void;
  isLight?: boolean;
}) {
  return (
    <Button
      isLight={isLight}
      onClick={() => {
        copy(copyText);
        onCopied(copyText);
      }}
      {...rest}
    >
      <Icon>
        <CopyIcon />
      </Icon>
    </Button>
  );
}

const _StyledCopyButton = styled.button({});

export const CopyButton = _StyledCopyButton.withComponent(_CopyButton);
