import { Nft } from 'alchemy-sdk';
import { SecondaryMaterial, media } from '~/shared/ui/kit';
import { NftThumbnail } from './NftThumbnail';
import { inheritNftCollectionMetadata } from '../utils';
import { trimMiddle } from '~/shared/utils/string-helpers';
import styled from '@emotion/styled';

const NftTitle = styled.h4({
  fontSize: 16,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '100%',
  width: 0,
  ...media('md', {
    fontSize: 20,
  }),
});

const NftContractAddress = styled.p({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: -0.28,
  ...media('md', {
    fontSize: 12,
  }),
});

const Thumbnail = styled(NftThumbnail)({
  width: 48,
  height: 48,
  borderRadius: 10,
  ...media('md', {
    width: 64,
    height: 64,
    borderRadius: 14,
  }),
});

export function NftItemCard({ nft, ...rest }: { nft: Nft }) {
  return (
    <SecondaryMaterial
      css={{
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 16,
        ...media('md', {
          borderRadius: 20,
        }),
        ...media('lg', {
          padding: 12,
          borderRadius: 24,
        }),
      }}
      {...rest}
    >
      <Thumbnail
        token={nft}
        css={{
          marginRight: 16,
        }}
      />

      <div
        css={{
          flexGrow: 1,
        }}
      >
        <NftTitle css={{ marginBottom: 4 }}>{nft.name}</NftTitle>
        <NftContractAddress>
          {trimMiddle(nft.contract.address, 16)}
        </NftContractAddress>
      </div>
    </SecondaryMaterial>
  );
}
