import { createContext, PropsWithChildren, useContext } from 'react';
import invariant from 'tiny-invariant';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { Web3AuthConnector } from '@web3auth/web3auth-wagmi-connector';

export type Web3AuthContextType = {
  web3auth: Web3AuthNoModal;
  isLoggedIn: boolean;
};

export const Web3AuthContext = createContext<Web3AuthContextType | null>(null);

export function useWeb3AuthContext(): Web3AuthContextType {
  const context = useContext(Web3AuthContext);
  invariant(context, 'Web3AuthContext is not initialized');
  return context;
}

export type Web3AuthProviderProps = PropsWithChildren<{
  connector: Web3AuthConnector;
  renderNotInitialized: () => JSX.Element;
}>;
