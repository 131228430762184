import { ListOfCards } from '~/shared/ui/kit';
import { LinkButton } from '~/shared/ui/kit/link-button';
import { useTheme } from '@emotion/react';
import { PropsWithChildren, useState } from 'react';
import { Transaction } from '..';
import {
  getTodayTransactions,
  groupTransactionsByDay,
  transformToSections,
} from '../lib/grouping';
import { TransactionItem } from './TransactionItem';
import { TransactionsEmpty } from './TransactionsEmpty';
import { useTranslation } from 'react-i18next';

type TransactionListProps = React.PropsWithChildren<{
  txs: Transaction[];
  account: string;
}>;

function TransactionListHeading({
  title,
  children,
}: PropsWithChildren<{
  title: string;
}>) {
  const theme = useTheme();

  return (
    <div>
      <p
        css={{ marginBottom: 8, fontSize: 14, color: theme.colors.neutral[60] }}
      >
        {title}
      </p>
      {children}
    </div>
  );
}

export function TransactionList({
  account,
  txs,
  ...rest
}: TransactionListProps) {
  const { t, i18n } = useTranslation('common');
  const [showAll, setShowAll] = useState(false);

  if (!txs.length) {
    return <TransactionsEmpty />;
  }

  const todayTxs = getTodayTransactions(txs);
  const withoutToday = txs.filter((tx) => !todayTxs.includes(tx));
  const byDay = groupTransactionsByDay(
    showAll
      ? txs.filter((tx) => !todayTxs.includes(tx))
      : txs.filter((tx) => !todayTxs.includes(tx)).slice(0, 6)
  );

  const hasMore = withoutToday.length > 6;

  const sections = [
    { title: t('glossary.today'), txs: todayTxs },
    ...transformToSections(byDay, i18n.language),
  ].filter((section) => section.txs.length);

  return (
    <ListOfCards spacing={4} {...rest}>
      {sections.map((section) => {
        return section.txs.map((tx, index) => {
          if (index === 0) {
            return (
              <TransactionListHeading title={section.title} key={tx.hash}>
                <TransactionItem account={account} tx={tx} />
              </TransactionListHeading>
            );
          }
          return <TransactionItem account={account} key={tx.hash} tx={tx} />;
        });
      })}
      {hasMore && !showAll && (
        <LinkButton
          onClick={() => {
            setShowAll(true);
          }}
        >
          {t('glossary.showAll')}
        </LinkButton>
      )}
    </ListOfCards>
  );
}
