import { useAccount } from 'wagmi';
import { useAccountTokens } from '~/entities/tokens';
import { UserToken } from '~/entities/tokens/tokens';
import { QuvioOrder } from '~/features/top-up';

const EMPTY_TOKEN = (currency: string): UserToken => ({
  symbol: currency,
  balance: '0x0',
  decimals: 18,
  name: currency,
  contract: null,
  logo: null,
});

export function usePaymentBalance(params: QuvioOrder) {
  const account = useAccount();
  const tokens = useAccountTokens({
    address: account.address,
  });
  const wantedToken = tokens.data?.data.find(
    (t) => t.symbol === params.currency
  );
  const token = wantedToken || EMPTY_TOKEN(params.currency);

  return token;
}
