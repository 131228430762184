export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3939 5.39412C17.7286 5.05938 18.2714 5.05938 18.6061 5.39412C18.9408 5.72885 18.9408 6.27157 18.6061 6.6063L13.2122 12.0002L18.6061 17.3941C18.9408 17.7289 18.9408 18.2716 18.6061 18.6063C18.2714 18.941 17.7286 18.941 17.3939 18.6063L12 13.2124L6.60609 18.6063C6.27135 18.941 5.72864 18.941 5.3939 18.6063C5.05917 18.2716 5.05917 17.7289 5.3939 17.3941L10.7878 12.0002L5.3939 6.6063C5.05917 6.27157 5.05917 5.72885 5.3939 5.39412C5.72864 5.05938 6.27135 5.05938 6.60609 5.39412L12 10.788L17.3939 5.39412Z"
      fill="currentColor"
    />
  </svg>
);
