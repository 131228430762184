import useSWR, { SWRResponse } from 'swr';
import invariant from 'tiny-invariant';
import {
  OwnedNftsResponse,
  Nft,
  GetFloorPriceResponse,
  FloorPriceMarketplace,
  FloorPriceError,
  NftOrdering,
  NftFilters,
} from 'alchemy-sdk';
import { alchemy } from '~/shared/ethereum/alchemy';
import { NftCollections } from './types';
import { inheritNftCollectionMetadata } from './utils';

const mostRecentDate = (a: string, b: string) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (dateA > dateB) {
    return a;
  }

  return b;
};

export function useAccountNfts({ address }: { address: string | undefined }): {
  ownedNftsByCollection: NftCollections;
  pageKey?: string;
  totalCount?: number;
} {
  const { data } = useSWR<OwnedNftsResponse>(
    () => (address ? 'nfts-' + address : null),
    async () => {
      invariant(address, 'address is required');
      const data = await alchemy.nft.getNftsForOwner(address, {
        orderBy: NftOrdering.TRANSFERTIME,
        excludeFilters: [NftFilters.SPAM],
      });

      return data;
    }
  );

  const ownedNftsByCollection: NftCollections = {};

  if (data) {
    const { ownedNfts, pageKey, totalCount } = data;

    ownedNfts.forEach((nft: Nft) => {
      const { contract } = nft;

      if (ownedNftsByCollection[contract.address]) {
        ownedNftsByCollection[contract.address].nfts.push(nft);
      } else {
        ownedNftsByCollection[contract.address] = {
          ...inheritNftCollectionMetadata(nft),
          mostRecentAcquire: mostRecentDate(
            nft.acquiredAt?.blockTimestamp || new Date(0).toISOString(),
            ownedNftsByCollection[contract.address]?.mostRecentAcquire ||
              new Date(0).toISOString()
          ),
          nfts: [nft],
        };
      }
    });

    return {
      ownedNftsByCollection,
      pageKey,
      totalCount,
    };
  }

  return {
    ownedNftsByCollection,
  };
}

function hasPriceInformation(
  price: FloorPriceMarketplace | FloorPriceError
): price is FloorPriceMarketplace {
  return 'error' in price === false;
}

function findAvailablePrice(response: GetFloorPriceResponse) {
  const prices = [];
  if (hasPriceInformation(response.looksRare)) {
    prices.push(response.looksRare);
  } else if (hasPriceInformation(response.openSea)) {
    prices.push(response.openSea);
  }

  return prices;
}

export function useNftPrice({ nft }: { nft: Nft }) {
  const swr = useSWR(
    `nft-price-${nft.contract}-${nft.tokenId}`,
    () => {
      return alchemy.nft.getFloorPrice(nft.contract.address);
    },
    {
      errorRetryCount: 2,
    }
  );

  const prices = swr.data ? findAvailablePrice(swr.data) : [];

  const firstPrice = prices[0];

  return { swr: swr, prices, firstPrice };
}
