import styled from '@emotion/styled';
import { PropsWithChildren, useState } from 'react';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { OpenloginLoginParams } from '@web3auth/openlogin-adapter';
import {
  BodyPrimaryText,
  ChevDownIcon,
  Icon,
  IconButton,
  media,
  NonWrappingButtons,
  useMediaQuery,
} from '~/shared/ui/kit';
import LoginAppleIcon from './provider-logo/login-apple.svg';
import LoginGoogleIcon from './provider-logo/login-google.svg';
import LoginFacebookIcon from './provider-logo/login-facebook.svg';
import LoginGithubIcon from './provider-logo/login-github.svg';
import LoginTwitterIcon from './provider-logo/login-twitter.svg';
import LoginRedditIcon from './provider-logo/login-reddit.svg';
import LoginDiscordIcon from './provider-logo/login-discord.svg';
import LoginTwitchIcon from './provider-logo/login-twitch.svg';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { PasswordBasedLogin } from './password-based-login';
import { web3AuthInstance } from '~/shared/ethereum/web3auth/connector/wagmi';

type LoginProviderOption = {
  icon: string;
  name: OpenloginLoginParams['loginProvider'];
};

const PROVIDER_BUTTONS: LoginProviderOption[] = [
  {
    name: 'google',
    icon: LoginGoogleIcon,
  },
  {
    name: 'apple',
    icon: LoginAppleIcon,
  },
  {
    name: 'twitch',
    icon: LoginTwitchIcon,
  },
  {
    name: 'facebook',
    icon: LoginFacebookIcon,
  },
  {
    name: 'github',
    icon: LoginGithubIcon,
  },
  {
    name: 'twitter',
    icon: LoginTwitterIcon,
  },
  {
    name: 'reddit',
    icon: LoginRedditIcon,
  },
  {
    name: 'discord',
    icon: LoginDiscordIcon,
  },
];

const LoginButton = styled(IconButton)((props) => ({
  width: 48,
  height: 48,
  borderRadius: 16,
  padding: 0,
  img: {
    width: 32,
    height: 32,
  },
  ...media('sm', {
    width: 72,
    height: 72,
    borderRadius: 20,
    padding: 0,
    img: {
      width: 48,
      height: 48,
    },
  }),
}));

function LoginOption({
  provider,
  onBeforeLoginRedirect,
  onLoginStart,
  onLoginSuccess,
  onLoginFailure,
}: {
  provider: LoginProviderOption;
  onBeforeLoginRedirect: (provider: string | undefined) => void;
  onLoginStart: (provider: string | undefined) => void;
  onLoginSuccess: (provider: string | undefined) => void;
  onLoginFailure: (provider: string | undefined, errorMessage: string) => void;
}) {
  return (
    <LoginButton
      key={provider.name}
      onClick={async () => {
        if (!web3AuthInstance) {
          console.debug(
            "Web3Auth isn't initialized yet for connection, skipping"
          );
          return;
        }

        onLoginStart && onLoginStart(provider.name);

        try {
          onBeforeLoginRedirect && onBeforeLoginRedirect(provider.name);
          await web3AuthInstance.connectTo<OpenloginLoginParams>(
            WALLET_ADAPTERS.OPENLOGIN,
            {
              redirectUrl: window.location.href,
              appState: window.location.search,
              loginProvider: provider.name,
            }
          );

          onLoginSuccess && onLoginSuccess(provider.name);
        } catch (error) {
          const message = (error || '').toString();
          onLoginFailure && onLoginFailure(provider.name, message);

          // if it's a duplicate connection error, reload the page
          if (message.includes('Already connected')) {
            window.location.reload();
            return;
          }

          throw error;
        }
      }}
    >
      <img src={provider.icon} alt={provider.name} />
    </LoginButton>
  );
}

/**
 * @param onBeforeLoginRedirect - when using 'redirect' mode for login, it is impossible to track the login process, so this callback is called before the redirect happens to allow for persisted state to be saved and recovered after the redirect
 * @returns
 */
export function Login({
  initialEmail,
  onLoginFailure,
  onBeforeLoginRedirect,
  onLoginStart,
  onLoginSuccess,
  ...rest
}: PropsWithChildren<{
  initialEmail?: string;
  onLoginStart: (provider: string | undefined) => void;
  onLoginSuccess: (provider: string | undefined) => void;
  onBeforeLoginRedirect: (provider: string | undefined) => void;
  onLoginFailure: (provider: string | undefined, errorMessage: string) => void;
}>) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const isSmall = useMediaQuery({ min: 'sm' });

  const buttonsCount = isSmall ? 5 : 4;
  const initiallyShown = PROVIDER_BUTTONS.slice(0, buttonsCount - 1);
  const initiallyHidden = PROVIDER_BUTTONS.slice(buttonsCount - 1);

  return (
    <div {...rest}>
      <PasswordBasedLogin
        css={{ marginBottom: 24 }}
        initialEmail={initialEmail}
        onBeforeLoginRedirect={onBeforeLoginRedirect}
        onLoginFailure={onLoginFailure}
        onLoginStart={onLoginStart}
        onLoginSuccess={onLoginSuccess}
      />

      <BodyPrimaryText
        css={{
          color: theme.colors.neutral[60],
          textAlign: 'center',
          marginBottom: 8,
        }}
        as="p"
      >
        {t('login.orContinueWith')}
      </BodyPrimaryText>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <NonWrappingButtons
          nButtons={buttonsCount}
          css={{
            justifyItems: 'center',
          }}
        >
          {initiallyShown.map((provider) => (
            <LoginOption
              provider={provider}
              key={provider.name}
              onBeforeLoginRedirect={onBeforeLoginRedirect}
              onLoginFailure={onLoginFailure}
              onLoginStart={onLoginStart}
              onLoginSuccess={onLoginSuccess}
            />
          ))}
          <LoginButton onClick={() => setIsOpen(!isOpen)}>
            <Icon
              size={28}
              css={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            >
              <ChevDownIcon />
            </Icon>
          </LoginButton>
          {isOpen &&
            initiallyHidden.map((provider) => (
              <LoginOption
                provider={provider}
                key={provider.name}
                onBeforeLoginRedirect={onBeforeLoginRedirect}
                onLoginFailure={onLoginFailure}
                onLoginStart={onLoginStart}
                onLoginSuccess={onLoginSuccess}
              />
            ))}
        </NonWrappingButtons>
      </div>
    </div>
  );
}
