import { PropsWithChildren } from 'react';
import { FiatBalance } from '~/shared/fiat-converter';
import { Transaction } from '../..';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  SublineText,
  TxInIcon,
  TxOutIcon,
  useMediaQuery,
} from '~/shared/ui/kit';
import { hasNoValue, trimAddressForSmall } from './shared';
import * as TransactionItemStyles from './styles';
import { ViewInExplorerMini } from '~/entities/explorer';
import { formatValue } from '~/entities/tokens/utils';
import { MATIC_TOKEN } from '~/entities/tokens/tokens';

const titles = {
  sent: 'transactions.sentNft',
  received: 'transactions.receivedNft',
  self: 'transactions.selfNft',
};

type NftTransactionItemProps = PropsWithChildren<{
  tx: Transaction;
  account: string;
  fiatValue?: FiatBalance;
}>;

function _NftTransactionItem({
  account,
  tx,
  ...rest
}: NftTransactionItemProps) {
  const { t } = useTranslation('common');

  const isMd = useMediaQuery('md');
  const isSent = tx.from.toLowerCase() === account.toLowerCase();
  const isSelf = tx.from.toLowerCase() === tx.to.toLowerCase();
  const isReceived = tx.to.toLowerCase() === account.toLowerCase();

  const title = isSelf
    ? t(titles.self)
    : isSent
    ? t(titles.sent)
    : t(titles.received);

  const subline = isSent
    ? t('glossary.to') + ': ' + trimAddressForSmall(tx.to, isMd)
    : isReceived
    ? t('glossary.from') + ': ' + trimAddressForSmall(tx.from, isMd)
    : account;

  return (
    <TransactionItemStyles.Container
      isOutlined={false}
      title={
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
          <ViewInExplorerMini
            txHash={tx.hash}
            size={14}
            css={{
              marginLeft: 4,
            }}
          />
        </div>
      }
      subline={<SublineText css={{ fontSize: 12 }}>{subline}</SublineText>}
      image={
        <TransactionItemStyles.IconContainer isReceived={isReceived}>
          {isReceived ? (
            <Icon>
              <TxInIcon />
            </Icon>
          ) : isSent ? (
            <Icon>
              <TxOutIcon />
            </Icon>
          ) : (
            <span>?</span>
          )}
        </TransactionItemStyles.IconContainer>
      }
      controls={
        <>
          {hasNoValue(tx.value) ? null : (
            <TransactionItemStyles.TxValue isReceived={isReceived}>
              {isReceived ? '+' : '-'}
              {formatValue(
                tx.value,
                tx.rawContract.decimal
                  ? Number.parseInt(tx.rawContract.decimal, 16)
                  : MATIC_TOKEN.decimals
              )}{' '}
              {tx.asset}
            </TransactionItemStyles.TxValue>
          )}
        </>
      }
      {...rest}
    ></TransactionItemStyles.Container>
  );
}

export const NftTransactionItem = styled(_NftTransactionItem)((props) => ({}));
