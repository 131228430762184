import {
  BodyPrimaryText,
  Button,
  Buttons,
  CopyButton,
  Modal,
  ModalConfirmationCallback,
  ModalHeading,
  NonWrappingButtons,
  SecondaryMaterial,
  Title,
} from '~/shared/ui/kit';
import { Label } from './styles';
import { Props as ReactModalProps } from 'react-modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { toast } from '~/shared/toasts';
import { useTranslation } from 'react-i18next';

const Confirmation = ({
  onConfirmationResponse,
}: {
  onConfirmationResponse: ModalConfirmationCallback;
}) => {
  const { t } = useTranslation('merchant');

  const secretKeyModalTitle = t('dashboard.secretKeyModalTitle');
  const secretKeyModalDescription = t('dashboard.secretKeyModalDescription');
  const secretKeyModalButtonCancel = t('dashboard.secretKeyModalButtonCancel');
  const secretKeyModalButtonConfirm = t(
    'dashboard.secretKeyModalButtonConfirm'
  );

  return (
    <div
      css={{
        marginTop: 40,
      }}
    >
      <ModalHeading css={{ textAlign: 'center' }}>
        {secretKeyModalTitle}
      </ModalHeading>
      <BodyPrimaryText
        css={{ textAlign: 'center', marginBottom: 28, marginTop: 24 }}
        as="p"
      >
        {secretKeyModalDescription}
      </BodyPrimaryText>
      <NonWrappingButtons nButtons={2}>
        <Button
          semanticType="secondary"
          onClick={(e) => {
            onConfirmationResponse(false, e);
          }}
        >
          {secretKeyModalButtonCancel}
        </Button>
        <Button
          semanticType="primary"
          onClick={(e) => {
            onConfirmationResponse(true, e);
          }}
        >
          {secretKeyModalButtonConfirm}
        </Button>
      </NonWrappingButtons>
    </div>
  );
};

const SecretKeyContainer = styled(SecondaryMaterial)(({ theme }) => ({
  position: 'relative',
  padding: '18px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 600,
  color: theme.colors.neutral[80],
}));

export function ApiKeysModal({
  secretKey,
  ...modalProps
}: ReactModalProps & {
  secretKey: string;
}) {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'merchant']);

  return (
    <Modal
      confirmClose={true}
      renderConfirm={(onConfirm) => (
        <Confirmation onConfirmationResponse={onConfirm} />
      )}
      css={{
        maxWidth: 480,
      }}
      renderChildren={(onCloseClick) => {
        return (
          <>
            <Title
              h={3}
              as="div"
              css={{ marginBottom: 8, textAlign: 'center' }}
            >
              {t('merchant:dashboard.secretKey')}
            </Title>
            <BodyPrimaryText
              as="p"
              css={{ marginBottom: 32, textAlign: 'center' }}
            >
              {t('merchant:dashboard.secretKeySaveDescription')}
            </BodyPrimaryText>
            <div>
              <Label
                css={{
                  marginBottom: 4,
                  fontWeight: 600,
                  color: theme.colors.neutral[60],
                }}
              >
                {t('merchant:dashboard.secretKey')}
              </Label>
              <SecretKeyContainer>
                <span
                  css={{
                    wordBreak: 'break-word',
                  }}
                >
                  {secretKey}
                </span>
                <CopyButton
                  css={{
                    marginLeft: 32,
                  }}
                  copyText={secretKey}
                  onCopied={(value) => {
                    toast.success(t('common:glossary.addressCopied'));
                  }}
                ></CopyButton>
              </SecretKeyContainer>
            </div>

            <Buttons nButtons={1} css={{ marginTop: 56 }}>
              <Button semanticType="primary" onClick={onCloseClick}>
                {t('merchant:dashboard.secretKeyConfirmButton')}
              </Button>
            </Buttons>
          </>
        );
      }}
      {...modalProps}
    ></Modal>
  );
}
