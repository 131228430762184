export const ReceiveIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_125_1256)">
      <path
        d="M14.25 19.5H18.75C19.1642 19.5 19.5 19.1642 19.5 18.75V14.25C19.5 13.8358 19.1642 13.5 18.75 13.5H14.25C13.8358 13.5 13.5 13.8358 13.5 14.25V18.75C13.5 19.1642 13.8358 19.5 14.25 19.5Z"
        stroke="#3E3A42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 10.5H18.75C19.1642 10.5 19.5 10.1642 19.5 9.75V5.25C19.5 4.83579 19.1642 4.5 18.75 4.5H14.25C13.8358 4.5 13.5 4.83579 13.5 5.25V9.75C13.5 10.1642 13.8358 10.5 14.25 10.5Z"
        stroke="#3E3A42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 19.5H9.75C10.1642 19.5 10.5 19.1642 10.5 18.75V14.25C10.5 13.8358 10.1642 13.5 9.75 13.5H5.25C4.83579 13.5 4.5 13.8358 4.5 14.25V18.75C4.5 19.1642 4.83579 19.5 5.25 19.5Z"
        stroke="#3E3A42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 10.5V7.5"
        stroke="#3E3A42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 4.5H7.5V10.5"
        stroke="#3E3A42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9H4.5"
        stroke="#3E3A42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6V4.5"
        stroke="#3E3A42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_125_1256">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24 24) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);
