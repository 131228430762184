import { SelectTokenStep } from './select-token-step';
import { SendFormStep, useSendForm } from './form';
import { useStepProgress } from '~/shared/hooks/useStepProgress';
import { AmountStep } from './amount-step';
import { ConfirmStep } from './confirm-step';
import { useTracker } from '~/shared/analytics';
import { useEffect } from 'react';
import { TransactionReceipt } from '~/entities/transactions/ui/TransactionReceipt/transaction-receipt';

// example MATIC hash: 0xc7c160e6d6b49f184f8fea18dbb97c2fc76523995fe2ca22902164dbeb1046fe
// example ERC20 hash: 0xb16e9738f93124c85a20d37a99bd6a849c9289c29dd6b85aa77d6858c319ff8b

export function SendFormSteps({
  onClose,
  onStepChange,
}: {
  onClose: (e: React.MouseEvent) => void;
  onStepChange?: (step: SendFormStep) => void;
}) {
  const form = useSendForm();
  const steps = useStepProgress<SendFormStep>();
  const tracker = useTracker();

  useEffect(() => {
    tracker.track('send_tokens.open.' + steps.step);
  }, [tracker, steps.step]);

  useEffect(() => {
    if (onStepChange) {
      onStepChange(steps.step);
    }
  }, [onStepChange, steps.step]);

  if (steps.step === 'selectToken') {
    return <SelectTokenStep />;
  } else if (steps.step === 'enterAmount') {
    return <AmountStep />;
  } else if (steps.step === 'confirm') {
    return <ConfirmStep />;
  } else if (steps.step === 'success') {
    return <TransactionReceipt txHash={form.transaction} onClose={onClose} />;
  }

  return <div></div>;
}
