import { useAccount } from 'wagmi';
import invariant from 'tiny-invariant';
import { Outlet } from 'react-router-dom';
import { Header } from '~/widgets/header';
import { AppMargin } from '~/shared/ui/kit';
import { ModalControlProvider } from '~/app/modal-control/modal-context';

export function WalletPage() {
  const { address } = useAccount();

  invariant(address, 'address should be defined');

  return (
    <div>
      <Header />
      <AppMargin>
        <ModalControlProvider>
          <Outlet />
        </ModalControlProvider>
      </AppMargin>
    </div>
  );
}
