import { QuvioOrder } from '~/features/top-up';
import { Button } from '~/shared/ui/kit';

export function useReturnToMerchant(params: QuvioOrder) {
  const callback = () => {
    if (params.redirect_url) {
      console.debug('Going back to provided redirectUrl', params.redirect_url);
      window.location.href = params.redirect_url;
    } else if (document.referrer) {
      console.debug('Going back to referrer', document.referrer);
      window.location.href = document.referrer;
    }
  };

  return callback;
}

export function ButtonBackToMerchant({ params }: { params: QuvioOrder }) {
  const callback = useReturnToMerchant(params);

  return (
    <Button semanticType="primary" onClick={callback}>
      Return to Merchant
    </Button>
  );
}
