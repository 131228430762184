import { ENV_RECAPTCHA_SITE_KEY } from '~/app/config';
import ReCAPTCHA from 'react-google-recaptcha';
import { forwardRef, useRef, ForwardedRef, MutableRefObject } from 'react';
import styled from '@emotion/styled';

const RECAPTCHA_PRIVACY_LINK =
  'https://www.google.com/intl/en/policies/privacy/';
const RECAPTCHA_TERMS_LINK = 'https://www.google.com/intl/en/policies/terms/';
const RECAPTCHA_LOGO = 'https://www.gstatic.com/recaptcha/api2/logo_48.png';

function isMutableRef<T extends any>(
  ref: ForwardedRef<T>
): ref is MutableRefObject<T> {
  return typeof ref === 'object' && ref !== null && 'current' in ref;
}

type ReCAPTCHASize = 'compact' | 'normal' | 'invisible';

const RecaptchaContainer = styled.div(() => ({
  width: '100%',
  display: 'flex',
  backgroundColor: '#f9f9f9',
  overflow: 'hidden',
  justifyContent: 'center',
  borderRadius: 20,
  padding: '4px 8px',
  border: `1px solid rgba(174, 175, 183, 0.20)`,
}));

const NEGATIVE_MARGIN = 4;
const RecaptchaContainerCropper = styled.div(() => ({
  width: 304 - NEGATIVE_MARGIN * 2,
  height: 78 - NEGATIVE_MARGIN * 2,
  overflow: 'hidden',
}));

export const Recaptcha = forwardRef<
  ReCAPTCHA | null,
  {
    onChange: (token: string) => void;
    size: ReCAPTCHASize;
  }
>(({ onChange, size, ...rest }, ref) => {
  if (size === 'invisible') {
    return (
      <div {...rest}>
        <ReCAPTCHA
          ref={(rc) => {
            if (typeof ref === 'function') {
              ref(rc);
            } else if (isMutableRef<ReCAPTCHA | null>(ref)) {
              ref.current = rc;
            }
          }}
          sitekey={ENV_RECAPTCHA_SITE_KEY}
          onChange={(token) => onChange(token || '')}
          size={size}
        ></ReCAPTCHA>
      </div>
    );
  }

  return (
    <div {...rest}>
      <RecaptchaContainer>
        <RecaptchaContainerCropper>
          <ReCAPTCHA
            ref={(rc) => {
              if (typeof ref === 'function') {
                ref(rc);
              } else if (isMutableRef<ReCAPTCHA | null>(ref)) {
                ref.current = rc;
              }
            }}
            sitekey={ENV_RECAPTCHA_SITE_KEY}
            onChange={(token) => onChange(token || '')}
            size={size}
            style={{
              margin: NEGATIVE_MARGIN * -1,
            }}
          ></ReCAPTCHA>
        </RecaptchaContainerCropper>
      </RecaptchaContainer>
    </div>
  );
});
