// replaces middle part of string with ellipsis
export function trimMiddle(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str;
  }

  const halfLength = Math.floor(maxLength / 2);
  const trimmed = str.slice(0, halfLength) + '...' + str.slice(-halfLength);
  return trimmed;
}

export function prettyPrintJson(json: any) {
  return JSON.stringify(json, null, 2);
}

export function limitLength(string: string, maxLength: number) {
  return string.length > maxLength
    ? string.slice(0, maxLength) + '...'
    : string;
}
