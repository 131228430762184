import styled from '@emotion/styled';
import { ENV_ONRAMPER_KEY } from '~/app/config';

const OnramperWidget = styled.iframe(({ theme }) => ({
  width: '100%',
  maxWidth: 420,
  height: 630,
}));

export function OfframpWidget({ ...rest }) {
  return (
    <OnramperWidget
      src={`https://buy.onramper.com/?supportSell=true&supportBuy=false&apiKey=${ENV_ONRAMPER_KEY}`}
      title="Onramper widget"
      allow="accelerometer; autoplay; camera; gyroscope; payment"
      {...rest}
    ></OnramperWidget>
  );
}
