export type DateISO = string;
export type AccessToken = {
  access_token: string;
  expiry_at: DateISO;
  refresh_token: string;
  refresh_token_expiry_at: DateISO;
};

export function fixUndefinedStatus(status: TransferStatus | undefined) {
  return status === undefined ? TransferStatus.NONE : status;
}

export enum TransferStatus {
  // Only used on frontend to replace undefined values
  NONE = -99999999,
  ORDER_BLOCKCHAIN_ERROR = -2,
  ORDER_FIAT_ERROR = -1,
  ORDER_CREATED = 0,
  ORDER_FIAT_ACCEPTED = 1,
  ORDER_FIAT_COMPLETED = 2,
  ORDER_COMPLETED = 5,
  BLOCKCHAIN_WAITING_FOR_CONFIRMATION = 50,
}

export function isErrorStatus(status: TransferStatus) {
  return status < 0 && status !== TransferStatus.NONE;
}
