import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EmailStep } from './steps/email';
import { OtpStep } from './steps/otp';
import { SecondaryMaterial, Title } from '~/shared/ui/kit';

export function PasswordBasedLogin({
  initialEmail,
  onBeforeLoginRedirect,
  onLoginStart,
  onLoginSuccess,
  onLoginFailure,
  ...rest
}: {
  initialEmail?: string;
  onBeforeLoginRedirect: (provider: string | undefined) => void;
  onLoginStart: (provider: string | undefined) => void;
  onLoginSuccess: (provider: string | undefined) => void;
  onLoginFailure: (provider: string | undefined, errorMessage: string) => void;
}) {
  const { t } = useTranslation('common');
  const [data, setData] = useState<{ email: string; ticket: string }>({
    email: '',
    ticket: '',
  });

  return (
    <SecondaryMaterial {...rest}>
      <Title
        h={3}
        as="h3"
        css={{
          textAlign: 'center',
          marginBottom: '1rem',
        }}
      >
        {t('login.signInWithEmailTitle')}
      </Title>
      {!data.email ? (
        <EmailStep
          initialEmail={initialEmail}
          onDone={(data) => {
            onLoginStart('email');
            setData(data);
          }}
        />
      ) : (
        <OtpStep
          email={data.email}
          ticket={data.ticket}
          onBeforeLoginRedirect={() => {
            onBeforeLoginRedirect('email');
          }}
          onDone={() => {
            onLoginSuccess('email');
          }}
          onGoBack={() =>
            setData({
              email: '',
              ticket: '',
            })
          }
          onError={(error) => {
            onLoginFailure('email', error);
          }}
        />
      )}
    </SecondaryMaterial>
  );
}
