import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDisconnect } from 'wagmi';

type LogoutContextType = {
  logout: () => Promise<void>;
};

const LogoutContext = createContext<LogoutContextType>({
  logout: () => Promise.resolve(),
});

export function LogoutHandler(props: {
  children: React.ReactNode;
  renderDisconnecting: React.ReactNode;
}) {
  const { disconnectAsync } = useDisconnect();
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const logout = useCallback(async () => {
    try {
      setIsDisconnecting(true);
      await disconnectAsync();
    } finally {
      setIsDisconnecting(false);
    }
  }, [disconnectAsync, setIsDisconnecting]);

  const logoutContextValue = useMemo(() => {
    return { logout };
  }, [logout]);

  return (
    <LogoutContext.Provider value={logoutContextValue}>
      {isDisconnecting ? props.renderDisconnecting : props.children}
    </LogoutContext.Provider>
  );
}

export function useLogout() {
  const logout = useContext(LogoutContext).logout;
  return logout;
}
