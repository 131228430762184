import { NftTransferForm, TransferFormStep } from '~/features/transfer-nfts';
import {
  BodyPrimaryText,
  Button,
  Modal,
  ModalConfirmationCallback,
  ModalHeading,
  NonWrappingButtons,
} from '~/shared/ui/kit';
import { Props as ReactModalProps } from 'react-modal';
import { Nft } from 'alchemy-sdk';
import {
  NftTransferFormValues,
  nftTransferModel,
} from '~/features/transfer-nfts/model';
import { observer } from 'mobx-react';

const ModalConfirmation = ({
  onConfirmationResponse,
}: {
  onConfirmationResponse: ModalConfirmationCallback;
}) => {
  return (
    <div
      css={{
        marginTop: 40,
      }}
    >
      <ModalHeading css={{ textAlign: 'center' }}>
        Do you want to cancel NFT sending?
      </ModalHeading>
      <BodyPrimaryText
        css={{ textAlign: 'center', marginBottom: 28, marginTop: 24 }}
        as="p"
      >
        The entered information will not be saved
      </BodyPrimaryText>
      <NonWrappingButtons nButtons={2}>
        <Button
          semanticType="secondary"
          onClick={(e) => {
            onConfirmationResponse(false, e);
          }}
        >
          Continue
        </Button>
        <Button
          semanticType="primary"
          onClick={(e) => {
            onConfirmationResponse(true, e);
          }}
        >
          Exit
        </Button>
      </NonWrappingButtons>
    </div>
  );
};

function _TransferNftsModal(
  props: ReactModalProps & {
    nft: Nft | null;
    formValues: NftTransferFormValues;
  }
) {
  // If it has filled address and does not have hash yet
  const shouldConfirmBeforeClosing =
    !!props.formValues.recipient && !props.formValues.transactionHash;
  return (
    <Modal
      {...props}
      confirmClose={shouldConfirmBeforeClosing}
      renderConfirm={(onConfirm) => (
        <ModalConfirmation onConfirmationResponse={onConfirm} />
      )}
      css={{
        maxWidth: 480,
        width: '100%',
      }}
      renderChildren={(onCloseClick) => {
        return props.nft ? (
          <NftTransferForm
            onClose={(e: React.MouseEvent) => {
              onCloseClick(e);
              // console.debug('ONCLOSE');
              //props.onRequestClose ? props.onRequestClose(e) : null;
            }}
          />
        ) : (
          ''
        );
      }}
    ></Modal>
  );
}

export const TransferNftsModal = observer((props: ReactModalProps) => {
  return (
    <_TransferNftsModal
      nft={nftTransferModel.token}
      formValues={nftTransferModel.formValues}
      onAfterClose={() => {
        nftTransferModel.reset();
      }}
      {...props}
    ></_TransferNftsModal>
  );
});
