import {
  endpoint,
  checkResponseCode,
  ResponseType,
  baseHeaders,
  PaginationResponseType,
} from '~/shared/api';
import { prefixedAddress } from '~/shared/ethereum/address';
import { AccessToken, TransferStatus } from './types';

type MerchantLoginLinkResponse = ResponseType<{
  detail: string;
  // Only for dev environments, to avoid sending email
  ticket?: string;
}>;

export async function sendLoginLink({
  captchaToken,
  email,
}: {
  captchaToken: string;
  email: string;
}): Promise<MerchantLoginLinkResponse> {
  let url = endpoint('/v1/merchants/auth');

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      captcha_answer: captchaToken,
      email,
    }),
    headers: {
      ...baseHeaders,
    },
  }).then(checkResponseCode);

  return response.json();
}

type DateISO = string;
type MerchantAuthorizeResponse = ResponseType<{
  access_token: string;
  expiry_at: DateISO;
  refresh_token: string;
  refresh_token_expiry_at: DateISO;
}>;

export type MerchantTransaction = {
  unique_id: string;
  order_id: string;
  email: string;
  recipient: string;
  status: TransferStatus;
  created_at: string;
  currency: string;
  amount: string;
  formatted_amount: string;
  tx_hash: string;
};

export async function authorizeMerchant({
  ticket,
}: {
  ticket: string;
}): Promise<MerchantAuthorizeResponse> {
  const url = endpoint('/v1/merchants/token');

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ticket,
    }),
    headers: {
      ...baseHeaders,
    },
  }).then(checkResponseCode);

  return response.json();
}

export type MerchantResponse = ResponseType<{
  is_active: boolean;
  email: string;
  webhook_url: string;
  receiving_address: string;
  client_id: string;
  client_secret: string;
}>;

export class MerchantApi {
  _token: AccessToken | null;
  onTokenUpdate: (token: AccessToken | null) => void;

  _mergedRefresh: Promise<void> | null = null;

  constructor(
    token: AccessToken | null,
    onTokenUpdate: (token: AccessToken | null) => void
  ) {
    this._token = token || null;
    this.onTokenUpdate = onTokenUpdate;
  }

  get token() {
    return this._token;
  }

  get _authorizationHeader() {
    if (!this.token) throw new Error('No token set');

    return {
      Authorization: `Bearer ${this.token?.access_token}`,
    };
  }

  async _catchInvalidToken(
    response: Response,
    retry: () => Promise<Response>,
    debug = false
  ) {
    if (response.status === 401 || debug) {
      this._mergedRefresh = this._mergedRefresh || this._refreshToken();
      await this._mergedRefresh;
      this._mergedRefresh = null;

      const retryResponse = await retry();

      if (retryResponse.status === 401) {
        this.onTokenUpdate(null);
        throw new Error('Invalid token');
      }

      return retryResponse;
    }

    return response;
  }

  async _refreshToken(): Promise<void> {
    const url = endpoint('/v1/merchants/token');

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${this.token?.access_token}`,
        ...baseHeaders,
      },
      body: JSON.stringify({
        refresh_token: this.token?.refresh_token,
      }),
    });

    if (response.status === 401) {
      this.onTokenUpdate(null);
      return;
    }

    const token = await response.json();
    this._token = token.data;
    this.onTokenUpdate(token.data);
  }

  setAccessToken(token: AccessToken) {
    this._token = token;
  }

  clearAccessToken() {
    this._token = null;
  }

  async getMerchant(): Promise<MerchantResponse> {
    const url = endpoint('/v1/merchants/me');

    const request = () =>
      fetch(url, {
        method: 'GET',
        headers: {
          ...this._authorizationHeader,
          ...baseHeaders,
        },
      });

    const response = await request()
      .then((res) => this._catchInvalidToken(res, request))
      .then(checkResponseCode);

    return response.json();
  }

  async updateMerchant({
    webhookUrl,
    receivingAddress,
  }: {
    webhookUrl: string;
    receivingAddress: string;
  }): Promise<MerchantResponse> {
    const url = endpoint('/v1/merchants/register');

    const request = () =>
      fetch(url, {
        method: 'POST',
        headers: {
          ...this._authorizationHeader,
          ...baseHeaders,
        },
        body: JSON.stringify({
          webhook_url: webhookUrl,
          receiving_address: prefixedAddress(receivingAddress),
        }),
      });

    const response = await request()
      .then((res) => this._catchInvalidToken(res, request))
      .then(checkResponseCode);

    return response.json();
  }

  async getTransactions(
    page: number
  ): Promise<PaginationResponseType<MerchantTransaction>> {
    const search = new URLSearchParams({
      page: page.toString(),
    });
    const url =
      endpoint('/v1/merchants/transactions') + '?' + search.toString();

    const request = () =>
      fetch(url, {
        method: 'GET',
        headers: {
          ...this._authorizationHeader,
          ...baseHeaders,
        },
      });

    const response = await request()
      .then((res) => this._catchInvalidToken(res, request))
      .then(checkResponseCode);

    const body =
      (await response.json()) as PaginationResponseType<MerchantTransaction>;
    body.size = body.size || 100;

    return body;
  }

  async __debugRefresh(): Promise<MerchantResponse> {
    const url = endpoint('/v1/merchants/me');

    const request = () =>
      fetch(url, {
        method: 'GET',
        headers: {
          ...this._authorizationHeader,
          ...baseHeaders,
        },
      });

    const response = await request()
      .then((res) => this._catchInvalidToken(res, request, true))
      .then(checkResponseCode);

    return response.json();
  }
}
