import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { CardBase, media, useMediaQuery } from '~/shared/ui/kit';

// TODO: add mobile padding
const _ViewContainer = styled(CardBase)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '520px',
  padding: '56px 28px',
  ...media('sm', {
    padding: 56,
  }),
}));

export type PaymentViewProps = PropsWithChildren<{
  topRightSlot?: React.ReactNode;
}>;

export function ViewContainer({
  topRightSlot,
  children,
  ...rest
}: PaymentViewProps) {
  const isSm = useMediaQuery({
    min: 'sm',
  });
  return (
    <_ViewContainer {...rest}>
      {topRightSlot && (
        <div
          css={{
            position: 'absolute',
            top: 8,
            right: isSm ? 56 : 28,
          }}
        >
          {topRightSlot}
        </div>
      )}
      {children}
    </_ViewContainer>
  );
}
