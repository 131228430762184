import { useTheme } from '@emotion/react';
import { SublineText, Title } from '~/shared/ui/kit';

export function PaymentAmount({
  amount,
  currency,
}: {
  amount: string;
  currency: string;
}) {
  const theme = useTheme();

  return (
    <span>
      <Title h={5} as={'span'}>
        {amount}
      </Title>{' '}
      <SublineText
        css={{
          color: theme.colors.neutral[60],
        }}
      >
        {currency}
      </SublineText>
    </span>
  );
}
