import styled from '@emotion/styled';
import React from 'react';
import { px } from '~/shared/utils/css-helpers';
import { ColorThemeType } from '../theme-provider';
import { media } from '../styles/media-query';

type CardListItemProps = React.PropsWithChildren<{
  // URL of an image or an image itself
  image?: JSX.Element | string;
  title: JSX.Element | string;
  subline?: JSX.Element | string;
  controls?: JSX.Element;
  isClickable?: boolean;
  isOutlined?: boolean;
}>;

const hovering = (theme: ColorThemeType) => ({
  '&:hover': {
    backgroundColor: theme.colors.neutral[20],
  },
});
const outline = (theme: ColorThemeType) => ({
  border: `2px solid ${theme.colors.neutral[30]}`,
});
const empty = {};

const Image = styled.div((props) => ({
  display: 'inline-flex',
  marginRight: px(props.theme.spacer * 3),
}));
const Title = styled.div((props) => ({
  fontWeight: 900,
  color: props.theme.colors.neutral[80],
  lineHeight: px(24),
}));
const Subline = styled.div((props) => ({
  lineHeight: 1,
}));
const Controls = styled.div((props) => ({}));

const LeftContainer = styled.div((props) => ({
  marginRight: 'auto',
}));
const RightContainer = styled.div((props) => ({
  textAlign: 'right',
}));

function _CardListItem({
  controls,
  title,
  subline,
  image,
  isOutlined = true,
  isClickable = false,
  ...rest
}: CardListItemProps) {
  return (
    <div {...rest}>
      <Image>{typeof image === 'string' ? <img src={image} /> : image}</Image>
      <LeftContainer>
        <Title>{title}</Title>
        <Subline>{subline}</Subline>
      </LeftContainer>
      {controls ? (
        <RightContainer>
          <Controls>{controls}</Controls>
        </RightContainer>
      ) : null}
    </div>
  );
}

export const CardListItem = styled(_CardListItem)<CardListItemProps>(
  ({ theme, isClickable = false }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: px(24),
    cursor: isClickable ? 'pointer' : 'default',

    ...(isClickable ? hovering(theme) : empty),
  })
);

export const OutlinedCardListItem = styled(_CardListItem)<CardListItemProps>(
  ({ theme, isClickable = false }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${px(theme.spacer * 3)} ${px(theme.spacer * 3)}`,
    borderRadius: px(24),
    cursor: isClickable ? 'pointer' : 'default',

    ...outline(theme),
    ...(isClickable ? hovering(theme) : empty),
    ...media('md', {
      padding: `${px(theme.spacer * 5)} ${px(theme.spacer * 5)}`,
    }),
  })
);
