import { Outlet } from 'react-router-dom';
import { MerchantApiProvider } from '~/entities/merchant';
import { merchantModel } from '~/entities/merchant/model';

export function MerchantZone() {
  return (
    <MerchantApiProvider model={merchantModel}>
      <Outlet />
    </MerchantApiProvider>
  );
}
