import classNames from 'classnames';
import './hamburger-menu.css';
import { HTMLAttributes } from 'react';

export function HamburgerMenu({
  isOpened,
  ...rest
}: HTMLAttributes<HTMLButtonElement> & { isOpened: boolean }) {
  return (
    <button
      className={classNames(
        'hamburger',
        'hamburger--3dy',
        isOpened ? 'is-active' : ''
      )}
      type="button"
      {...rest}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  );
}
