import { createContext, useContext, useState } from 'react';
import { ModalControls, useModalControl } from '~/shared/hooks/useModalControl';
import { NftInfoModal } from '~/widgets/nft-info-modal';
import { ReceiveModal } from '~/widgets/receive-modal';
import { SendTokensModal } from '~/widgets/send-tokens-modal';
import { TransferNftsModal } from '~/widgets/transfer-nfts-modal';

export enum ModalName {
  Receive = 'receive',
  SendTokens = 'send-tokens',
  NftInfo = 'nft-info',
  TransferNft = 'transfer-nft',
}

type ModalControlContextValue = ModalControls<ModalName>;

export const ModalControlContext = createContext<ModalControlContextValue>({
  close: () => {},
  isActive: (name: ModalName) => {
    return false;
  },
  open: (name: ModalName) => {},
});

export function useModalControlContext() {
  const context = useContext(ModalControlContext);
  return context;
}

export function ModalControlProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const modalControl = useModalControl();
  const { isActive, close } = modalControl;

  return (
    <ModalControlContext.Provider value={modalControl}>
      <ReceiveModal
        isOpen={isActive(ModalName.Receive)}
        onRequestClose={() => close()}
      />
      <SendTokensModal
        isOpen={isActive(ModalName.SendTokens)}
        onRequestClose={() => close()}
      />
      <NftInfoModal
        isOpen={isActive(ModalName.NftInfo)}
        onRequestClose={() => {
          close();
        }}
      />
      <TransferNftsModal
        isOpen={isActive(ModalName.TransferNft)}
        onRequestClose={() => {
          close();
        }}
      />
      {children}
    </ModalControlContext.Provider>
  );
}
