import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { media } from '~/shared/ui/kit';
import paymentArrow from './payment-arrow.svg';

const PaymentHeaderContainer = styled.div(({ theme }) => ({
  padding: 20,
  border: `1px solid ${theme.colors.neutral[30]}`,
  borderRadius: 20,
}));

const PaymentHeaderArrowContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: `url(${paymentArrow}) no-repeat 20px 50%`,
  backgroundSize: '76% auto',
  ...media('sm', {}),
});

export function PaymentHeader({
  children,
  amount,
  ...rest
}: PropsWithChildren<{ amount: React.ReactNode }>) {
  return (
    <PaymentHeaderContainer {...rest}>
      <div
        css={{
          textAlign: 'center',
          marginBottom: 20,
        }}
      >
        {amount}
      </div>
      <PaymentHeaderArrowContainer>{children}</PaymentHeaderArrowContainer>
    </PaymentHeaderContainer>
  );
}
