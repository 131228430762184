import { MATIC_DATA } from './shared/const';

/**
 * This function can handle UserToken / Token and TokenMetadata contract addresses
 * @param contractAddress
 * @returns
 */
export function isMatic(contractAddress: string | null | undefined) {
  return (
    contractAddress === MATIC_DATA.address ||
    contractAddress === null ||
    contractAddress === undefined
  );
}
