import styled from '@emotion/styled';
import { CopyAddress } from '~/features/copy-address';
import { CardBase, jakartaText } from '~/shared/ui/kit';
import { mixGradientOnBackground } from '~/shared/utils/color-helpers';
import { px } from '~/shared/utils/css-helpers';

const SummaryContainer = styled(CardBase)((props) => ({
  borderRadius: props.theme.spacer * 6,
  background: mixGradientOnBackground(
    props.theme.colors.summary.gradient,
    '#FFFFFF'
  ),
}));

const IntegerPart = styled.span((props) => ({
  ...jakartaText(props.theme),
  fontSize: px(52),
  fontWeight: 700,
  color: props.theme.colors.neutral[100],
}));

const FractionPart = styled.span((props) => ({
  ...jakartaText(props.theme),
  fontSize: px(32),
  fontWeight: 500,
  color: props.theme.colors.neutral[100],
}));

function BalanceHighlight({
  balance,
  precision = 2,
  symbol = '',
}: {
  balance: number;
  precision?: number;
  symbol?: string;
}) {
  const fractionPart = balance % 1;
  const sFractionPart = fractionPart
    .toPrecision(precision)
    .toString()
    .replace('0.', '');

  const integerPart = balance - fractionPart;

  return (
    <span>
      <IntegerPart>
        {symbol}
        {integerPart}
      </IntegerPart>
      <FractionPart>
        .{sFractionPart === '0' ? '00' : sFractionPart}
      </FractionPart>
    </span>
  );
}

export function Summary({
  account,
  balance,
  balanceSymbol,
}: {
  account: string;
  balance: number;
  balanceSymbol: string;
}) {
  return (
    <SummaryContainer>
      <CopyAddress address={account} />
      <div>
        <BalanceHighlight symbol={balanceSymbol} balance={balance} />
      </div>
    </SummaryContainer>
  );
}
