import { QuvioOrder } from '~/features/top-up';
import { BodyPrimaryText, Button, Buttons, Title } from '~/shared/ui/kit';
import { useReturnToMerchant } from '../ui/BackButton';
import { ViewContainer } from '../ui/ViewContainer';
import { useTranslation } from 'react-i18next';

export function ErrorView({
  error,
  params,
  ...rest
}: {
  error: Error | string;
  params: QuvioOrder;
}) {
  const { t } = useTranslation('common');

  const returnToMerchant = useReturnToMerchant(params);

  return (
    <ViewContainer {...rest}>
      <Title h={3} as="h3" css={{ marginBottom: 24 }}>
        Failed to prepare payment
      </Title>
      <BodyPrimaryText as="p" css={{ marginBottom: 36 }}>
        {error ? error.toString() : ''}
      </BodyPrimaryText>
      <Buttons nButtons={1}>
        <Button onClick={returnToMerchant} semanticType="primary">
          {t('paymentFlow.returnToMerchant')}
        </Button>
      </Buttons>
    </ViewContainer>
  );
}
