import styled from '@emotion/styled';

export const ListOfCards = styled.ul<{
  spacing?: number;
}>((props) => ({
  '> *': {
    marginBottom: props.theme.spacer * (props.spacing || 4),
  },
  '> *:last-child': {
    marginBottom: 0,
  },
}));
