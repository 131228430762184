import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './preflight.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { ColorTheme } from './shared/ui/kit';
import Modal from 'react-modal';
import { AnalyticsProvider } from './shared/analytics';
import { Tracker } from './shared/analytics';
import { checkIfFirstLoadAfterRedirect } from './shared/ethereum/web3auth';
import { ToastContainer } from './shared/toasts';
import './features/i18n';
import { router } from './app/routing';
import { EVENT_ID, retrieveDefferedEvent } from './shared/analytics/analytics';

//@ts-ignore
window.Intercom('boot', {});

if (checkIfFirstLoadAfterRedirect()) {
  const loginEvent = retrieveDefferedEvent(EVENT_ID.LOGIN);
  if (loginEvent) {
    Tracker.track(loginEvent.event, loginEvent.properties);
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Modal.setAppElement(document.getElementById('root') as HTMLElement);

function Root() {
  return (
    <React.StrictMode>
      <ColorTheme>
        <ToastContainer></ToastContainer>
        <AnalyticsProvider>
          <RouterProvider router={router} />
        </AnalyticsProvider>
      </ColorTheme>
    </React.StrictMode>
  );
}

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
