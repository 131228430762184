import { FetchTokenResult } from 'wagmi/dist/actions';
import { BigNumber } from 'ethers';

export const MATIC_DATA: FetchTokenResult = {
  address: `0x${'0'.repeat(40)}`,
  decimals: 18,
  name: 'Matic Token',
  symbol: 'MATIC',
  totalSupply: {
    formatted: '0',
    value: BigInt(0),
  },
};
