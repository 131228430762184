import React from 'react';

export interface ModalControls<T extends string = string> {
  open: (name: T) => void;
  close: () => void;
  isActive: (name: T) => boolean;
}

export const useModalControl = () => {
  const [activeModal, setActiveModal] = React.useState<string>('');

  return {
    open: (name: string) => setActiveModal(name),
    close: () => setActiveModal(''),
    isActive: (name: string) => activeModal === name,
  };
};
