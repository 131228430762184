import {
  SendFormProvider,
  SendFormStep,
  SendFormSteps,
} from '~/features/send-tokens';
import {
  BodyPrimaryText,
  Button,
  Modal,
  ModalConfirmationCallback,
  ModalHeading,
  NonWrappingButtons,
} from '~/shared/ui/kit';
import { Props as ReactModalProps } from 'react-modal';
import { useState } from 'react';

const Confirmation = ({
  onConfirmationResponse,
}: {
  onConfirmationResponse: ModalConfirmationCallback;
}) => {
  return (
    <div
      css={{
        marginTop: 40,
      }}
    >
      <ModalHeading css={{ textAlign: 'center' }}>
        Do you want to cancel the payment?
      </ModalHeading>
      <BodyPrimaryText
        css={{ textAlign: 'center', marginBottom: 28, marginTop: 24 }}
        as="p"
      >
        The entered information will not be saved
      </BodyPrimaryText>
      <NonWrappingButtons nButtons={2}>
        <Button
          semanticType="secondary"
          onClick={(e) => {
            onConfirmationResponse(false, e);
          }}
        >
          Continue
        </Button>
        <Button
          semanticType="primary"
          onClick={(e) => {
            onConfirmationResponse(true, e);
          }}
        >
          Exit
        </Button>
      </NonWrappingButtons>
    </div>
  );
};

export function SendTokensModal(props: ReactModalProps) {
  const [formStep, setFormStep] = useState<SendFormStep>();

  const shouldConfirmBeforeClosing =
    formStep === 'confirm' || formStep === 'enterAmount';

  return (
    <Modal
      {...props}
      confirmClose={shouldConfirmBeforeClosing}
      renderConfirm={(onConfirm) => (
        <Confirmation onConfirmationResponse={onConfirm} />
      )}
      css={{
        maxWidth: 480,
        width: '100%',
      }}
    >
      <SendFormProvider>
        <SendFormSteps
          onClose={(e) =>
            props.onRequestClose ? props.onRequestClose(e) : null
          }
          onStepChange={(step) => setFormStep(step)}
        />
      </SendFormProvider>
    </Modal>
  );
}
