import {
  WidgetParams,
  SignedWidgetParams,
} from '@nearpay/nearpay-sdk/dist/interfaces/widget-parameters';
import {
  EventType,
  makeSignatureString,
  NearPay,
  WidgetEvent,
} from '@nearpay/nearpay-sdk';
import useSWR from 'swr';
import { signTopUpParameters } from '../api';
import { useEffect, useRef } from 'react';
import { ENV_NETWORK } from '~/app/config';
import { signTopUpParametersLocally } from '~/pages/onramp/sign-locally';

const parseQuery = (
  link: string
): SignedWidgetParams & {
  email?: string;
  sumsub_token?: string;
} => {
  const url = new URL(link);
  const params = url.searchParams;

  return {
    signature: params.get('signature') || '',
    toWallet: params.get('toWallet') || '',
    toAmount: params.get('toAmount') || '',
    toCurrency: params.get('toCurrency') || '',
    email: params.get('email') || '',
    sumsub_token: params.get('sumsub_token') || '',
    merchantOrderId: params.get('merchantOrderId') || '',
    apiKey: params.get('apiKey') || '',
  };
};

export function NearPayWidget({
  params,
  onEvent,
  localSign = false,
  ...rest
}: {
  params: WidgetParams & {
    email?: string;
    sumsub_token?: string;
  };
  localSign?: boolean;
  onEvent?: (e: WidgetEvent) => void;
}) {
  const signatureString = makeSignatureString(params);

  const mountElement = useRef<HTMLDivElement | null>(null);
  const nearpay = useRef<NearPay | null>(null);

  const signedLink = useSWR(
    'sign-' + signatureString,
    () => {
      if (localSign) {
        return signTopUpParametersLocally(params);
      } else {
        return signTopUpParameters(params);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const hasElement = !!mountElement.current;

  useEffect(() => {
    if (!mountElement.current) {
      console.debug("Can't mount NearPay Widget, no element");
      return;
    } else if (!signedLink.data?.data.link) {
      //console.debug("Can't mount NearPay Widget, no signed link");
      return;
    }

    const signedParams = parseQuery(signedLink.data.data.link);

    const instance = new NearPay({
      mountElement: mountElement.current,
      environment: ENV_NETWORK === 'testnet' ? 'stage' : 'production',
      params: signedParams,
    });

    let _onEvent = onEvent || (() => {});
    instance.addListener(EventType.Any, _onEvent);

    nearpay.current = instance;
    try {
      nearpay.current.init();
      console.debug('Mounted NearPay Widget', instance);
    } catch (error) {
      console.debug('Failed to mount', error);
    }

    return () => {
      instance.removeListener(EventType.Any, _onEvent);
    };
  }, [signedLink.data?.data.link, hasElement, onEvent]);

  if (!signedLink.data) {
    return <div>loading...</div>;
  }

  return <div ref={mountElement}></div>;
}
