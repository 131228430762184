import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { StepState } from './types';

function getFontWeight(state: StepState, theme: Theme) {
  return state === 'active' ? 700 : 500;
}

function getTextColor(state: StepState, theme: Theme) {
  return state === 'active'
    ? theme.colors.neutral[100]
    : state === 'finished'
    ? theme.colors.purple[100]
    : theme.colors.neutral[60];
}

function getOutlineColor(state: StepState, theme: Theme) {
  return state === 'active'
    ? theme.colors.purple[80]
    : state === 'finished'
    ? theme.colors.purple[100]
    : theme.colors.neutral[30];
}

export const StepName = styled.div<{ state: StepState }>(
  ({ theme, state }) => ({
    color: getTextColor(state, theme),
    fontWeight: 700,
    fontSize: 12,
    margin: '0 4px',
  })
);

// Wrap number in outer container to compensate for the height change
export const StepNumberContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  height: 36,
});

const gradient = (from: string, to: string) => {
  return `linear-gradient(0deg, ${from} 0%, ${to} 100%)`;
};
const isTransparent = (prevState: StepState, nextState: StepState) => {
  return prevState === 'void' || nextState === 'void';
};

export const LeftLineJoin = styled.div<{
  prevState: StepState;
  nextState: StepState;
}>(({ prevState, nextState, theme }) => ({
  flexGrow: 1,
  height: 1,
  background: isTransparent(prevState, nextState)
    ? 'transparent'
    : gradient(
        getOutlineColor(prevState, theme),
        getOutlineColor(nextState, theme)
      ),
}));

export const RightLineJoin = styled.div<{
  prevState: StepState;
  nextState: StepState;
}>(({ prevState, nextState, theme }) => ({
  flexGrow: 1,
  height: 1,
  background: isTransparent(prevState, nextState)
    ? 'transparent'
    : gradient(
        getOutlineColor(prevState, theme),
        getOutlineColor(nextState, theme)
      ),
}));

export const StepNumber = styled.div<{ state: StepState }>(
  ({ theme, state }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: state === 'active' ? 36 : 24,
    height: state === 'active' ? 36 : 24,

    borderRadius: '50%',
    border: `1px solid ${getOutlineColor(state, theme)}`,
    color: getTextColor(state, theme),
    fontWeight: getFontWeight(state, theme),
    fontSize: 14,
  })
);

export const StepContainer = styled.div<{ state: StepState }>({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const StepsContainer = styled.div<{ stepsCount: number }>(
  ({ stepsCount }) => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: `repeat(${stepsCount}, 1fr)`,
  })
);
