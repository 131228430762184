export const WalletIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00006 3C3.34321 3 2.00006 4.34315 2.00006 6V18C2.00006 19.6569 3.34321 21 5.00006 21H19.0001C20.6569 21 22.0001 19.6569 22.0001 18V6C22.0001 4.34315 20.6569 3 19.0001 3H5.00006ZM4.00006 6C4.00006 5.44772 4.44778 5 5.00006 5H19.0001C19.5523 5 20.0001 5.44772 20.0001 6V7H7.00006C6.44778 7 6.00006 7.44772 6.00006 8C6.00006 8.55228 6.44778 9 7.00006 9H20.0001V11H18.0001C16.3432 11 15.0001 12.3431 15.0001 14C15.0001 15.6569 16.3432 17 18.0001 17H20.0001V18C20.0001 18.5523 19.5523 19 19.0001 19H5.00006C4.44778 19 4.00006 18.5523 4.00006 18V6ZM20.0001 15V13H18.0001C17.4478 13 17.0001 13.4477 17.0001 14C17.0001 14.5523 17.4478 15 18.0001 15H20.0001Z"
      fill="#3E3A42"
    />
  </svg>
);
