import { Nft } from 'alchemy-sdk';
import { makeAutoObservable } from 'mobx';

export type NftTransferFormValues = {
  token: Nft | null;
  recipient: string;
  transactionHash: string;
};

export class NftTransferModel {
  token: Nft | null = null;
  recipient: string = '';
  transactionHash: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.token = null;
    this.recipient = '';
    this.transactionHash = '';
  }

  updateToken(token: Nft) {
    this.reset();
    this.token = token;
  }

  get formValues(): NftTransferFormValues {
    return {
      token: this.token,
      recipient: this.recipient,
      transactionHash: this.transactionHash,
    };
  }

  updateFormValues(values: Partial<NftTransferFormValues>) {
    this.token = values.token || this.token;
    this.recipient = values.recipient || this.recipient;
    this.transactionHash = values.transactionHash || this.transactionHash;
  }
}

export const nftTransferModel = new NftTransferModel();
