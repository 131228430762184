import { Token } from '~/entities/tokens';
import { StepProgressProvider } from '~/shared/hooks/useStepProgress';
import React, { PropsWithChildren } from 'react';

type SendFormValues = {
  token: Token | null;
  receiverAddress: string;
  amount: string;
  transaction: string;
};

export const SendFormContext = React.createContext<
  SendFormValues & { setValues: (v: Partial<SendFormValues>) => void }
>({
  token: null,
  receiverAddress: '0x8aC7Ba450bEa997fE00b2dd1bd6fD844521d7e55',
  amount: '0.005',
  transaction: '',
  setValues: (values) => {},
});

export type SendFormStep =
  | 'selectToken'
  | 'enterAmount'
  | 'confirm'
  | 'success';
const sendFormSteps: SendFormStep[] = [
  'selectToken',
  'enterAmount',
  'confirm',
  'success',
];

export const useSendForm = () => {
  const contextValue = React.useContext(SendFormContext);

  return contextValue;
};

export const SendFormProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [formValues, setFormValues] = React.useState<SendFormValues>({
    token: null,
    receiverAddress: '',
    amount: '',
    transaction: '',
  });

  return (
    <SendFormContext.Provider
      value={{
        ...formValues,
        setValues: (values) =>
          setFormValues({
            ...formValues,
            ...values,
          }),
      }}
    >
      <StepProgressProvider steps={sendFormSteps}>
        {children}
      </StepProgressProvider>
    </SendFormContext.Provider>
  );
};
