import { findLocaleFromLanguage } from '~/features/i18n';
import { Transaction } from '..';

export function isToday(timestamp: number) {
  const today = new Date();
  const date = new Date(timestamp);
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function getTodayTransactions(txs: Transaction[]) {
  return txs.filter((tx) =>
    isToday(new Date(tx.metadata.blockTimestamp).getTime())
  );
}

export function sortByDate(txs: Transaction[]) {
  return txs.sort((a, b) => {
    const aDate = new Date(a.metadata.blockTimestamp).getTime();
    const bDate = new Date(b.metadata.blockTimestamp).getTime();
    return bDate - aDate;
  });
}

export function groupTransactionsByMonth(txs: Transaction[]) {
  const sorted = sortByDate(txs);
  const groups = sorted.reduce((acc, tx) => {
    const date = new Date(tx.metadata.blockTimestamp);
    const month = date.getMonth();
    const year = date.getFullYear();
    const key = `${year}-${month}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(tx);
    return acc;
  }, {} as Record<string, Transaction[]>);
  return groups;
}

export function groupTransactionsByDay(txs: Transaction[]) {
  const sorted = sortByDate(txs);
  const groups = sorted.reduce((acc, tx) => {
    const date = new Date(tx.metadata.blockTimestamp);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const key = `${year}-${month}-${day}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(tx);
    return acc;
  }, {} as Record<string, Transaction[]>);
  return groups;
}
// take date string in format 'YYYY-mm-DD'
// and transform it into 'MMM DD, YYYY'
export function getMonthTitle(date: string, language: string) {
  const [year, month, day] = date.split('-');
  const dateObj = new Date(+year, +month, +day);
  const locale = findLocaleFromLanguage(language);

  return dateObj.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export function transformToSections(
  groups: Record<string, Transaction[]>,
  locale: string
) {
  return Object.keys(groups)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .map((key) => ({
      title: getMonthTitle(key, locale),
      txs: groups[key],
    }));
}
