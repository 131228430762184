export const CheckIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_33_366)">
      <path
        d="M10.4104 1.52344L4.2962 7.19559L1.71048 4.43619L0 6.039L4.17737 10.497L12 3.24655L10.4104 1.52344Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_33_366">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
