import { useMerchantContext } from '~/entities/merchant';
import useSWR from 'swr';

export function useMerchantSettings() {
  const { api, token } = useMerchantContext();

  const settings = useSWR(
    token?.access_token ? `merchant-settings-${token.access_token}` : null,
    () => api.getMerchant(),
    {
      revalidateOnFocus: true,
    }
  );

  return settings;
}
