import { useAccountTokens } from '~/entities/tokens';
import { parseAlchemyBalance } from '~/entities/tokens/utils';
import { useTokenPrices } from '~/shared/fiat-converter';
import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { useAccount } from 'wagmi';
import { toEthers } from '~/shared/ethereum/units';

export function useUserTotalBalance({ symbol }: { symbol: 'usd' | 'eur' }) {
  const account = useAccount();
  const tokens = useAccountTokens({
    address: account.address,
  });
  const tokenPrices = useTokenPrices();

  const totalBalance = useMemo(() => {
    if (!tokens.data || !tokenPrices.data) {
      return null;
    }

    invariant(
      tokens.data && tokenPrices.data,
      'Tokens and prices are required'
    );

    return tokens.data.data.reduce((acc, token) => {
      // @ts-ignore
      // Bug of typescript, should understand that tokenPrices.data.data is not null at this point
      const price = tokenPrices.data.data.find(
        (p) => p.symbol === token.symbol
      );

      if (!price) {
        return acc;
      }

      const balance = token.balance;
      const balanceUsd =
        toEthers(
          parseAlchemyBalance(balance).toString(),
          token.decimals
        ).toUnsafeFloat() * price[symbol];

      return acc + balanceUsd;
    }, 0);
  }, [tokens.data, tokenPrices.data, symbol]);

  return { value: totalBalance, symbol };
}
