import { WidgetParams } from '@nearpay/nearpay-sdk/dist/interfaces/widget-parameters';
import { ENV_MUNZEN_SECRET_KEY } from '~/app/config';
import { ResponseType } from '~/shared/api';
async function hmacSha256(data: string, secret: string) {
  const enc = new TextEncoder();
  const algo = {
    name: 'HMAC',
    hash: 'SHA-256',
  };

  const key = await crypto.subtle.importKey(
    'raw',
    enc.encode(secret),
    algo,
    false,
    ['sign', 'verify']
  );

  const signature = await crypto.subtle.sign(algo.name, key, enc.encode(data));
  const hashArray = Array.from(new Uint8Array(signature));

  const digest = hashArray.reduce((acc, b) => {
    return acc + b.toString(16).padStart(2, '0');
  }, '');

  return digest;
}

export async function signTopUpParametersLocally(
  parameters: WidgetParams & {
    email?: string;
    sumsub_token?: string;
  }
): Promise<
  ResponseType<{
    link: string;
  }>
> {
  const signatureString = Object.keys(parameters)
    .sort()
    // @ts-ignore
    .map((key) => {
      if (key === 'apiKey') {
        return null;
      }
      // @ts-ignore
      return `${key}:${parameters[key]}`;
    })
    .filter((x) => !!x)
    .join('');

  const signature = await hmacSha256(signatureString, ENV_MUNZEN_SECRET_KEY);

  const queryString = Object.keys(parameters)
    .map((key) => {
      // @ts-ignore
      console.debug('key', key, parameters[key]);
      // @ts-ignore
      return parameters[key] ? `${key}=${parameters[key]}` : null;
    })
    .filter((x) => !!x)
    .join('&');

  return {
    data: {
      link: `https://any.com/?signature=${signature}&${queryString}`,
    },
  };
}
