import { PropsWithChildren, useState } from 'react';
import { UserInfo as UserInfoType } from '@web3auth/base';
import styled from '@emotion/styled';

type UserInfoProps = PropsWithChildren<{
  userInfo: Partial<UserInfoType>;
}>;

const _AvatarImg = styled.img((props) => ({
  objectFit: 'cover',
  borderRadius: '99999px',
  backgroundColor: props.theme.colors.neutral[30],
}));

const _Email = styled.div({});

const _Container = styled.div({
  display: 'flex',
  [`${_Email}`]: {
    marginLeft: '10px',
  },
});

const styles = {
  AvatarImg: _AvatarImg,
  Email: _Email,
  Container: _Container,
};

export function Avatar({ avatar, ...rest }: { avatar: string | undefined }) {
  const [isReady, setIsReady] = useState<boolean>(false);
  return (
    <div
      {...rest}
      css={{
        width: '32px',
        height: '32px',
        display: 'flex',
      }}
    >
      <styles.AvatarImg
        css={{
          visibility: isReady ? 'visible' : 'hidden',
          width: isReady ? '100%' : 0,
          height: isReady ? '100%' : 0,
          position: isReady ? 'static' : 'absolute',
        }}
        src={avatar}
        alt="Avatar"
        onLoad={() => {
          setIsReady(true);
        }}
        referrerPolicy="no-referrer"
      />
      {/* <styles.Avatar
        css={{
          display: isReady ? 'none' : 'inline',
          width: isReady ? 0 : '50px',
          height: isReady ? 0 : '50px',
        }}
        src={'https://duckduckgo.com/assets/logo_header.alt.v108.svg'}
      /> */}
    </div>
  );
}
