import { useEffect } from 'react';
export function useClickOutside({
  onClickedOutside,
  shouldListen,
  ref,
}: {
  onClickedOutside: (e: MouseEvent) => void;
  shouldListen: boolean;
  ref: React.MutableRefObject<HTMLElement | null | undefined>;
}) {
  useEffect(() => {
    // close menu when clicking outside
    const listener = (e: MouseEvent) => {
      if (ref.current && ref.current.contains(e.target as Node)) {
        return;
      }

      onClickedOutside(e);
    };

    if (shouldListen) {
      document.addEventListener('click', listener);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [shouldListen, onClickedOutside, ref]);
}
