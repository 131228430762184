import { validateEmail } from './validators';

export function isValidEmail(message?: string) {
  return {
    test: (email: string | undefined) => {
      return validateEmail(email);
    },
    message: message || "That's not a valid email",
  };
}
