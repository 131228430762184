import styled from '@emotion/styled';
import { AppLogoIcon } from './logo';

const Container = styled.div((props) => ({
  img: {
    width: '100%',
    height: '100%',
  },
}));

export function AppLogo(props: any) {
  return (
    <Container {...props}>
      <AppLogoIcon />
    </Container>
  );
}
