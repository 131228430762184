import { keyframes } from '@emotion/react';
import loader from './loader.png';

const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export function Spinner({ size = 48, ...rest }: { size?: number }) {
  return (
    <div
      css={{
        width: size,
        height: size,
        animation: `${rotateKeyframes} 1.8s linear infinite`,
      }}
      {...rest}
    >
      <img src={loader} alt="Loading" />
    </div>
  );
}
