import useSWR from 'swr';
import { endpoint, ResponseType } from '~/shared/api';
import { formatFiatValue } from '~/entities/tokens/utils';

const tokenPriceUrl = endpoint('/v1/exchange_rates');

type TokenPrice = {
  contract: string | null;
  eur: number;
  symbol: string;
  usd: number;
};

export function useTokenPrices() {
  const data = useSWR<ResponseType<TokenPrice[]>>(
    'token-prices',
    () => {
      return fetch(tokenPriceUrl).then((res) => res.json());
    },
    {}
  );

  return data;
}

export class FiatBalance {
  price: number;
  value: number;
  ticker: string;

  constructor(price: number, value: number, ticker: string) {
    this.price = price;
    this.value = value;
    this.ticker = ticker;
  }

  get fiatValue() {
    return this.value * this.price;
  }

  get formatted() {
    if (this.price === 0) {
      return `${this.symbol}–`;
    }
    return `${formatFiatValue(this.fiatValue, this.symbol)}`;
  }

  get symbol() {
    if (this.ticker === 'USD') {
      return '$';
    } else if (this.ticker === 'EUR') {
      return '€';
    } else {
      return this.ticker;
    }
  }

  add(other: FiatBalance): FiatBalance {
    return new FiatBalance(this.price, this.value + other.value, this.ticker);
  }

  mul(other: number): FiatBalance {
    return new FiatBalance(this.price, this.value * other, this.ticker);
  }
}

export function useFiatValue({
  value,
  symbol,
}: {
  value: number;
  symbol: string;
}): FiatBalance {
  const prices = useTokenPrices();
  const tokenPrice = prices.data?.data.find((p) => p.symbol === symbol);

  if (!tokenPrice) {
    return new FiatBalance(0, 0, 'USD');
  }

  return new FiatBalance(tokenPrice.usd, value, 'USD');
}
