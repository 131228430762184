import numbro from 'numbro';
import BigNumber from 'bignumber.js';
import { UserToken } from './tokens';

export const parseAlchemyBalance = (balance: `0x${string}`): BigNumber => {
  return new BigNumber(balance, 16);
};

export const formatValue = (value: number, maxDigits: number) => {
  const bigValueFormatter = Intl.NumberFormat('fr-FR', {
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const smallValueFormatter = Intl.NumberFormat('fr-FR', {
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 4,
    maximumFractionDigits: maxDigits,
  });

  if (value < 1) {
    return smallValueFormatter.format(value);
  } else if (value < 1000000) {
    return bigValueFormatter.format(value);
  } else {
    return numbro(value).format({
      mantissa: 3,
      trimMantissa: true,
      optionalMantissa: true,
      average: true,
    });
  }
};
/**
 *
 * @param value Ethers value of the balance (integer)
 * @param decimals Number of decimals of the token
 * @returns
 */
export const formatTokenAmount = (value: string, decimals: number) => {
  const balanceNumber = new BigNumber(value, 10)
    .dividedBy(10 ** decimals)
    .toNumber();

  return formatValue(balanceNumber, decimals);
};

export const formatTokenBalance = (token: UserToken) =>
  formatTokenAmount(
    parseAlchemyBalance(token.balance).toString(),
    token.decimals
  );

export const formatFiatValue = (value: number, symbol: string = '') => {
  if (value && value < 0.001) return `~${symbol}0.001`;

  const smallValueFormatter = Intl.NumberFormat('gb-GB', {
    minimumSignificantDigits: 2,
    maximumSignificantDigits: 4,
    maximumFractionDigits: 18,
  });

  return `${symbol}${smallValueFormatter.format(value)}`;
};

export const normalizeTokenBalance = (token: UserToken) => {
  const { balance, decimals } = token;
  const balanceNumber = parseAlchemyBalance(balance);
  return balanceNumber.dividedBy(10 ** decimals).toNumber();
};

export const normalizeTokenAmount = (
  amount: `0x${string}`,
  decimals: number
) => {
  const amountNumber = new BigNumber(amount, 16);
  return amountNumber.dividedBy(10 ** decimals).toNumber();
};
