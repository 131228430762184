import {
  baseHeaders,
  checkResponseCode,
  endpoint,
  ResponseType,
} from '~/shared/api';

type SendLoginEmailResponse = ResponseType<{
  detail: string;
  ticket: string;
}>;
export async function sendLoginEmail({
  captchaToken,
  email,
}: {
  captchaToken: string;
  email: string;
}): Promise<SendLoginEmailResponse> {
  let url = endpoint('/v1/auth/signin');

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      captcha_answer: captchaToken,
      email,
    }),
    headers: {
      ...baseHeaders,
    },
  }).then(checkResponseCode);

  return response.json();
}

type UserLoginResponse = ResponseType<{
  email: string;
  id_token: string;
}>;
export async function loginUser({
  ticket,
  email,
}: {
  ticket: string;
  email: string;
}): Promise<UserLoginResponse> {
  let url = endpoint('/v1/auth/token');

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ticket,
      email,
    }),
    headers: {
      ...baseHeaders,
    },
  }).then(checkResponseCode);

  return response.json();
}
