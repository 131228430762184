import { useEffect, useState } from 'react';
import { useAccount, useConfig, useConnect } from 'wagmi';

export function useWeb3AuthAutoReconnect() {
  const client = useConfig();
  const { isConnecting, isReconnecting, status: accountStatus } = useAccount();
  const { isLoading, status } = useConnect();
  const [isInitting, setIsInitting] = useState(false);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const init = async () => {
      setIsInitting(true);
      try {
        await client.autoConnect();
      } catch (error) {
        console.error('Failed to autoconnect', error);
      } finally {
        setIsDone(true);
        setIsInitting(false);
      }
    };
    init();
  }, []);

  if (isDone) {
    return {
      willReconnect: false,
      doingReconnect: false,
    };
  }

  const willReconnect = isReconnecting;
  const doingReconnect =
    isInitting || isConnecting || isLoading || isReconnecting;

  return {
    willReconnect,
    doingReconnect,
  };
}
