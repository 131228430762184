import { Nft } from 'alchemy-sdk';
import { NftItemCard } from '~/entities/nfts/ui/NftItemCard';
import { NftTransferFormValues, NftTransferModel } from './model';

import { KindOfBigNumber } from '~/shared/ethereum/types';
import { FiatBalance } from '~/shared/fiat-converter';
import {
  Button,
  Buttons,
  Delimiter,
  FormItemDisplay,
  ModalHeading,
} from '~/shared/ui/kit';
import { limitLength, trimMiddle } from '~/shared/utils/string-helpers';
import { useTranslation } from 'react-i18next';
import { TransactionNetworkFee } from '~/shared/signing-transactions';
import { useNftTransfering } from './lib/hooks/use-nft-transfering';
import { useAccount } from 'wagmi';
import { HexString } from '~/shared/types';
import { observer } from 'mobx-react';
import { useStepProgress } from '~/shared/hooks/useStepProgress';
import { NftStatus } from '~/entities/nfts/ui/NftStatus';
import { ErrorMessage } from '~/shared/ui/kit/input-base/input-base';

export function NftTransferTransactionDetails({
  from,
  to,
  feeMatic,
  maxTotal,
}: {
  from: string;
  to: string;
  maxTotal: FiatBalance;
  feeMatic: KindOfBigNumber;
}) {
  const { t } = useTranslation('common');
  return (
    <>
      <FormItemDisplay
        name={t('glossary.from')}
        value={trimMiddle(from || '', 20)}
        css={{ marginBottom: 24 }}
      />
      {to && (
        <FormItemDisplay
          name={t('glossary.to')}
          value={trimMiddle(to, 20)}
          css={{ marginBottom: 24 }}
        />
      )}

      <Delimiter css={{ marginBottom: 24 }} />

      <TransactionNetworkFee feeMatic={feeMatic} css={{ marginBottom: 24 }} />
      <FormItemDisplay
        name={t('glossary.maxTotal')}
        value={`${maxTotal.formatted}`}
      />
    </>
  );
}

function _ConfirmStep({
  nft,
  formValues,
  updateFormValues,
}: {
  nft: Nft;
  formValues: NftTransferFormValues;
  updateFormValues: (values: Partial<NftTransferFormValues>) => void;
}) {
  const account = useAccount();
  const steps = useStepProgress();

  const { fee, submit, sender, error } = useNftTransfering({
    from: account.address as HexString,
    to: formValues.recipient as HexString,
    token: nft,
  });

  const isError = error;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 576 - 40 * 2, //figma height - two margins (40px)
      }}
    >
      <ModalHeading css={{ marginBottom: 16 }}>Transfer</ModalHeading>
      {isError ? (
        <NftStatus status="error" nft={nft} />
      ) : (
        <NftItemCard nft={nft} />
      )}

      <div
        css={{
          marginTop: 24,
        }}
      >
        <NftTransferTransactionDetails
          from={account.address || ''}
          to={formValues.recipient}
          feeMatic={fee.feeMatic}
          maxTotal={fee.feeFiat}
        />
      </div>

      {error ? (
        <ErrorMessage css={{ marginTop: 16 }}>
          {limitLength(error.message, 80)}
        </ErrorMessage>
      ) : null}
      <Buttons
        nButtons={2}
        css={{
          marginTop: 'auto',
          paddingTop: 24,
        }}
      >
        <Button type="button" semanticType="secondary" onClick={steps.goBack}>
          Back
        </Button>
        <Button
          type="submit"
          semanticType="primary"
          disabled={!submit}
          isLoading={sender.isLoading}
          onClick={async () => {
            if (sender.writeAsync) {
              const result = await sender.writeAsync();

              if (result.hash) {
                updateFormValues({
                  transactionHash: result.hash,
                });
              }
            }
          }}
        >
          {sender.isError ? 'Try Again' : 'Transfer'}
        </Button>
      </Buttons>
    </div>
  );
}

export const ConfirmStep = observer(
  ({ nftTransferModel }: { nftTransferModel: NftTransferModel }) => {
    if (!nftTransferModel.token) {
      return <div>nft is null</div>;
    }

    return (
      <_ConfirmStep
        nft={nftTransferModel.token}
        formValues={nftTransferModel.formValues}
        updateFormValues={(values) => nftTransferModel.updateFormValues(values)}
      ></_ConfirmStep>
    );
  }
);
