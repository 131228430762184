function validateEnvs() {
  const missingEnvs = [];

  if (!process.env.REACT_APP_NETWORK) {
    missingEnvs.push('REACT_APP_NETWORK');
  }
  if (!(process.env.REACT_APP_NETWORK || '').match(/^(testnet|mainnet)$/)) {
    throw new Error('REACT_APP_NETWORK must be either "testnet" or "mainnet"');
  }
  if (!process.env.REACT_APP_RPC_URL) {
    missingEnvs.push('REACT_APP_RPC_URL');
  }
  if (!process.env.REACT_APP_ALCHEMY_API_KEY) {
    missingEnvs.push('REACT_APP_ALCHEMY_API_KEY');
  }

  if (!process.env.REACT_APP_WEB3_CLIENT_ID) {
    missingEnvs.push('REACT_APP_WEB3_CLIENT_ID');
  }

  if (!process.env.REACT_APP_WEB3_VERIFIER_NAME) {
    missingEnvs.push('REACT_APP_WEB3_VERIFIER_NAME');
  }

  if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
    missingEnvs.push('REACT_APP_RECAPTCHA_SITE_KEY');
  }

  if (!process.env.REACT_APP_ANALYTICS_KEY) {
    missingEnvs.push('REACT_APP_ANALYTICS_KEY');
  }

  if (!process.env.REACT_APP_QUV_BACKEND) {
    missingEnvs.push('REACT_APP_QUV_BACKEND');
  }

  if (!process.env.REACT_APP_WHITELABEL_PRIVACY_POLICY_LINK) {
    missingEnvs.push('REACT_APP_WHITELABEL_PRIVACY_POLICY_LINK');
  }

  if (!process.env.REACT_APP_WHITELABEL_NAME) {
    missingEnvs.push('REACT_APP_WHITELABEL_NAME');
  }

  if (!process.env.REACT_APP_WHITELABEL_TOS_LINK) {
    missingEnvs.push('REACT_APP_WHITELABEL_TOS_LINK');
  }

  if (!process.env.REACT_APP_WHITELABEL_TOS_EMAIL_LINK) {
    missingEnvs.push('REACT_APP_WHITELABEL_TOS_EMAIL_LINK');
  }

  if (!process.env.REACT_APP_WHITELABEL_LOGO_PACK) {
    missingEnvs.push('REACT_APP_WHITELABEL_LOGO_PACK');
  }

  if (missingEnvs.length > 0) {
    throw new Error(`Missing environment variables: ${missingEnvs.join(', ')}`);
  }
}

validateEnvs();
export const ENV_NETWORK = process.env.REACT_APP_NETWORK as
  | 'testnet'
  | 'mainnet';
export const ENV_RPC_URL = process.env.REACT_APP_RPC_URL as string;

export const ENV_ALCHEMY_API_KEY = process.env
  .REACT_APP_ALCHEMY_API_KEY as string;

export const ENV_WEB3_CLIENT_ID = process.env
  .REACT_APP_WEB3_CLIENT_ID as string;

export const ENV_WEB3_VERIFIER_NAME = process.env
  .REACT_APP_WEB3_VERIFIER_NAME as string;
export const ENV_RECAPTCHA_SITE_KEY = process.env
  .REACT_APP_RECAPTCHA_SITE_KEY as string;

export const ENV_ANALYTICS_KEY = process.env.REACT_APP_ANALYTICS_KEY as string;
export const ENV_ANALYTICS_PROXY_HOST = process.env
  .REACT_APP_ANALYTICS_PROXY_HOST as string | undefined;

export const ENV_QUV_BACKEND = process.env.REACT_APP_QUV_BACKEND as string;

export const ENV_WHITELABEL_PRIVACY_POLICY_LINK = process.env
  .REACT_APP_WHITELABEL_PRIVACY_POLICY_LINK as string;
export const ENV_WHITELABEL_NAME = process.env
  .REACT_APP_WHITELABEL_NAME as string;
export const ENV_WHITELABEL_TOS_LINK = process.env
  .REACT_APP_WHITELABEL_TOS_LINK as string;
export const ENV_WHITELABEL_TOS_EMAIL_LINK = process.env
  .REACT_APP_WHITELABEL_TOS_EMAIL_LINK as string;
export const ENV_WHITELABEL_LOGO_PACK = process.env
  .REACT_APP_WHITELABEL_LOGO_PACK as string;

export const ENV_ONRAMPER_KEY = process.env.REACT_APP_ONRAMPER_KEY as string;
export const ENV_MUNZEN_SECRET_KEY = process.env
  .REACT_APP_MUNZEN_SECRET_KEY as string;
