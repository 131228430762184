export const TxOutIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_39_3773)">
      <path
        d="M15.7422 4.25782C14.6062 3.12065 13.1585 2.34596 11.5822 2.03176C10.0059 1.71755 8.37174 1.87795 6.88659 2.49266C5.40144 3.10736 4.13197 4.14876 3.23881 5.4851C2.34564 6.82143 1.8689 8.39266 1.8689 10C1.8689 11.6073 2.34564 13.1786 3.23881 14.5149C4.13197 15.8512 5.40144 16.8926 6.88659 17.5074C8.37174 18.1221 10.0059 18.2825 11.5822 17.9683C13.1585 17.654 14.6062 16.8794 15.7422 15.7422C16.4967 14.9883 17.0952 14.0932 17.5036 13.1079C17.9119 12.1227 18.1221 11.0666 18.1221 10C18.1221 8.93345 17.9119 7.87736 17.5036 6.89208C17.0952 5.9068 16.4967 5.01166 15.7422 4.25782ZM12.8125 11.5625C12.8125 11.7283 12.7467 11.8872 12.6294 12.0044C12.5122 12.1217 12.3533 12.1875 12.1875 12.1875C12.0217 12.1875 11.8628 12.1217 11.7456 12.0044C11.6284 11.8872 11.5625 11.7283 11.5625 11.5625V9.32032L8.25782 12.6328C8.13699 12.7462 7.97816 12.8103 7.8125 12.8125C7.64654 12.8118 7.48717 12.7475 7.36719 12.6328C7.24983 12.5143 7.18399 12.3543 7.18399 12.1875C7.18399 12.0207 7.24983 11.8607 7.36719 11.7422L10.6797 8.4375H8.4375C8.27174 8.4375 8.11277 8.37166 7.99556 8.25445C7.87835 8.13724 7.8125 7.97826 7.8125 7.8125C7.8125 7.64674 7.87835 7.48777 7.99556 7.37056C8.11277 7.25335 8.27174 7.1875 8.4375 7.1875H12.1875C12.268 7.18729 12.3477 7.20323 12.4219 7.23438C12.5762 7.29679 12.6997 7.41749 12.7656 7.57032C12.7962 7.64741 12.8121 7.72956 12.8125 7.8125V11.5625Z"
        fill="#3E3A42"
      />
    </g>
    <defs>
      <clipPath id="clip0_39_3773">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
