import { Nft } from 'alchemy-sdk';
import { NftCollectionMetadata } from './types';

export const formatImgUrl = (url?: string): string | undefined => {
  let newUrl = url;

  if (newUrl?.match(/^ipfs:\/\/.+/)) {
    newUrl = newUrl.replace(/ipfs:\/\//, 'https://ipfs.io/ipfs/');
  }

  return newUrl;
};

export function inheritNftCollectionMetadata(nft: Nft): NftCollectionMetadata {
  const { contract } = nft;

  return {
    contract: contract.address,
    name: contract.name,
    symbol: contract.symbol,
    img: contract.openSeaMetadata.imageUrl,
    mostRecentAcquire:
      nft.acquiredAt?.blockTimestamp || new Date(0).toISOString(),
  };
}
