const adjustHue = (base: number, adjust: number) => (base + adjust) % 360;

const ADJUSTMENT = 40;

export const quvTheme = {
  spacer: 4,
  colors: {
    critical: {
      120: 'hsla(354,92%,39%,1)',
      100: 'hsla(354,78%,49%,1)',
      20: 'hsla(354,91%,96%,1)',
    },
    success: {
      120: 'hsla(148,100%,30%,1)',
      100: 'hsla(149,82%,43%,1)',
      40: 'hsla(149,76%,84%,1)',
      20: 'hsla(149,100%,94%,1)',
    },
    info: {
      120: 'hsla(220,96%,29%,1)',
      20: 'hsla(220,71%,96%,1)',
    },
    warning: {
      20: 'hsla(38,100%,96%,1)',
    },
    background: 'hsl(240, 2%, 98%)',
    neutral: {
      100: 'hsla(270,3%,11%,1)',
      80: 'hsla(270,6%,24%,1)',
      60: 'hsla(262,4%,52%,1)',
      50: 'hsla(264,9%,67%,1)',
      40: 'hsla(266,11%,88%,1)',
      30: 'hsla(270,29%,95%,1)',
      20: 'hsla(260,23%,97%,1)',
      10: 'hsla(0,0%,100%,1)',
    },
    /** by colors  */
    purple: {
      120: 'hsl(270, 74%, 56%)',
      100: 'hsl(270, 100%, 80%)',
      80: 'hsl(270, 100%, 90%)',
    },
    buttons: {
      gradient:
        'linear-gradient(127.53deg, hsla(270,73%,51%,1) 0.51%, hsla(256,93%,39%,1) 58.55%, hsla(248,100%,28%,1) 76.95%, hsla(234,100%,16%,1) 100%)',
      gradientActive:
        'linear-gradient(220.53deg, hsla(270,73%,51%,1) 0.51%, hsla(256,93%,39%,1) 58.55%, hsla(248,100%,28%,1) 76.95%, hsla(234,100%,16%,1) 100%)',
      stop1: 'hsla(270,73%,51%,1)',
      stop2: 'hsla(256,93%,39%,1)',
      stop3: 'hsla(248,100%,28%,1)',
      stop4: 'hsla(234,100%,16%,1)',
      active: 'hsla(273, 100%, 41%, 1)',
    },
    summary: {
      gradient:
        'linear-gradient(257.51deg, hsla(25, 87%, 78%, 0.32) -2.84%, hsla(326,67%,75%,0.32) 28.55%, hsla(273,55%,65%,0.32) 68.22%, hsla(248,80%,60%,0.32) 105.04%)',
      stop1: 'hsla(25, 87%, 78%, 0.32)',
      stop2: 'hsla(326,67%,75%,0.32)',
      stop3: 'hsla(273,55%,65%,0.32)',
      stop4: 'hsla(248,80%,60%,0.32)',
    },
  },
};
