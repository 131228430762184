import { HTMLAttributes, ImgHTMLAttributes } from 'react';
import { Nft } from 'alchemy-sdk';
import { formatImgUrl } from '../utils';
import styled from '@emotion/styled';
import { ImagePreview, OutlinedIcon } from '~/shared/ui/kit';

type TokenItemProps = ImgHTMLAttributes<HTMLImageElement> & {
  token: Nft;
};

const Img = styled.img<{ isClickable: boolean }>(({ isClickable }) => ({
  width: 104,
  height: 104,
  borderRadius: '16px',
  cursor: isClickable ? 'pointer' : 'default',
}));

export function NftThumbnail({ token, ...rest }: TokenItemProps) {
  const thumbnail = token.image.thumbnailUrl;

  if (!thumbnail) {
    return (
      <OutlinedIcon
        size={104}
        {...rest}
        css={{
          cursor: 'pointer',
        }}
      >
        <ImagePreview />
      </OutlinedIcon>
    );
  }
  return (
    <Img
      src={thumbnail}
      alt={token.name}
      isClickable={!!rest.onClick}
      {...rest}
    />
  );
}
