export const AttentionIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 34C26 32.8954 25.1046 32 24 32C22.8954 32 22 32.8954 22 34C22 35.1046 22.8954 36 24 36C25.1046 36 26 35.1046 26 34Z"
      fill="currentColor"
    />
    <path
      d="M24 29.9999C22.8955 29.9999 22 29.1045 22 27.9999V19.9999C22 18.8954 22.8955 17.9999 24 17.9999C25.1046 17.9999 26 18.8953 26 19.9999V27.9999C26 29.1045 25.1046 29.9999 24 29.9999Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3685 7.31773C27.1577 2.89409 20.8451 2.89409 18.6343 7.31773L4.64067 35.3182C2.64694 39.3076 5.54794 44.0005 10.0077 44.0005H37.9951C42.4548 44.0005 45.3559 39.3076 43.3621 35.3182L29.3685 7.31773ZM22.2124 9.10591C22.9493 7.63136 25.0535 7.63136 25.7904 9.10591L39.7841 37.1064C40.4487 38.4362 39.4817 40.0005 37.9951 40.0005H10.0077C8.52114 40.0005 7.55414 38.4362 8.21871 37.1064L22.2124 9.10591Z"
      fill="currentColor"
    />
  </svg>
);
