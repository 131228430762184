import styled from '@emotion/styled';
import { useAccount, WagmiConfig } from 'wagmi';
import { Navigate, Route, Routes } from 'react-router-dom';
import { WalletPage } from '~/pages/wallet';
import { LoginPage } from '~/pages/login';
import {
  ReconnectingMiddleware,
  Web3AuthProvider,
} from '~/shared/ethereum/web3auth';
import { CurtainLoader } from '~/shared/ui/kit';
import { PayWithQuvio } from '~/pages/pay-with-quvio';
import { MainPage } from '~/pages/main';
import { LogoutHandler } from '~/features/logout';

import { lazy, Suspense, useEffect } from 'react';
import { MerchantZone } from '~/pages/merchant-zone';
import {
  MerchantLoginFailView,
  MerchantLoginSuccessView,
  MerchantLoginEntryView,
  MerchantLoginFormView,
  MerchantLogin,
} from '~/pages/merchant-zone/merchant-login';
import {
  MerchantDashboard,
  MerchantSettingsView,
  MerchantTransactions,
  MerchantPlayground,
} from '~/pages/merchant-zone/merchant-dashboard';

import { useTracker } from '~/shared/analytics';
import { FF_FARM_TOKENS } from '../feature-flags';
import { ChainProvider } from '~/shared/ethereum/chain-provider';
import {
  wagmiClient,
  web3AuthConnector,
} from '~/shared/ethereum/web3auth/connector/wagmi';
import { useWeb3AuthTokenSubmit } from '../app-model';
import { OfframpPage } from '~/pages/offramp/offramp';
import { OnrampPage } from '~/pages/onramp';

const AppContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.background,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

const FarmTokens = lazy(() => import('~/pages/farm-tokens'));

function App() {
  const { isConnected, address } = useAccount();
  const tracker = useTracker();

  useEffect(() => {
    if (address) {
      tracker.identify(address);
    }
  }, [tracker, address]);

  useWeb3AuthTokenSubmit();

  return (
    <AppContainer>
      <ReconnectingMiddleware
        renderDuringReconnect={<CurtainLoader text="Getting you back..." />}
      >
        <LogoutHandler
          renderDisconnecting={<CurtainLoader text="Logging you out..." />}
        >
          <Routes>
            <Route path="merchant/*" element={<MerchantZone />}>
              <Route
                path="login/entry"
                element={<MerchantLoginEntryView />}
              ></Route>
              <Route path="login" element={<MerchantLogin />}>
                <Route path="" element={<MerchantLoginFormView />} />
                <Route path="success" element={<MerchantLoginSuccessView />} />
                <Route path="fail" element={<MerchantLoginFailView />} />
              </Route>
              <Route path="dashboard" element={<MerchantDashboard />}>
                <Route path="" element={<MerchantSettingsView />} />
                <Route path="playground" element={<MerchantPlayground />} />
                <Route path="transactions" element={<MerchantTransactions />} />
              </Route>
              <Route path="*" element={<Navigate to="/merchant/login" />} />
            </Route>
            {FF_FARM_TOKENS ? (
              <Route path="farm-tokens" element={<FarmTokens />} />
            ) : null}
            <Route path="buy/*" element={<PayWithQuvio />} />
            {isConnected ? (
              <Route path="*" element={<WalletPage />}>
                <Route path="" element={<MainPage />} />
                <Route path="offramp" element={<OfframpPage />} />
                <Route path="onramp" element={<OnrampPage />} />
              </Route>
            ) : (
              <Route path="" element={<LoginPage />} />
            )}
          </Routes>
        </LogoutHandler>
      </ReconnectingMiddleware>
    </AppContainer>
  );
}

export function WalletApp() {
  return (
    <Suspense fallback={<CurtainLoader text="" />}>
      <Web3AuthProvider
        connector={web3AuthConnector}
        renderNotInitialized={() => <CurtainLoader text=""></CurtainLoader>}
      >
        <WagmiConfig config={wagmiClient}>
          <ChainProvider>
            <App />
          </ChainProvider>
        </WagmiConfig>
      </Web3AuthProvider>
    </Suspense>
  );
}
