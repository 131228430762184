import {
  BodyPrimaryText,
  Button,
  Buttons,
  ModalHeading,
  ModalSubHeading,
} from '~/shared/ui/kit';
import { useSendForm } from './form';
import { useAccount } from 'wagmi';
import { useStepProgress } from '~/shared/hooks/useStepProgress';
import { HexString } from '~/shared/types';
import { FormSelectedAsset } from './ui/form-selected-asset';
import { useTokenSending } from './lib/hooks/use-token-sending';
import { MATIC_DATA } from '~/entities/tokens/shared/const';
import { TransactionDetails } from './ui/transaction-details';
import { useFiatValue } from '~/shared/fiat-converter';
import { useTracker } from '~/shared/analytics';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export function ConfirmStep() {
  const { t } = useTranslation('common');

  const theme = useTheme();
  const form = useSendForm();
  const steps = useStepProgress();
  const account = useAccount();
  const tracker = useTracker();

  const { fee, sender, error, submit } = useTokenSending({
    token: {
      address: form.token!.contract || MATIC_DATA.address,
      decimals: form.token!.decimals,
      symbol: form.token!.symbol,
    },
    to: form.receiverAddress as HexString,
    amount: form.amount,
  });

  const isGasInsufficientError =
    error && 'code' in error && error.code === 'UNPREDICTABLE_GAS_LIMIT';

  const fiatValue = useFiatValue({
    value: Number(form.amount),
    symbol: form.token?.symbol || '',
  });

  async function doSending() {
    tracker.track('send_tokens.click_confirm_send');
    if (!sender || !submit) {
      return;
    }

    try {
      const tx = await submit();
      tracker.track('send_tokens.success');
      form.setValues({
        transaction: tx.hash,
      });

      steps.goForward();
    } catch (err) {
      tracker.track('send_tokens.error');
      console.error(err);
    }
  }

  return (
    <div>
      <ModalHeading css={{ marginBottom: 4 }}>
        {t('glossary.send')}
      </ModalHeading>
      <ModalSubHeading
        css={{
          marginBottom: 16,
        }}
      >
        {form.token ? <FormSelectedAsset token={form.token} /> : null}
      </ModalSubHeading>

      <div css={{ marginBottom: 24 }}>
        <TransactionDetails
          tokenName={form.token?.name || ''}
          tokenSymbol={form.token?.symbol || ''}
          from={account.address || ''}
          to={form.receiverAddress}
          amount={form.amount}
          feeMatic={fee.feeMatic}
          maxTotal={fiatValue.add(fee.feeFiat)}
        />
      </div>

      {isGasInsufficientError ? (
        <BodyPrimaryText
          as="p"
          css={{
            color: theme.colors.critical[100],
            fontSize: 14,
            marginBottom: 16,
          }}
        >
          {t('sendTokens.errorGasInsufficient')}
        </BodyPrimaryText>
      ) : null}

      <div>
        {sender.isLoading ? (
          <Buttons nButtons={1}>
            <Button semanticType="primary">
              {t('sendTokens.sendingTokens')}...
            </Button>
          </Buttons>
        ) : (
          <Buttons>
            <Button
              type="button"
              semanticType="secondary"
              onClick={() => {
                steps.goBack();
              }}
            >
              {t('glossary.back')}
            </Button>
            <Button
              semanticType="primary"
              onClick={() => {
                doSending();
              }}
            >
              {isGasInsufficientError
                ? t('glossary.sendAnyway')
                : t('glossary.send')}
            </Button>
          </Buttons>
        )}
      </div>
    </div>
  );
}
