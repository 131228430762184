import styled from '@emotion/styled';

export const TokenLogoPlaceholder = styled.div((props) => ({
  borderRadius: 12,
  width: 44,
  height: 44,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: props.theme.colors.neutral[40],
}));
