import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { useState } from 'react';

type ProgressContextValue<T extends string = string> = {
  step: T;
  isFinished: boolean;
  setStep: (step: T) => void;
  steps: T[];
  setFinished: (value: boolean) => void;
};

const StepProgressContext = React.createContext<ProgressContextValue>({
  step: '',
  isFinished: false,
  steps: [],
  setStep: () => {},
  setFinished: (value: boolean) => {},
});

export function StepProgressProvider({
  steps,
  initialStep,
  children,
}: PropsWithChildren<{
  steps: string[];
  initialStep?: string;
}>) {
  const [isFinished, setFinished] = useState(false);
  const [step, setStep] = useState<string>(initialStep || steps[0]);

  const contextValue = useMemo(
    () => ({
      step,
      setStep: (step: string) => {
        setFinished(false);
        setStep(step);
      },
      steps,
      setFinished,
      isFinished,
    }),
    [step, setStep, steps, setFinished, isFinished]
  );

  return (
    <StepProgressContext.Provider value={contextValue}>
      {children}
    </StepProgressContext.Provider>
  );
}

export function useStepProgress<T extends string>() {
  const { step, setStep, steps, setFinished, isFinished } = useContext(
    StepProgressContext
  ) as unknown as ProgressContextValue<T>;

  const goBack = () => {
    if (step === steps[0]) return;
    const index = steps.indexOf(step);
    setStep(steps[index - 1]);
  };
  const goForward = () => {
    if (step === steps[steps.length - 1]) return;
    const index = steps.indexOf(step);
    setStep(steps[index + 1]);
  };

  const jumpTo = (step: T) => {
    if (!steps.includes(step)) return;
    setStep(step);
  };

  const index = steps.indexOf(step);

  return {
    step,
    steps,
    goBack,
    goForward,
    jumpTo,
    index,
    setFinished,
    isFinished,
  };
}

export function SetStepOnMount<T extends string = string>({
  step,
  children,
}: PropsWithChildren<{ step: T | 'finished' }>) {
  const { setStep, setFinished } = useContext(StepProgressContext);

  React.useEffect(() => {
    if (step === 'finished') {
      setFinished(true);
    } else {
      setStep(step);
    }
  }, [step, setStep]);

  return <>{children}</>;
}
