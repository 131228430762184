import { css } from '@emotion/react';
import { FetchBalanceResult } from 'wagmi/actions';
import styled from '@emotion/styled';

type BalanceDisplayProps = {
  data: FetchBalanceResult | undefined;
  isLoading: boolean;
  isError: boolean;
};

const style = css({
  fontWeight: 'bold',
});

const Container = styled.div({
  ...style,
});

export function BalanceDisplay({
  data,
  isError,
  isLoading,
}: BalanceDisplayProps) {
  return <Container>Balance: {data ? data.formatted : 'Loading...'}</Container>;
}
