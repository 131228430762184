export const ImagePreview = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1058_22762)">
      <path
        d="M30.375 6.75H5.625C5.00368 6.75 4.5 7.25368 4.5 7.875V28.125C4.5 28.7463 5.00368 29.25 5.625 29.25H30.375C30.9963 29.25 31.5 28.7463 31.5 28.125V7.875C31.5 7.25368 30.9963 6.75 30.375 6.75Z"
        stroke="#837F8A"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9375 15.75C22.8695 15.75 23.625 14.9945 23.625 14.0625C23.625 13.1305 22.8695 12.375 21.9375 12.375C21.0055 12.375 20.25 13.1305 20.25 14.0625C20.25 14.9945 21.0055 15.75 21.9375 15.75Z"
        fill="#837F8A"
      />
      <path
        d="M20.7158 23.0627L24.3285 19.4542C24.5394 19.2434 24.8255 19.125 25.1237 19.125C25.422 19.125 25.708 19.2434 25.9189 19.4542L31.5004 25.0399"
        stroke="#837F8A"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 23.7219L12.1416 16.079C12.246 15.9744 12.3701 15.8914 12.5067 15.8348C12.6433 15.7782 12.7897 15.749 12.9375 15.749C13.0853 15.749 13.2317 15.7782 13.3683 15.8348C13.5049 15.8914 13.629 15.9744 13.7334 16.079L26.903 29.2499"
        stroke="#837F8A"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1058_22762">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
