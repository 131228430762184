import styled from '@emotion/styled';
import { CardBase } from '../card-base';
import { media } from '../styles/media-query';

export const SmallCenteredContainer = styled(CardBase)(({ theme }) => ({
  width: '100%',
  maxWidth: '520px',
  margin: '50px auto 20px auto',
  padding: '56px 28px',
  ...media('sm', {
    padding: 56,
  }),
}));
