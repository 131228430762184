import mixpanel from 'mixpanel-browser';
import { ENV_ANALYTICS_KEY, ENV_ANALYTICS_PROXY_HOST } from '~/app/config';

// create an instance of the mixpanel client
mixpanel.init(ENV_ANALYTICS_KEY, {
  debug: process.env.NODE_ENV !== 'production',
  api_host: ENV_ANALYTICS_PROXY_HOST,
  ignore_dnt: true,
});

const silent = process.env.NODE_ENV === 'production';

export const Tracker = {
  identify(id: string) {
    if (!silent) {
      console.debug('Analytics.identify', id);
    }
    mixpanel.identify(id);
  },
  track: (event: string, properties?: any) => {
    if (!silent) {
      console.debug('Analytics.track', event, properties);
    }
    mixpanel.track(event, properties);
  },
};

export function prefixedAnalytics(prefix: string) {
  return {
    track: (event: string, properties?: any) => {
      Tracker.track(`${prefix}.${event}`, properties);
    },
  };
}
