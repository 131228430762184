export type TransferType =
  | 'erc20transfer'
  | 'erc721transfer'
  | 'transfer'
  | 'unknown';

export function defaultGasLimit(mode: TransferType) {
  if (mode === 'transfer') {
    return BigInt(21000);
  } else if (mode === 'erc20transfer') {
    return BigInt(210000);
  } else if (mode === 'erc721transfer') {
    // @todo appropriate value
    return BigInt(10000000000);
  } else {
    return BigInt(0);
  }
}
