import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { BodyPrimaryText, MarkdownLink, Title } from '~/shared/ui/kit';

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.warning[20],
  padding: `20px`,
}));

const KYB_LINK = 'https://p6iab36j07m.typeform.com/to/MblQUpor';

export function InactiveMerchantBanner({ ...rest }) {
  const { t } = useTranslation('merchant');

  return (
    <Container {...rest}>
      <div
        css={{
          maxWidth: 900,
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Title
          h={4}
          as={'h4'}
          css={{
            marginBottom: 16,
            textAlign: 'center',
          }}
        >
          {t('dashboard.inactiveTitle')}
        </Title>
        <BodyPrimaryText
          as="p"
          css={{
            textAlign: 'center',
          }}
        >
          <Trans
            t={t}
            i18nKey={'dashboard.inactiveDescription'}
            components={[
              <MarkdownLink
                href={KYB_LINK}
                target="_blank"
                css={{
                  fontWeight: 700,
                }}
              ></MarkdownLink>,
            ]}
          ></Trans>
        </BodyPrimaryText>
      </div>
    </Container>
  );
}
