import { CurtainLoader } from '~/shared/ui/kit';
import { useAccount } from 'wagmi';
import { AuthorizedView } from './authorized-view';
import { NotAuthorizedView } from './not-authorized-view';
import { BuyPageProvider, useQuvioParams } from './shared';
import { ErrorView } from './views/0-error-view';
import { AnalyticsPropertiesProvider } from '~/shared/analytics/react';
import { firstErrorMessage, isFailedFetch } from '~/shared/api';
import { ChangeLanguage } from '~/features/i18n/change-language';
import { StepProgress } from '~/shared/ui/kit/step-progress';
import {
  StepProgressProvider,
  useStepProgress,
} from '~/shared/hooks/useStepProgress';
import { PayWithQuvioStep, steps as stepsObjects } from './views/shared';

function ConnectedStepProgress({ ...rest }) {
  const { index, isFinished, steps } = useStepProgress<PayWithQuvioStep>();
  const finishedIndex = steps.length + 1;

  return (
    <StepProgress
      steps={stepsObjects}
      currentStep={isFinished ? finishedIndex : index}
      {...rest}
    ></StepProgress>
  );
}

export function PayWithQuvio() {
  const params = useQuvioParams();
  const account = useAccount();

  if (!params) {
    return <div>Invalid configuration</div>;
  }

  const MainViewElement = account.address ? AuthorizedView : NotAuthorizedView;

  return (
    <BuyPageProvider
      renderLoading={() => (
        <CurtainLoader text={'Preparing payment solutions'} />
      )}
      renderError={(error) => {
        let message = isFailedFetch(error)
          ? firstErrorMessage(error.response?.detail || [])
          : error.message;

        return (
          <ErrorView
            error={message}
            params={params}
            css={{
              margin: 'auto',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          />
        );
      }}
    >
      <div
        css={{
          padding: '10px 20px',
          display: 'flex',
          marginBottom: -40,
          height: 60,
        }}
      >
        <ChangeLanguage
          css={{
            marginLeft: 'auto',
            position: 'relative',
            zIndex: 1,
          }}
        />
      </div>
      <StepProgressProvider steps={stepsObjects.map((step) => step.key)}>
        <div
          css={{
            marginTop: '50px',
            marginBottom: '20px',
          }}
        >
          <ConnectedStepProgress
            css={{
              maxWidth: '400px',
              margin: 'auto',
              marginBottom: 24,
            }}
          ></ConnectedStepProgress>
          <AnalyticsPropertiesProvider
            props={{
              session_id: params.signature,
              client_id: params.client_id,
            }}
          >
            <MainViewElement
              css={{
                margin: 'auto',
              }}
            />
          </AnalyticsPropertiesProvider>
        </div>
      </StepProgressProvider>
    </BuyPageProvider>
  );
}
