import { KindOfBigNumber } from '~/shared/ethereum/types';
import { FiatBalance } from '~/shared/fiat-converter';
import { Delimiter, FormItemDisplay } from '~/shared/ui/kit';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { useTranslation } from 'react-i18next';
import { TransactionNetworkFee } from '~/shared/signing-transactions';

export function TransactionDetails({
  from,
  to,
  tokenName,
  tokenSymbol,
  amount,
  feeMatic,
  maxTotal,
}: {
  tokenName: string;
  tokenSymbol: string;
  from: string;
  to?: string;
  amount: string;
  maxTotal: FiatBalance;
  feeMatic: KindOfBigNumber;
}) {
  const { t } = useTranslation('common');
  return (
    <>
      <FormItemDisplay
        name={t('glossary.asset')}
        value={tokenName}
        css={{ marginBottom: 24 }}
      />
      <FormItemDisplay
        name={t('glossary.from')}
        value={trimMiddle(from || '', 20)}
        css={{ marginBottom: 24 }}
      />
      {to && (
        <FormItemDisplay
          name={t('glossary.to')}
          value={trimMiddle(to, 20)}
          css={{ marginBottom: 24 }}
        />
      )}
      <FormItemDisplay
        name={t('glossary.amount')}
        value={`${amount} ${tokenSymbol}`}
        css={{ marginBottom: 24 }}
      />

      <Delimiter css={{ marginBottom: 24 }} />

      <TransactionNetworkFee feeMatic={feeMatic} css={{ marginBottom: 24 }} />
      <FormItemDisplay
        name={t('glossary.maxTotal')}
        value={`${maxTotal.formatted}`}
      />
    </>
  );
}
