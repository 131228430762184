import { autorun, makeAutoObservable } from 'mobx';
import { appModel } from '~/app/app-model';
import { router } from '~/app/routing';
import { MerchantApi, authorizeMerchant } from './api';
import { AccessToken } from './types';

const tokenStorage = {
  persist(token: AccessToken) {
    localStorage.setItem('merchant-token', JSON.stringify(token));
  },
  restore(): AccessToken | null {
    const token = localStorage.getItem('merchant-token');

    if (!token) {
      return null;
    } else {
      let parsed: AccessToken;
      try {
        parsed = JSON.parse(token);
      } catch (error) {
        return null;
      }

      if (typeof parsed !== 'object') {
        return null;
      }

      if (
        parsed.access_token &&
        parsed.expiry_at &&
        parsed.refresh_token &&
        parsed.refresh_token_expiry_at
      ) {
        return parsed;
      }

      return null;
    }
  },
  clear() {
    localStorage.removeItem('merchant-token');
  },
};

export class MerchantModel {
  api: MerchantApi = new MerchantApi(
    tokenStorage.restore(),
    this.onTokenUpdated.bind(this)
  );

  token: AccessToken | null = tokenStorage.restore();
  isLoggingIn: boolean = false;

  _disposers: (() => void)[] = [];

  get isAuthenticated() {
    return !!this.token;
  }

  constructor() {
    makeAutoObservable(this);

    this._disposers.push(
      autorun(() => {
        if (this.token) {
          tokenStorage.persist(this.token);
          this.api.setAccessToken(this.token);
        } else {
          this.api.clearAccessToken();
          tokenStorage.clear();
        }
      })
    );
  }

  async _authenticate(ticket: string) {
    this.isLoggingIn = true;
    try {
      let response = await authorizeMerchant({ ticket });
      return response;
    } finally {
      this.isLoggingIn = false;
    }
  }

  onTokenUpdated(token: AccessToken | null) {
    if (!token) {
      this.logout();
      router.navigate('/merchant/login', {
        replace: true,
      });
    }
  }

  async login(ticket: string) {
    if (this.isLoggingIn) {
      return;
    }

    try {
      const token = await this._authenticate(ticket);

      // If merchant tries to login from different account
      // but already logged in, we need to logout first

      if (this.isAuthenticated) {
        this.logout();
      }

      this.token = token.data;

      router.navigate('/merchant/dashboard', { replace: true });
    } catch (error) {
      router.navigate('/merchant/login/fail', {
        state: {
          errorMessage: 'Login failed. Please try again.',
        },
      });
    }
  }

  logout() {
    this.token = null;
  }

  get context() {
    const self = this;

    return {
      token: this.token,
      isAuthenticated: this.isAuthenticated,
      api: this.api,
      login: this.login.bind(self),
      logout: this.logout.bind(self),
    };
  }

  dispose() {
    this._disposers.forEach((dispose) => dispose());
  }
}

export const merchantModel = new MerchantModel();
