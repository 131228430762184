import { useEffect, useState } from 'react';

const WERT_PARAM_NAME = 'wertMaxResolveTime';

const getResolutionTime = () => {
  return new Date().getTime() + TWO_MINUTES;
};

export function addWertStatusTimeParam(url: string) {
  // resolve after 2 minutes has passed since receiving success
  const resolutionTime = getResolutionTime();

  return url + `&${WERT_PARAM_NAME}=${resolutionTime}`;
}

function hasDelayedResolutionParam(search: string) {
  const params = new URLSearchParams(search);
  return params.has(WERT_PARAM_NAME);
}

function parseResolionTime(search: string): Date {
  const params = new URLSearchParams(search);
  const timeValue = params.get(WERT_PARAM_NAME);
  return new Date(Number(timeValue) || 0);
}

const TWO_MINUTES = 2 * 60 * 1000;

const timeUntilDate = (date: Date) => {
  return date.getTime() - new Date().getTime();
};

export function useWertResolve(search: string) {
  const hasParam = hasDelayedResolutionParam(search);
  const resolutionTime = parseResolionTime(search);
  // resolve immediately, if no time is set
  const [isWertResolved, setIsWertResolved] = useState(!hasParam);

  useEffect(() => {
    if (hasParam && !isWertResolved) {
      let id = setTimeout(() => {
        setIsWertResolved(true);
      }, timeUntilDate(resolutionTime));

      return () => {
        clearTimeout(id);
      };
    }
  }, [hasParam, isWertResolved]);

  return isWertResolved;
}
