import {
  ChangeEventHandler,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
} from 'react';
import { Icon } from '../icon';
import { CheckboxIcon } from '../icon/icons/checkbox';

function _Checkbox(
  {
    children,
    value,
    onChange,
    ...rest
  }: PropsWithChildren<{
    value: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
  }>,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <label
      css={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      {...rest}
    >
      <input
        type="checkbox"
        checked={!!value}
        onChange={onChange}
        ref={ref}
        css={{
          visibility: 'hidden',
          position: 'absolute',
          width: 0,
          height: 0,
          margin: 0,
          padding: 0,
          border: 0,
        }}
      />
      <Icon
        css={{
          marginRight: 8,
          padding: 4,
        }}
      >
        <CheckboxIcon checked={!!value} />
      </Icon>

      {children}
    </label>
  );
}

export const Checkbox = forwardRef(_Checkbox);
