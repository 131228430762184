import { useCallback, useMemo, useState } from 'react';

type AsyncStatus = 'idle' | 'loading' | 'success' | 'error';

export function useAsyncStatus() {
  const [status, setStatus] = useState<AsyncStatus>('idle');

  const isLoading = status === 'loading';
  const isSuccess = status === 'success';
  const isError = status === 'error';

  return {
    isLoading,
    isSuccess,
    isError,
    setStatus,
  };
}

type InferArgs<T> = T extends (...t: [...infer Arg]) => any ? Arg : never;
type InferReturn<T> = T extends (...t: [...infer Arg]) => infer Res
  ? Res
  : never;
type AnyFunction = (...args: any[]) => any;

const wrap = function <TFunc extends AnyFunction>(
  action: TFunc,
  setStatus: (status: AsyncStatus) => void
) {
  const wrapped = async (
    ...args: InferArgs<typeof action>
  ): Promise<InferReturn<typeof action>> => {
    setStatus('loading');
    try {
      const result = await action(...args);
      setStatus('success');
      return result;
    } catch (error) {
      setStatus('error');
      throw error;
    }
  };

  return wrapped;
};

export function useAsyncAction<TFunc extends AnyFunction>(action: TFunc) {
  const { isLoading, isSuccess, isError, setStatus } = useAsyncStatus();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wrappedCallback = useCallback(wrap(action, setStatus), [
    action,
    setStatus,
  ]);

  const memoized = useMemo(() => {
    return {
      run: wrappedCallback,
      isLoading,
      isSuccess,
      isError,
    };
  }, [wrappedCallback, isLoading, isSuccess, isError]);

  return memoized;
}
