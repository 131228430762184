import { Nft } from 'alchemy-sdk';

export type TransferFormValues = {
  token: Nft | null;
  recipient: string;
  transaction: string;
};

export type TransferFormStep = 'enterRecipient' | 'confirm' | 'success';
export const transferFormSteps: TransferFormStep[] = [
  'enterRecipient',
  'confirm',
  'success',
];
