import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ImagePreview, OutlinedIcon } from '~/shared/ui/kit';

const Container = styled.div((props) => ({
  minHeight: 240,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  p: {
    marginTop: 16,
    color: props.theme.colors.neutral[60],
    maxWidth: 220,
    fontSize: 14,
  },
}));

export function NftsEmpty() {
  const { t } = useTranslation('common');

  return (
    <Container>
      <OutlinedIcon size="large">
        <ImagePreview />
      </OutlinedIcon>
      <p>{t('nft.nftDisplayedHere')}</p>
    </Container>
  );
}
