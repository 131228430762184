import styled from '@emotion/styled';
import { useAccount } from 'wagmi';

import { isMatic, useAccountTokens } from '~/entities/tokens';
import { createTransfer } from '~/features/top-up';
import {
  Button,
  Buttons,
  LinkButton,
  Title,
  BodyPrimaryText,
  MarkdownLink,
  Icon,
} from '~/shared/ui/kit';
import { useBuyPageContext } from '../shared';
import { HexString } from '~/shared/types';
import { signTransactionLocally } from '~/shared/ethereum/transaction-helpers';
import { TransactionDetails } from '~/features/send-tokens/ui/transaction-details';
import {
  prepareTransferParameters,
  useManualTxPrepare,
} from '~/features/send-tokens';
import { PaymentViewProps, ViewContainer } from '../ui/ViewContainer';
import { PaymentHeader } from '../ui/PaymentHeader';
import { UserAvatar } from '~/widgets/user-avatar';
import { PaymentAmount } from '../ui/PaymentAmount';
import { useReturnToMerchant } from '../ui/BackButton';
import { UserPaymentBalance } from '../ui/UserPaymentBalance';
import { usePaymentBalance } from '../shared/use-payment-balance';
import { useFiatValue } from '~/shared/fiat-converter';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWeb3AuthContext } from '~/shared/ethereum/web3auth';
import { alchemy } from '~/shared/ethereum/alchemy';
import { useActiveChain } from '~/shared/ethereum/chain-provider';
import { toEthers } from '~/shared/ethereum/units';
import { useMemo, useState } from 'react';
import { analytics, useTracker } from '~/shared/analytics';
import { useAsyncAction } from '~/shared/hooks/useAsyncStatus';
import { Checkbox } from '~/shared/ui/kit/checkbox';
import { ErrorMessage } from '~/shared/ui/kit/input-base/input-base';
import { MerchantFavicon } from '../ui/MerchantFavicon';
import { MerchantDetails } from '../ui/MerchantDetails';
import { useTranslation, Trans } from 'react-i18next';
import { FixedNumber } from 'ethers';
import { AppLogoFilled, whiteLabelConfig } from '~/white-label';
import { useFeeEstimation } from '~/shared/signing-transactions';
import { RoutePath } from '~/app/routing';

const SecondaryMaterial = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.neutral[20],
  padding: 24,
  borderRadius: 20,
}));

export function ConfirmationView(props: PaymentViewProps) {
  const { t } = useTranslation('common');
  const [isTermsAccepted, setIsTermsAccepted] = useState(true);
  const [termsError, setTermsError] = useState(false);

  const w3a = useWeb3AuthContext();
  const provider = w3a.web3auth.provider;
  const currentChainId = useActiveChain();
  const { params, token, merchant } = useBuyPageContext();
  const returnToMerchant = useReturnToMerchant(params);
  const usingMatic = isMatic(token.address);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const tracker = useTracker();

  analytics.useOnMountEvent('buy_tokens.confirmation');

  // load tokens and account data
  const account = useAccount();
  const tokens = useAccountTokens({
    address: account.address,
  });

  // prepare transaction config, populate with params
  const sendParams = useMemo(
    () => ({
      to: merchant.receiving_address as HexString,
      from: account.address as HexString,
      amount: params.amount,
      token: {
        address: token.address,
        decimals: token.decimals,
        symbol: token.symbol,
      },
    }),
    [
      merchant.receiving_address,
      account.address,
      params.amount,
      token.address,
      token.decimals,
      token.symbol,
    ]
  );

  const transactionConfig = useManualTxPrepare(sendParams);

  // estimate transaction fees
  const fee = useFeeEstimation(
    transactionConfig,
    usingMatic ? 'transfer' : 'erc20transfer'
  );
  // Calculate fiat value
  const fiatValue = useFiatValue({
    value: Number(params.amount),
    symbol: token.symbol,
  });

  // Check user balance
  const paymentToken = usePaymentBalance(params);
  const userBalance = paymentToken
    ? toEthers(paymentToken.balance || 0, paymentToken.decimals)
    : FixedNumber.from(0);
  const isCheckingBalance = tokens.isLoading;
  const isEnoughBalance =
    userBalance.toUnsafeFloat() >= Number.parseFloat(params.amount);

  const isLoading = isCheckingBalance || !account.address;

  const createTransferRequest = async () => {
    if (!provider || !account.address) {
      return;
    }

    const config = await prepareTransferParameters(sendParams);
    const signedTransaction = await signTransactionLocally({
      chainId: currentChainId,
      provider,
      alchemy,
      config,
      transferType: usingMatic ? 'transfer' : 'erc20transfer',
    });

    const transferId = await createTransfer({
      rawTx: signedTransaction,
      orderId: params.order_id,
      sender: account.address,
      clientId: params.client_id,
    });

    if (isEnoughBalance) {
      tracker.track('buy_tokens.confirmation.success.balance_enough');
      navigate(
        RoutePath.PayWithQuvio.Status({
          transferId: transferId.data.unique_id,
          search: search.toString(),
        })
      );
      navigate(`/buy/${transferId.data.unique_id}/status?${search}`);
    } else {
      tracker.track('buy_tokens.confirmation.success.balance_not_enough');
      navigate(`/buy/${transferId.data.unique_id}/top-up?${search}`);
    }
  };

  const buttonAction = useAsyncAction(createTransferRequest);

  return (
    <ViewContainer {...props}>
      {/* <ChangeLanguage
        css={{
          position: 'absolute',
          top: 20,
          right: 20,
        }}
      /> */}
      <PaymentHeader
        css={{ marginBottom: 24, width: '100%' }}
        amount={
          <PaymentAmount currency={params.currency} amount={params.amount} />
        }
      >
        <UserAvatar
          css={{
            flexShrink: 0,
          }}
        ></UserAvatar>
        <Icon size={32}>
          <AppLogoFilled />
        </Icon>
        <MerchantFavicon
          merchant={merchant}
          css={{
            flexShrink: 0,
          }}
        />
      </PaymentHeader>

      <Title h={5} as="div" css={{ marginBottom: 8 }}>
        {t('paymentFlow.confirmationScreen.informationTitle')}
      </Title>
      <MerchantDetails merchant={merchant} css={{ marginBottom: 8 }} />
      <SecondaryMaterial>
        <TransactionDetails
          from={account.address || ''}
          amount={params.amount}
          tokenName={token.name}
          tokenSymbol={token.symbol}
          feeMatic={fee.feeMatic}
          maxTotal={fiatValue.add(fee.feeFiat)}
        />
      </SecondaryMaterial>

      <Title h={5} as={'div'} css={{ marginBottom: 8, marginTop: 24 }}>
        {t('paymentFlow.confirmationScreen.payWithTitle')}
      </Title>
      <UserPaymentBalance token={paymentToken}></UserPaymentBalance>

      <div css={{ marginTop: 24, marginBottom: 16 }}>
        <Checkbox
          value={isTermsAccepted}
          onChange={() => {
            if (!isTermsAccepted) {
              setTermsError(false);
            }

            setIsTermsAccepted(!isTermsAccepted);
          }}
        >
          <BodyPrimaryText as="p" css={{ fontSize: 14 }}>
            <Trans
              t={t}
              i18nKey="paymentFlow.confirmationScreen.legalAgreement"
              components={[
                <MarkdownLink
                  href={whiteLabelConfig.tosLink}
                  target="_blank"
                  css={{
                    fontWeight: 700,
                  }}
                />,
                <MarkdownLink
                  href={whiteLabelConfig.privacyPolicyLink}
                  target="_blank"
                  css={{
                    fontWeight: 700,
                  }}
                ></MarkdownLink>,
              ]}
            ></Trans>
          </BodyPrimaryText>
        </Checkbox>
        {termsError && (
          <ErrorMessage
            css={{
              marginTop: 8,
              display: 'block',
            }}
          >
            {t('paymentFlow.confirmationScreen.legalAgreementValidationError')}
          </ErrorMessage>
        )}
      </div>

      <Buttons nButtons={1}>
        <Button
          semanticType="primary"
          onClick={() => {
            if (!isTermsAccepted) {
              setTermsError(true);
              return;
            }

            setTermsError(false);
            tracker.track('buy_tokens.confirmation.user-confirmed');
            buttonAction.run();
          }}
          disabled={isLoading}
          isLoading={isLoading || buttonAction.isLoading}
        >
          {isEnoughBalance ? 'Transfer' : 'Top up and transfer'}
        </Button>
      </Buttons>

      <div css={{ marginTop: 24, textAlign: 'center' }}>
        <LinkButton onClick={returnToMerchant}>
          {t('paymentFlow.cancelAndReturnToMerchant')}
        </LinkButton>
      </div>
    </ViewContainer>
  );
}
