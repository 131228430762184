import useSWR from 'swr';
import { useMerchantSettings } from '~/pages/merchant-zone/data-hooks';

// Create order
// POST /v1/merchant/order

// {
//   "client_id": "ede02135-55b1-42be-915d-a9450af78a40",
//   "order_id": "5bed426b-0309-4c0a-ad96-e6a1fa561ab5",
//   "amount": "700000000000000000",
//   "currency": "USDT",
//   "recipient": "0x2cD807D9047b157DE1e0661728A79fb484527b5E",
//   "signature": "d2688753afbc0907b539b0596e79f389a4aab5333486f5e9376b4be4311c5838"
// }

function sha256(data: string) {
  const encoder = new TextEncoder();
  const dataEncoded = encoder.encode(data);
  return crypto.subtle.digest('SHA-256', dataEncoded).then((hash) => {
    return Array.from(new Uint8Array(hash))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  });
}

async function createMerchantSignature({
  order_id,
  currency,
  amount,
  client_id,
  client_secret,
  email,
  sumsub_token,
}: {
  order_id: string;
  currency: string;
  amount: string;
  client_id: string;
  client_secret: string;
  email?: string;
  sumsub_token?: string;
}) {
  const parameters = {
    order_id,
    amount,
    currency,
    client_id,
    email,
    sumsub_token,
  };

  const keys = Object.keys(parameters) as (keyof typeof parameters)[];
  keys.sort();
  let hashString = keys
    .map((key) => key + '=' + encodeURIComponent(parameters[key] || ''))
    .join('&');
  hashString += '&client_secret=' + client_secret;

  const signature = await sha256(hashString);
  return signature;
}

const optionalQuery = (key: string, value?: string) => {
  return value ? `&${key}=${encodeURIComponent(value)}` : '';
};
export async function merchantBuyLink({
  order_id,
  client_id,
  client_secret,
  currency,
  amount,
  email,
  sumsub_token,
  redirect_url,
}: {
  order_id: string;
  client_id: string;
  client_secret: string;
  currency: string;
  amount: string;
  sumsub_token?: string;
  email?: string;
  redirect_url?: string;
}) {
  const signature = await createMerchantSignature({
    order_id,
    currency,
    amount,
    client_id,
    client_secret: client_secret,
    email,
    sumsub_token,
  });

  const enc = encodeURIComponent;

  return `/buy?order_id=${enc(order_id)}&currency=${enc(currency)}&amount=${enc(
    amount
  )}&sumsub_token=${enc(sumsub_token || '')}&email=${enc(
    email || ''
  )}&client_id=${enc(client_id)}&signature=${signature}${optionalQuery(
    'redirect_url',
    redirect_url
  )}`;
}

export function MerchantBuyLink({
  orderId,
  currency,
  amount,
  email,
  sumsub_token,
  redirect_url,
  client_secret,
  render,
}: {
  orderId: string;
  currency: string;
  amount: string;
  client_secret: string;
  email?: string;
  sumsub_token?: string;
  redirect_url?: string;
  render: (link: string) => JSX.Element;
}) {
  const settings = useMerchantSettings();

  const link = useSWR(
    settings.data?.data.client_id
      ? 'get-order-link' +
          orderId +
          email +
          amount +
          currency +
          sumsub_token +
          redirect_url +
          client_secret
      : false,
    () => {
      return merchantBuyLink({
        client_secret,
        client_id: settings.data?.data.client_id || '',
        order_id: orderId,
        currency,
        amount,
        email,
        sumsub_token,
        redirect_url,
      });
    }
  );

  return render(link.data || '');
}
