import { ThemeProvider } from '@emotion/react';
import { jwalTheme } from './themes/jwal';
import { quvTheme } from './themes/quv';
import { maticonTheme } from './themes/maticon';
import { metaticTheme } from './themes/metatic';
import { polystockTheme } from './themes/polystock';
import { ENV_WHITELABEL_NAME } from '~/app/config';
import { ColorThemeType } from './themes/types';

const themeMap: {
  [key: string]: ColorThemeType;
} = {
  ['Jwal.io']: jwalTheme,
  ['Quv.io']: quvTheme,
  ['Maticon.xyz']: maticonTheme,
  ['Metatic.xyz']: metaticTheme,
  ['Polystock.xyz']: polystockTheme,
};

export function ColorTheme({ children }: { children: React.ReactNode }) {
  const theme = themeMap[ENV_WHITELABEL_NAME] || quvTheme;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
