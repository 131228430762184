import {
  Button,
  Buttons,
  CopyIcon,
  Icon,
  ModalHeading,
  SublineText,
} from '~/shared/ui/kit';
import { QRCodeSVG } from 'qrcode.react';
import { useAccount } from 'wagmi';
import copy from 'copy-to-clipboard';
import { toast } from '~/shared/toasts';
import { useTranslation } from 'react-i18next';
// TODO: customizable QR code https://github.com/kozakdenys/qr-code-styling

export function ReceiveQr() {
  const { t } = useTranslation('common');
  const account = useAccount();

  if (!account.address) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <ModalHeading>{t('glossary.receive')}</ModalHeading>

      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '24px 0 24px 0',
        }}
      >
        <QRCodeSVG
          value={account.address}
          css={{ width: '100%', height: '100%', maxWidth: 285 }}
        ></QRCodeSVG>
      </div>

      <SublineText
        css={{ textAlign: 'center', marginTop: 24, wordBreak: 'break-word' }}
        as="div"
      >
        {account.address}
      </SublineText>

      <Buttons nButtons={1} css={{ marginTop: 24 }}>
        <Button
          semanticType="primary"
          onClick={() => {
            copy(account.address || '');
            toast.success(t('glossary.addressCopied'));
          }}
        >
          {t('glossary.copyAddress')}
          <Icon css={{ marginLeft: 12 }}>
            <CopyIcon />
          </Icon>
        </Button>
      </Buttons>
    </div>
  );
}
