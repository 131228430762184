import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

export function FormItemDisplay({
  name,
  value,
  ...rest
}: {
  name: string;
  value: string | React.ReactNode;
}) {
  const theme = useTheme();

  return (
    <div
      {...rest}
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        letterSpacing: '-0.02em',
        fontWeight: 500,
      }}
    >
      <div
        css={{
          color: theme.colors.neutral[80],
        }}
      >
        {name}
      </div>
      <div
        css={{
          color: theme.colors.neutral[60],
        }}
      >
        {value}
      </div>
    </div>
  );
}

export const Delimiter = styled.div((props) => ({
  border: `1px dashed ${props.theme.colors.neutral[40]}`,
}));
