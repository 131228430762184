import { useTheme } from '@emotion/react';
import {
  AttentionIcon,
  BodyPrimaryText,
  Button,
  Buttons,
  CheckOutlinedIcon,
  OutlinedIcon,
  Spinner,
  Title,
} from '~/shared/ui/kit';

import useSWR from 'swr';
import { useBuyPageContext } from '../../shared';
import { useLocation, useParams } from 'react-router-dom';
import { getTransferStatus } from '~/features/top-up';
import invariant from 'tiny-invariant';
import { PaymentViewProps, ViewContainer } from '../../ui/ViewContainer';
import { useReturnToMerchant } from '../../ui/BackButton';
import { analytics, useTracker } from '~/shared/analytics';
import {
  fixUndefinedStatus,
  isErrorStatus,
  TransferStatus,
} from '~/entities/merchant/types';
import { useTranslation } from 'react-i18next';
import { SetStepOnMount } from '~/shared/hooks/useStepProgress';
import { PayWithQuvioStep } from '../shared';
import { useWertResolve } from '../../fix-wert-status';

const waitingStatusText: {
  [key: number]: string | undefined;
} = {
  [TransferStatus.NONE]: '',
  [TransferStatus.ORDER_FIAT_ACCEPTED]:
    'paymentFlow.statusScreen.waitingCardPayment',
  [TransferStatus.ORDER_FIAT_COMPLETED]:
    'paymentFlow.statusScreen.waitingSendingTokens',
};

function WaitingStatus({ status }: { status: TransferStatus }) {
  const { t } = useTranslation('common');
  // TODO: get real text from design
  analytics.useOnMountEvent('buy_tokens.status_screen.waiting');

  const textKey = waitingStatusText[status];

  const statusText = textKey ? t(textKey) : '';

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '600px',
        maxWidth: 400,
        textAlign: 'center',
        padding: 24,
        margin: 'auto',
      }}
    >
      <Spinner
        css={{
          marginBottom: 24,
        }}
      />

      <Title h={3} as="div" css={{ marginBottom: 12 }}>
        {t('glossary.pleaseWait')}...
      </Title>

      <BodyPrimaryText>{statusText}</BodyPrimaryText>
    </div>
  );
}

function SuccessStatus(props: PaymentViewProps) {
  const { t } = useTranslation('common');
  const { params } = useBuyPageContext();
  const theme = useTheme();
  const returnToMerchant = useReturnToMerchant(params);
  const tracker = useTracker();

  analytics.useOnMountEvent('buy_tokens.status_screen.success');

  return (
    <ViewContainer
      {...props}
      css={{
        textAlign: 'center',
      }}
    >
      <OutlinedIcon
        size={'large'}
        color={theme.colors.success[40]}
        css={{
          color: theme.colors.success[120],
          marginBottom: 32,
        }}
      >
        <CheckOutlinedIcon />
      </OutlinedIcon>
      <Title h={3} as="div" css={{ marginBottom: 8 }}>
        {t('paymentFlow.statusScreen.successTitle')}
      </Title>
      <BodyPrimaryText
        css={{
          color: theme.colors.neutral[60],
        }}
      >
        {t('paymentFlow.statusScreen.successDescription')}
      </BodyPrimaryText>
      <Buttons nButtons={1} css={{ marginTop: 32 }}>
        <Button
          semanticType="primary"
          onClick={() => {
            tracker.track(
              'buy_tokens.status_screen.success.click_return_to_merchant'
            );
            returnToMerchant();
          }}
        >
          {t('paymentFlow.returnToMerchant')}
        </Button>
      </Buttons>
    </ViewContainer>
  );
}

function ErrorStatus(props: PaymentViewProps) {
  const { t } = useTranslation('common');
  const { params } = useBuyPageContext();
  const theme = useTheme();
  const returnToMerchant = useReturnToMerchant(params);

  const tracker = useTracker();
  analytics.useOnMountEvent('buy_tokens.status_screen.error');

  return (
    <ViewContainer
      {...props}
      css={{
        textAlign: 'center',
      }}
    >
      <OutlinedIcon
        size={'large'}
        color={theme.colors.critical[20]}
        css={{
          color: theme.colors.critical[100],
          marginBottom: 32,
        }}
      >
        <AttentionIcon />
      </OutlinedIcon>
      <Title h={3} as="div" css={{ marginBottom: 8 }}>
        {t('paymentFlow.statusScreen.errorTitle')}
      </Title>
      <BodyPrimaryText
        css={{
          color: theme.colors.neutral[60],
        }}
      >
        {t('paymentFlow.statusScreen.errorDescription')}
      </BodyPrimaryText>
      <Buttons nButtons={1} css={{ marginTop: 32 }}>
        <Button
          semanticType="primary"
          onClick={() => {
            tracker.track(
              'buy_tokens.status_screen.error.click_return_to_merchant'
            );
            returnToMerchant();
          }}
        >
          {t('paymentFlow.returnToMerchant')}
        </Button>
      </Buttons>
    </ViewContainer>
  );
}

export function StatusView(props: PaymentViewProps) {
  // const [fakeStatus, setFakeStatus] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setFakeStatus((prev) => (prev === 2 ? 0 : prev + 1));
  //   }, 2000);
  //   return () => clearInterval(interval);
  // });

  const location = useLocation();

  const params = useParams();

  const status = useSWR(
    params.transferId ? 'status-' + params.transferId : false,
    () => {
      invariant(params.transferId, 'transferId is required');
      return getTransferStatus(params.transferId);
    },
    {
      refreshInterval: 3000,
    }
  );

  const isWertResolved = useWertResolve(location.search);
  let transferStatus = fixUndefinedStatus(status.data?.data.status);

  console.debug({
    transferStatus,
    isWertResolved,
  });
  // Hack to show waiting status for 2 minutes (max) after Wert local resolution
  if (transferStatus === TransferStatus.ORDER_FIAT_ERROR && !isWertResolved) {
    return (
      <SetStepOnMount<PayWithQuvioStep> step="transfer">
        <WaitingStatus status={transferStatus} />;
      </SetStepOnMount>
    );
  } else if (
    transferStatus === TransferStatus.ORDER_CREATED ||
    transferStatus === TransferStatus.ORDER_FIAT_ACCEPTED
  ) {
    return (
      <SetStepOnMount<PayWithQuvioStep> step="transfer">
        <WaitingStatus status={TransferStatus.ORDER_FIAT_ACCEPTED} />;
      </SetStepOnMount>
    );
  } else if (transferStatus === TransferStatus.ORDER_COMPLETED) {
    return (
      <SetStepOnMount<PayWithQuvioStep> step="finished">
        <SuccessStatus {...props} />;
      </SetStepOnMount>
    );
  } else if (isErrorStatus(transferStatus)) {
    return (
      <SetStepOnMount<PayWithQuvioStep> step="transfer">
        <ErrorStatus {...props} />
      </SetStepOnMount>
    );
  }

  return (
    <SetStepOnMount<PayWithQuvioStep> step="transfer">
      <WaitingStatus status={transferStatus} />;
    </SetStepOnMount>
  );
}
