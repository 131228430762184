import { isAddress } from 'viem';

export function isValidAddress(message?: string) {
  return {
    message: message || "Receiver's address is not valid",
    test: (address: string) => {
      return isAddress(address || '');
    },
  };
}
