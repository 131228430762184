import { OnRampConfig, QuvioOrder } from '~/features/top-up/api';
import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { FetchTokenResult } from 'wagmi/dist/actions';
import {
  useOnRampConfig,
  useMerchantConfig,
  MerchantConfig,
} from '~/features/top-up';

import { useTokenData } from '~/entities/tokens';
import invariant from 'tiny-invariant';
import { useQuvioParams } from './quvio-params';
import { FailedFetch, isFailedFetch } from '~/shared/api';

export type BuyPageContextValue = {
  token: FetchTokenResult;
  params: QuvioOrder;
  config: OnRampConfig;
  merchant: MerchantConfig;
};

export const BuyPageContext = createContext<BuyPageContextValue>(null!);
export const useBuyPageContext = () => useContext(BuyPageContext);

interface SWRResponseState {
  data: any;
  error: any;
  isValidating: boolean;
  isLoading: boolean;
}
const swrHelpers = {
  isLoading: function (configs: SWRResponseState[]) {
    const isLoadings = configs.map((config) => config.isLoading);
    return isLoadings.some((isLoading) => isLoading);
  },
  isValidating: function (configs: SWRResponseState[]) {
    const isValidatings = configs.map((config) => config.isValidating);
    return isValidatings.some((isValidating) => isValidating);
  },
  isReady: function (configs: SWRResponseState[]) {
    const isReady = configs.map((config) => !!config.data);
    return isReady.every((isReady) => isReady);
  },

  error: function (configs: SWRResponseState[]) {
    const errors = configs.map((config) => config.error);
    return errors.find((error) => error);
  },
};

export function BuyPageProvider(
  props: PropsWithChildren<{
    renderLoading: () => JSX.Element;
    renderError: (error: Error | FailedFetch) => JSX.Element;
  }>
) {
  const params = useQuvioParams();
  const config = useOnRampConfig();
  const merchantConfig = useMerchantConfig(params);
  const tokenData = useTokenData({
    ticker: params ? params.currency : '',
  });

  /*
    BEWARE: https://github.com/vercel/swr/issues/2446
    when using multiple useSWR requests, you need to manually check if all of them are ready
    using || operator will not work, as useSWR relies on component accessing data to be marked as needed.
    Because || operator makes an early exit and will not access latter nodes, useSWR will not mark them as needed.
    and will not update
  */
  const batchedConfigs: SWRResponseState[] = [
    tokenData,
    config,
    merchantConfig,
  ];
  const error = swrHelpers.error(batchedConfigs);
  const isLoading = swrHelpers.isLoading(batchedConfigs);
  const isFinishedLoading = swrHelpers.isReady(batchedConfigs);
  const isReady =
    isFinishedLoading &&
    !!tokenData.data?.data &&
    !!config.data?.data &&
    !!merchantConfig.data?.data;

  if (isReady) {
    invariant(params, 'Params are required');
    invariant(tokenData.data?.data, 'Token data is required');
    invariant(config.data?.data, 'Config is required');
    invariant(merchantConfig.data?.data, 'Merchant config is required');

    const contextValue = {
      token: tokenData.data.data,
      merchant: merchantConfig.data.data,

      params: {
        ...params,
        email: params.email,
      },
      config: config.data.data,
    };

    return (
      <BuyPageContext.Provider value={contextValue}>
        {props.children}
      </BuyPageContext.Provider>
    );
  } else if (isLoading) {
    return props.renderLoading();
  } else if (error) {
    return props.renderError(error);
  }

  const missingTokenInformationError =
    tokenData.data && !tokenData.data.data
      ? new Error('Unsupported token')
      : null;

  return props.renderError(
    tokenData.error ||
      config.error ||
      missingTokenInformationError ||
      'Unknown error'
  );
}
