import { useEffect, useState } from 'react';

export const LoadingDots = () => {
  const [dots, setDots] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots === 3 ? 0 : dots + 1));
    }, 500);
    return () => clearInterval(interval);
  });

  return (
    <span>
      <span css={{ opacity: dots >= 1 ? 1 : 0 }}>.</span>
      <span css={{ opacity: dots >= 2 ? 1 : 0 }}>.</span>
      <span css={{ opacity: dots >= 3 ? 1 : 0 }}>.</span>
    </span>
  );
};
