import { ClockIcon, OutlinedIcon } from '~/shared/ui/kit';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const Container = styled.div((props) => ({
  minHeight: 380,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  p: {
    marginTop: 16,
    color: props.theme.colors.neutral[60],
    maxWidth: 220,
  },
}));

export function TransactionsEmpty() {
  const theme = useTheme();
  return (
    <Container>
      <OutlinedIcon>
        <ClockIcon color={theme.colors.neutral[60]} />
      </OutlinedIcon>
      <p>Your transactions will be displayed here</p>
    </Container>
  );
}
