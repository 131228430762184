import { useTheme } from '@emotion/react';

export const TxInIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_39_3759)">
        <path
          d="M4.25781 15.7422C5.39377 16.8793 6.84149 17.654 8.41782 17.9682C9.99415 18.2824 11.6283 18.1221 13.1134 17.5073C14.5986 16.8926 15.868 15.8512 16.7612 14.5149C17.6544 13.1786 18.1311 11.6073 18.1311 10C18.1311 8.39266 17.6544 6.82143 16.7612 5.48509C15.868 4.14875 14.5986 3.10736 13.1134 2.49265C11.6283 1.87794 9.99415 1.71754 8.41782 2.03175C6.84149 2.34595 5.39377 3.12064 4.25781 4.25781C3.50332 5.01165 2.90479 5.90679 2.49642 6.89207C2.08806 7.87735 1.87787 8.93344 1.87787 10C1.87787 11.0665 2.08806 12.1226 2.49642 13.1079C2.90479 14.0932 3.50332 14.9883 4.25781 15.7422ZM7.1875 8.4375C7.1875 8.27174 7.25334 8.11276 7.37055 7.99555C7.48776 7.87834 7.64674 7.8125 7.8125 7.8125C7.97826 7.8125 8.13723 7.87834 8.25444 7.99555C8.37165 8.11276 8.4375 8.27174 8.4375 8.4375V10.6797L11.7422 7.36718C11.863 7.25384 12.0218 7.18975 12.1875 7.1875C12.3535 7.1882 12.5128 7.25251 12.6328 7.36718C12.7502 7.48568 12.816 7.64572 12.816 7.8125C12.816 7.97928 12.7502 8.13931 12.6328 8.25781L9.32031 11.5625H11.5625C11.7283 11.5625 11.8872 11.6283 12.0044 11.7456C12.1216 11.8628 12.1875 12.0217 12.1875 12.1875C12.1875 12.3533 12.1216 12.5122 12.0044 12.6294C11.8872 12.7466 11.7283 12.8125 11.5625 12.8125H7.8125C7.73202 12.8127 7.65232 12.7968 7.57812 12.7656C7.42381 12.7032 7.30031 12.5825 7.23437 12.4297C7.20376 12.3526 7.18786 12.2704 7.1875 12.1875V8.4375Z"
          fill={theme.colors.success[100]}
        />
      </g>
      <defs>
        <clipPath id="clip0_39_3759">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(20 20) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
