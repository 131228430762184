import { useLayoutEffect, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { WertWidgetEvent } from './types';
import { WERT_URL } from './config';

function isWertEvent(widgetWindow: Window, event: MessageEvent): boolean {
  const thisWidgetEvent = event.source === widgetWindow;
  // const expectedOrigin = event.origin === this.origin;
  const isDataObject = typeof event.data === 'object';

  if (!thisWidgetEvent || !isDataObject) return false;

  return true;
}

type WertParams = {
  address: string;
  commodity: string;
  commodity_amount: string;
  color_background: string;
  click_id: string;
  network: string;
  commodities: Array<{
    commodity: string;
    network: string;
  }>;
};

function buildWertParams(params: WertParams) {
  return (Object.keys(params) as Array<keyof WertParams>)
    .map(
      (key) =>
        `${key}=${encodeURIComponent(
          typeof params[key] === 'object'
            ? JSON.stringify(params[key])
            : String(params[key])
        )}`
    )
    .join('&');
}

export interface WertWidgetParams {
  partner_id: string;
  click_id: string;
  commodity_amount: string;
  commodity: string;
  network: string;
  address: string;
}

export function WertWidget({
  params,
  onEvent,
  ...rest
}: {
  params: WertWidgetParams & {
    email?: string;
    sumsub_token?: string;
  };
  onEvent?: (e: WertWidgetEvent) => void;
}) {
  const mountElement = useRef<HTMLDivElement | null>(null);
  const wertIframe = useRef<HTMLIFrameElement | null>(null);
  const theme = useTheme();

  useLayoutEffect(() => {
    const listener = (e: MessageEvent) => {
      if (!isWertEvent(wertIframe.current!.contentWindow!, e)) {
        return;
      }

      onEvent?.(e.data);
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [onEvent]);

  const commodityParams = {
    commodities: [{ commodity: params.commodity, network: params.network }],
    commodity: params.commodity,
  };

  return (
    <div
      ref={mountElement}
      css={{
        width: '100%',
        maxWidth: '520px',
        minHeight: '580px',
        display: 'flex',
      }}
      {...rest}
    >
      <iframe
        ref={wertIframe}
        css={{
          width: '100%',
          border: 0,
          outline: 0,
        }}
        src={`${WERT_URL}/${params.partner_id}/widget?${buildWertParams({
          address: params.address || '',
          commodity_amount: params.commodity_amount || '',
          network: params.network,
          color_background: theme.colors.background,
          click_id: params.click_id || '',
          ...commodityParams,
        })}`}
        allow="camera *; microphone *"
        sandbox="allow-scripts allow-forms allow-popups allow-same-origin"
      ></iframe>
    </div>
  );
}
