import { MerchantSettingsForm } from './merchant-settings-form';
import styled from '@emotion/styled';
import { ApiKeys } from './api-keys';
import { ApiKeysModal } from './api-keys-modal';
import { useModalControl } from '~/shared/hooks/useModalControl';
import { useState } from 'react';
import { Spinner } from '~/shared/ui/kit';
import { useMerchantSettings } from '../../data-hooks';
import { toast } from '~/shared/toasts';
import { useTranslation } from 'react-i18next';

const Container = styled.div({
  maxWidth: 660,
  width: '100%',
  margin: '36px auto 36px auto',
});
export function MerchantSettingsView() {
  const { t } = useTranslation('merchant');

  const { isActive, open, close } = useModalControl();
  const [keys, setKeys] = useState<{
    apiKey: string;
    secretKey: string;
  }>({ apiKey: '', secretKey: '' });

  const settings = useMerchantSettings();
  const isReady = settings.data && settings.data.data;

  if (!isReady) {
    return (
      <Container
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '600px',
        }}
      >
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <ApiKeysModal
        isOpen={isActive('api-keys')}
        secretKey={keys.secretKey}
        onRequestClose={() => close()}
      />
      <ApiKeys
        apiKey={settings.data?.data.client_id}
        secretKey={settings.data?.data.client_secret}
        css={{ marginBottom: 20 }}
      ></ApiKeys>
      <MerchantSettingsForm
        address={settings.data?.data.receiving_address || ''}
        webhook={settings.data?.data.webhook_url || ''}
        onMerchantUpdated={(response) => {
          settings.mutate();

          if (
            response.data.client_secret &&
            response.data.client_id &&
            response.data.client_secret.indexOf('*') === -1
          ) {
            setKeys({
              apiKey: response.data.client_id,
              secretKey: response.data.client_secret,
            });
            open('api-keys');
          } else {
            toast.success(t('dashboard.settingsUpdated'));
          }
        }}
      />
    </Container>
  );
}
