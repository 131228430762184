import { SublineText, useMediaQuery } from '~/shared/ui/kit';
import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Transaction } from '../..';
import { Icon, TxInIcon, TxOutIcon } from '~/shared/ui/kit';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { FiatBalance, useFiatValue } from '~/shared/fiat-converter';
import { formatValue } from '~/entities/tokens/utils';
import { MATIC_TOKEN } from '~/entities/tokens/tokens';
import { useTranslation } from 'react-i18next';
import { ViewInExplorerMini } from '~/entities/explorer';
import * as TransactionItemStyles from './styles';

type TransactionItemProps = PropsWithChildren<{
  tx: Transaction;
  account: string;
  fiatValue?: FiatBalance;
}>;

const titles = {
  sent: 'transactions.sent',
  received: 'transactions.received',
  self: ' transactions.self',
};

function _TransactionItem({ account, tx, ...rest }: TransactionItemProps) {
  const { t } = useTranslation('common');

  const isMd = useMediaQuery('md');
  const isSent = tx.from.toLowerCase() === account.toLowerCase();
  const isSelf = tx.from.toLowerCase() === tx.to.toLowerCase();
  const isReceived = tx.to.toLowerCase() === account.toLowerCase();

  const trimAddressIfNeeded = (address: string) => {
    return isMd ? address : trimMiddle(address, 12);
  };

  const title = isSelf
    ? t(titles.self)
    : isSent
    ? t(titles.sent)
    : t(titles.received);

  const subline = isSent
    ? t('glossary.to') + ': ' + trimAddressIfNeeded(tx.to)
    : isReceived
    ? t('glossary.from') + ': ' + trimAddressIfNeeded(tx.from)
    : account;

  const fiatValue = useFiatValue({
    value: tx.value,
    symbol: tx.asset,
  });

  return (
    <TransactionItemStyles.Container
      isOutlined={false}
      title={
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
          <ViewInExplorerMini
            txHash={tx.hash}
            size={14}
            css={{
              marginLeft: 4,
            }}
          />
        </div>
      }
      subline={<SublineText css={{ fontSize: 12 }}>{subline}</SublineText>}
      image={
        <TransactionItemStyles.IconContainer isReceived={isReceived}>
          {isReceived ? (
            <Icon>
              <TxInIcon />
            </Icon>
          ) : isSent ? (
            <Icon>
              <TxOutIcon />
            </Icon>
          ) : (
            <span>?</span>
          )}
        </TransactionItemStyles.IconContainer>
      }
      controls={
        <>
          <TransactionItemStyles.TxValue isReceived={isReceived}>
            {isReceived ? '+' : '-'}
            {formatValue(
              tx.value,
              tx.rawContract.decimal
                ? Number.parseInt(tx.rawContract.decimal, 16)
                : MATIC_TOKEN.decimals
            )}{' '}
            {tx.asset}
          </TransactionItemStyles.TxValue>

          <TransactionItemStyles.TxValueFiat>
            {fiatValue.formatted}
          </TransactionItemStyles.TxValueFiat>
        </>
      }
      {...rest}
    ></TransactionItemStyles.Container>
  );
}

export const TokenTransactionItem = styled(_TransactionItem)((props) => ({}));
