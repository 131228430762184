import React from 'react';
import {
  Action,
  ActionGrid,
  Icon,
  LinkOutIcon,
  ReceiveIcon,
  SendIcon,
} from '~/shared/ui/kit';
import { useTranslation } from 'react-i18next';
import { ENV_ONRAMPER_KEY } from '~/app/config';

type WalletActionsProps = React.PropsWithChildren<{
  onAction: (actionName: string) => void;
}>;
export function WalletActions({ onAction, ...rest }: WalletActionsProps) {
  const { t } = useTranslation('common');

  return (
    <ActionGrid {...rest}>
      <Action
        onClick={() => onAction('send')}
        icon={
          <Icon>
            <SendIcon />
          </Icon>
        }
      >
        {t('glossary.send')}
      </Action>

      <Action
        onClick={() => onAction('receive')}
        icon={
          <Icon>
            <ReceiveIcon />
          </Icon>
        }
      >
        {t('glossary.receive')}
      </Action>
      <Action
        onClick={() => onAction('onramp')}
        icon={
          <Icon>
            <LinkOutIcon />
          </Icon>
        }
      >
        {t('glossary.buy')}
      </Action>
      {/* {ENV_ONRAMPER_KEY ? (
        <Action
          onClick={() => onAction('offramp')}
          icon={
            <Icon>
              <LinkOutIcon />
            </Icon>
          }
        >
          Sell
        </Action>
      ) : null} */}
    </ActionGrid>
  );
}
