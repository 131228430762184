import { Connector, createConfig } from 'wagmi';
import {
  ENV_RPC_URL,
  ENV_NETWORK,
  ENV_ALCHEMY_API_KEY,
  ENV_WEB3_CLIENT_ID,
  ENV_WEB3_VERIFIER_NAME,
} from '~/app/config';

import { polygon, Chain } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { configureChains } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { CHAIN_NAMESPACES, getEvmChainConfig } from '@web3auth/base';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { Web3AuthConnector } from '@web3auth/web3auth-wagmi-connector';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { whiteLabelConfig } from '~/white-label';


const polygonAmoy : Chain = {
    id: 80002,
    name: "Polygon Amoy",
    network: "maticamo",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-amoy.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-amoy.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-amoy.infura.io/v3"],
            webSocket: ["wss://polygon-amoy.infura.io/ws/v3"],
        },
        default: {
            http: ["https://rpc-amoy.polygon.technology"],
        },
        public: {
            http: ["https://rpc-amoy.polygon.technology"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: 'OK LINK',
            url: 'https://www.oklink.com/amoy',
        },
         default: {
            name: 'OK LINK',
            url: 'https://www.oklink.com/amoy',
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 3127388,
        },
    },
    testnet: true,
};



const { chains, publicClient } = configureChains(
  ENV_NETWORK === 'testnet' ? [polygonAmoy] : [polygon],
  [alchemyProvider({ apiKey: ENV_ALCHEMY_API_KEY })]
);

const ap = alchemyProvider({ apiKey: ENV_ALCHEMY_API_KEY });


const selectedChain = chains[0];
const chainConfig = getEvmChainConfig(selectedChain.id);
if (chainConfig === null) {
  throw new Error('Chain config not found');
}


//  const transformedChainConfig: CustomChainConfig = {
//    ...chainConfig,
//    blockExplorer: chainConfig.blockExplorerUrl ? '',
//  };

const web3authNetwork = ENV_NETWORK === 'testnet' ? 'testnet' : 'cyan';

const openloginAdapter = new OpenloginAdapter({
  privateKeyProvider: new EthereumPrivateKeyProvider({
    config: {
      chainConfig: chainConfig,
    },
  }),
  adapterSettings: {
    // using "popup" has issues with browsers blocking popups
    uxMode: 'redirect',
//  whiteLabel: {
//    name: whiteLabelConfig.name,
//    logoLight: 'https://web3auth.io/images/w3a-L-Favicon-1.svg',
//    logoDark: 'https://web3auth.io/images/w3a-D-Favicon-1.svg',
//    defaultLanguage: 'en',
//    dark: false, // whether to enable dark mode. defaultValue: false
//  },
    loginConfig: {
      jwt: {
        verifier: ENV_WEB3_VERIFIER_NAME,
        typeOfLogin: 'jwt',
        clientId: ENV_WEB3_CLIENT_ID,
      },
    },
  },
});

export const web3AuthInstance = new Web3AuthNoModal({
  clientId: ENV_WEB3_CLIENT_ID,
  web3AuthNetwork: web3authNetwork,
  chainConfig: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x' + selectedChain.id.toString(16),
    rpcTarget: ENV_RPC_URL,
    displayName: selectedChain.name,
    tickerName: selectedChain.nativeCurrency.name,
    ticker: selectedChain.nativeCurrency.symbol,
    blockExplorer: selectedChain.blockExplorers?.default.url,
  },
}).configureAdapter(openloginAdapter);

export const web3AuthConnector = new Web3AuthConnector({
  chains,
  options: {
    web3AuthInstance,
  },
});

export const wagmiClient = createConfig({
  autoConnect: true,
  connectors: [
    web3AuthConnector as unknown as Connector,
    new InjectedConnector({ chains }),
  ],
  publicClient
});
