import styled from '@emotion/styled';
import { MerchantLoginForm } from './merchant-login-form';
import { CardBase, Title } from '~/shared/ui/kit';
import { MerchantLoginViewContainer } from '../styles';
import { useTranslation } from 'react-i18next';

// TODO: add mobile padding
export const ViewContainer = styled(CardBase);
export function MerchantLoginFormView() {
  const { t } = useTranslation('merchant');

  return (
    <MerchantLoginViewContainer>
      <Title h={2} as={'h2'} css={{ textAlign: 'center', marginBottom: 32 }}>
        {t('login.title')}
      </Title>
      <MerchantLoginForm />
    </MerchantLoginViewContainer>
  );
}
