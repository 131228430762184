import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { CardBase, LinkButton, media } from '~/shared/ui/kit';
import { OnrampWidget } from './onramp-widget';
import { useAccount } from 'wagmi';
import { getConfig } from '~/features/top-up';
import useSWR from 'swr';
import { CopyAddress } from '~/features/copy-address';
import { HexString } from '~/shared/types';

export const Container = styled(CardBase)(({ theme }) => ({
  width: '100%',
  maxWidth: '520px',
  margin: '50px auto 20px auto',
  ...media('md', {
    padding: '16px 56px',
  }),
  ...media(
    {
      max: 'md',
    },
    {
      padding: '16px 0',
    }
  ),
}));

export function OnrampPage() {
  const { address } = useAccount();
  const config = useSWR('onramp-config', () => {
    return getConfig();
  });

  return (
    <div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 32,
        }}
      >
        {address && config.data ? (
          <div
            css={{
              margin: 'auto',
              width: '100%',
              height: 570,
            }}
          >
            <OnrampWidget address={address} onrampConfig={config.data.data} />
          </div>
        ) : null}
      </div>

      <div css={{ marginTop: 24, textAlign: 'center' }}>
        <Link to="/">
          <LinkButton>Go back to Wallet</LinkButton>
        </Link>
      </div>
    </div>
  );
}
