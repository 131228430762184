import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { OfframpWidget } from '~/features/off-ramp/ui/offramp-widget';
import { CardBase, LinkButton, media } from '~/shared/ui/kit';

export const Container = styled(CardBase)(({ theme }) => ({
  width: '100%',
  maxWidth: '520px',
  margin: '50px auto 20px auto',
  ...media('md', {
    padding: '16px 56px',
  }),
  ...media(
    {
      max: 'md',
    },
    {
      padding: '16px 0',
    }
  ),
}));

export function OfframpPage() {
  return (
    <div>
      <Container>
        <OfframpWidget
          css={{
            margin: 'auto',
          }}
        />
        <div css={{ marginTop: 24, textAlign: 'center' }}>
          <Link to="/">
            <LinkButton>Go back to Wallet</LinkButton>
          </Link>
        </div>
      </Container>
    </div>
  );
}
