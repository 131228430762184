import { Link } from 'react-router-dom';
import { PayViaQuv } from './pay-via-quv';
import { PayViaQuvGradient } from './pay-via-quv-gradient';
import payWithQuvNoiseSvg from './pay-with-quv-noise.svg';

export function PayWithQuvWhiteButton({ link }: { link: string }) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 168,
        height: 66,
        padding: '21px 16px 21px 19px',
        borderRadius: 16,
        backgroundColor: '#FFFFFF',
      }}
    >
      <PayViaQuvGradient />
    </Link>
  );
}

export function PayWithQuvButton({ link }: { link: string }) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 168,
        height: 66,
        padding: '21px 16px 21px 19px',
        borderRadius: 16,
        backgroundColor: '#19BCD2',
      }}
    >
      <PayViaQuv />
    </Link>
  );
}

export function PayWithQuvBlackButton({ link }: { link: string }) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 168,
        height: 66,
        padding: '21px 16px 21px 19px',
        backgroundColor: '#000000',
        borderRadius: 16,
      }}
    >
      <PayViaQuv />
    </Link>
  );
}

export function PayWithQuvBrandedButton({ link }: { link: string }) {
  return (
    <Link
      to={link}
      target="_blank"
      style={{
        boxSizing: 'border-box',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 168,
        height: 66,
        padding: '21px 16px 21px 19px',
        background: `linear-gradient(127.53deg, #8329DD 0.51%, #3707BF 58.55%, #14008E 76.95%, #000852 100%),
linear-gradient(0deg, rgba(223, 220, 227, 0.4), rgba(223, 220, 227, 0.4))`,
        borderRadius: 16,
      }}
    >
      <PayViaQuv />
    </Link>
  );
}
