import {
  ENV_WHITELABEL_PRIVACY_POLICY_LINK,
  ENV_WHITELABEL_NAME,
  ENV_WHITELABEL_TOS_LINK,
  ENV_WHITELABEL_TOS_EMAIL_LINK,
  ENV_WHITELABEL_LOGO_PACK,
} from '~/app/config';

interface WhiteLabelConfiguration {
  name: string;
  privacyPolicyLink: string;
  tosLink: string;
  tosEmailLogin: string;
}

export const whiteLabelConfig = {
  name: ENV_WHITELABEL_NAME,
  privacyPolicyLink: ENV_WHITELABEL_PRIVACY_POLICY_LINK,
  tosLink: ENV_WHITELABEL_TOS_LINK,
  tosEmailLogin: ENV_WHITELABEL_TOS_EMAIL_LINK,
} as WhiteLabelConfiguration;
