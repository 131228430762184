import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { AppLogo } from '~/white-label';

const idleKeyframes = keyframes`
  from {
    transform: scale(0.84);
    opacity: 0.84;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;
const LoaderLogo = styled(AppLogo)((props) => ({
  height: 100,
  animation: `${idleKeyframes} 1s ease-in-out infinite alternate`,
  maxWidth: '25vw',
}));

const Container = styled.div((props) => ({
  background: props.theme.colors.neutral[10],
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export function CurtainLoader({ text }: { text: string }) {
  return (
    <Container>
      <div
        css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <LoaderLogo />
        <p css={{ fontSize: 18, fontWeight: 500, marginTop: 20 }}>{text}</p>
      </div>
    </Container>
  );
}
