import { Navigate, Outlet } from 'react-router-dom';
import { useMerchantContext } from '~/entities/merchant';
import { ChangeLanguage } from '~/features/i18n/change-language';
import { TransparentHeader } from '~/widgets/header';

export function MerchantLogin() {
  const merchant = useMerchantContext();

  if (merchant.isAuthenticated) {
    return <Navigate to={'/merchant/dashboard'}></Navigate>;
  }

  return (
    <div>
      <TransparentHeader />
      <Outlet></Outlet>
    </div>
  );
}
