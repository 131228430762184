import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { Token } from '.';
import { endpoint, ResponseType } from '~/shared/api';
import { useMemo } from 'react';
import { useToken } from 'wagmi';
import { MATIC_DATA } from './shared/const';
import { FetchTokenResult } from 'wagmi/dist/actions';
import { useActiveChain } from '~/shared/ethereum/chain-provider';

const accountTokensUrl = (address: string) =>
  endpoint(`/v1/account/${address}/tokens`);

export function useAccountTokens({ address }: { address: string | undefined }) {
  const data = useSWR<ResponseType<Token[]>>(
    () => (address ? 'tokens-' + address : null),
    () => {
      invariant(address, 'address is required');
      return fetch(accountTokensUrl(address)).then((res) => res.json());
    }
  );

  return data;
}

export type TokenData = {
  contract: `0x${string}` | null;
  eur: number;
  symbol: string;
  usd: number;
};

const tokenList = endpoint('/v1/exchange_rates');

export function useTokenData({ ticker }: { ticker: string | undefined }): {
  isLoading: boolean;
  isValidating: boolean;
  isError: boolean;
  error: any | undefined;
  data: ResponseType<FetchTokenResult | undefined> | undefined;
} {
  const activeChain = useActiveChain();
  const tokens = useSWR<ResponseType<TokenData[]>>(
    () => 'token-list',
    () => {
      return fetch(tokenList).then((res) => res.json());
    }
  );

  const tokenExchangePrice = useMemo(() => {
    return tokens.data?.data.find((t) => t.symbol === ticker);
  }, [tokens.data, ticker]);

  const tokenContractMetadata = useToken({
    address: tokenExchangePrice?.contract || undefined,
    formatUnits: 'ether',
    chainId: activeChain,
  });

  //const data = ticker === 'MATIC' ? MATIC_DATA : tokenContractMetadata.data;

  // console.debug('useTokenData: final result', data, {
  //   isLoading: tokens.isLoading || tokenContractMetadata.isLoading,
  //   isError: !!tokens.error || tokenContractMetadata.isError,
  //   error: tokens.error || tokenContractMetadata.error,
  // });
  return {
    isLoading: tokens.isLoading || tokenContractMetadata.isLoading,
    isValidating: tokens.isLoading || tokenContractMetadata.isLoading,
    isError: !!tokens.error || tokenContractMetadata.isError,
    error: tokens.error || tokenContractMetadata.error,
    // just to comply with the backend interface
    data: {
      data: ticker === 'MATIC' ? MATIC_DATA : tokenContractMetadata.data,
    },
  };
}
