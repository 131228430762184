import React from 'react';
import { CardListItem, SublineText } from '~/shared/ui/kit';
import styled from '@emotion/styled';
import { Token } from '..';
import { TokenFiatBalance } from '../shared/styles';
import {
  formatTokenBalance,
  formatFiatValue,
  normalizeTokenBalance,
} from '../utils';
import { TokenIcon } from './TokenIcon';
import { useFiatValue } from '~/shared/fiat-converter';

const TokenListItemContainer = styled(CardListItem)((props) => ({
  padding: 0,
  border: 0,
}));

type TokenListItemProps = {
  token: Token;
  fiatExchangeRate?: number;
  isClickable?: boolean;
  isOutlined?: boolean;
};

export function TokenListItem({ token }: TokenListItemProps) {
  const fiatValue = useFiatValue({
    value: normalizeTokenBalance(token),
    symbol: token.symbol,
  });

  return (
    <TokenListItemContainer
      title={token.name}
      // TODO: better format for fiat exchange rate
      subline={
        <SublineText>
          ${fiatValue.price ? formatFiatValue(fiatValue.price) : ' –'}
        </SublineText>
      }
      image={<TokenIcon token={token} />}
      controls={
        <>
          <div>
            {formatTokenBalance(token)} {token.symbol}
          </div>
          <TokenFiatBalance>
            {fiatValue.symbol}
            {fiatValue.price === null
              ? ' –'
              : formatFiatValue(fiatValue.fiatValue || 0)}
          </TokenFiatBalance>
        </>
      }
    >
      <div>TokenListItem</div>
    </TokenListItemContainer>
  );
}
