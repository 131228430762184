import {
  Icon,
  WalletIcon,
  LogoutIcon,
  ActionMenu,
  ActionMenuItem,
  useMediaQuery,
  media,
} from '~/shared/ui/kit';
import { trimMiddle } from '~/shared/utils/string-helpers';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useAccount } from 'wagmi';
import { useLogout } from '~/features/logout';
import { useUserInfo } from '~/shared/ethereum/web3auth';
import { useTranslation } from 'react-i18next';

const AccountAddress = styled.div((props) => ({
  overflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 500,
  pointerEvents: 'none',
}));

function shortenEmail(email: string) {
  const [name, domain] = email.split('@');
  return `${trimMiddle(name, 16)}@${domain}`;
}

function extraShorten(email: string) {
  const [name, domain] = email.split('@');
  return `${trimMiddle(name, 16)}`;
}

export function AccountMenu() {
  const { t } = useTranslation('common');
  const isMd = useMediaQuery({
    min: 'md',
  });

  const isXs = useMediaQuery({
    min: 'xs',
    max: 'sm',
  });

  const logout = useLogout();
  const account = useAccount();
  const theme = useTheme();
  const userInfo = useUserInfo();

  return (
    <ActionMenu
      css={{
        ...media(
          {
            min: 'md',
          },
          {
            minWidth: 180,
          }
        ),
      }}
      actions={
        <>
          <ActionMenuItem
            color={theme.colors.critical[100]}
            icon={<LogoutIcon />}
            onClick={logout}
          >
            {t('glossary.logOut')}
          </ActionMenuItem>
        </>
      }
    >
      <Icon css={{ marginRight: 8 }}>
        <WalletIcon />
      </Icon>
      {isXs ? null : (
        <AccountAddress>
          {userInfo.data?.email
            ? isMd
              ? shortenEmail(userInfo.data?.email)
              : extraShorten(userInfo.data?.email)
            : trimMiddle(account.address || '', 10)}
        </AccountAddress>
      )}
    </ActionMenu>
  );
}
