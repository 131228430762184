import styled from '@emotion/styled';
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

const Input = styled.input((props) => ({
  color: props.theme.colors.neutral[80],
  fontSize: 16,
  fontWeight: 500,
}));

const Label = styled.label<{ isShrinked: boolean }>((props) => ({
  fontSize: 12,
  color: props.theme.colors.neutral[50],
  transform: props.isShrinked
    ? 'translateY(0)'
    : 'translateY(22px) scale(1.33333)',
  transformOrigin: 'center left',
  pointerEvents: 'none',
  position: !props.isShrinked ? 'absolute' : 'static',
  top: 0,
  lineHeight: 1,
}));

type InputTextProps = PropsWithChildren<{
  label?: string;
}> &
  React.InputHTMLAttributes<HTMLInputElement>;

export const InputText = forwardRef(
  ({ ...props }: InputTextProps, ref: ForwardedRef<HTMLInputElement>) => {
    const isEmptyInput = props.value === '' || props.value === undefined;
    return (
      <>
        {props.label ? (
          <Label isShrinked={!isEmptyInput}>{props.label}</Label>
        ) : null}
        <Input {...props} ref={ref}></Input>
      </>
    );
  }
);
