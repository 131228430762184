import { useStepProgress } from '~/shared/hooks/useStepProgress';
import {
  Button,
  Buttons,
  InputBase,
  InputButton,
  InputText,
  ModalHeading,
  ModalSubHeading,
} from '~/shared/ui/kit';
import { SendFormStep, useSendForm } from './form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { FormSelectedAsset } from './ui/form-selected-asset';
import { formatFiatValue, normalizeTokenAmount } from '~/entities/tokens/utils';
import { isMatic, useAccountTokens } from '~/entities/tokens';
import { useAccount, useBalance, useFeeData, useTransaction } from 'wagmi';
import { useFiatValue } from '~/shared/fiat-converter';
import { useActiveChain } from '~/shared/ethereum/chain-provider';
import { HexString } from '~/shared/types';
import { BigNumber, FixedNumber } from 'ethers';
import BN from 'bignumber.js';
import { MATIC_DATA } from '~/entities/tokens/shared/const';
import { toEthers, toWei } from '~/shared/ethereum/units';
import { useTranslation } from 'react-i18next';
import { toHexString } from '~/shared/utils/number-format-helpers';
import { isValidAddress } from '~/shared/validation/is-valid-address';

interface SendSchema {
  receiverAddress: string | undefined;
  amount: string | undefined;
}

function isUndefined<T>(value: T | undefined): value is undefined {
  return typeof value === 'undefined';
}

const schema: yup.ObjectSchema<
  SendSchema,
  {
    decimals: number;
    balance: BigNumber;
  }
> = yup.object({
  receiverAddress: yup.string().required().test(isValidAddress()),
  amount: yup
    .mixed((amount): amount is string => {
      try {
        FixedNumber.from(amount);
        return true;
      } catch (error) {
        return false;
      }
    })
    .typeError('Amount should be a number')
    .required("Amount can't be empty")
    .test('is-valid-amount', 'Amount is too small', (amount, _context) => {
      const context = _context as unknown as yup.TestContext<{
        decimals: number;
      }>;
      const validationContext = context.options.context;

      if (!amount) {
        return false;
      }

      if (!validationContext || !validationContext.decimals) {
        return true;
      }
      // minimum amount is 1 / 10^decimals
      const minAmount = new BN(10).pow(-1 * validationContext.decimals);
      const amountBN = new BN(amount);
      return amountBN.isGreaterThan(minAmount);
    })
    .test(
      'is-not-bigger-than-balance',

      (amount, _context) => {
        const context = _context as unknown as yup.TestContext<{
          decimals: number;
          balance: BigNumber;
        }>;

        const { balance, decimals } = context.options.context || {};

        if (
          isUndefined(balance) ||
          isUndefined(decimals) ||
          isUndefined(amount)
        ) {
          return true;
        }

        const stringAmount = amount.toString();

        if (toWei(stringAmount.toString(), decimals).lte(balance)) {
          return true;
        }

        return _context.createError({
          message: `Maximum amount is ${toEthers(balance, decimals)}`,
          path: _context.path,
        });
      }
    ),
});

const useMaxSpendableBalance = (address?: HexString) => {
  // safe limit to avoid miscalculations
  // TODO: probably should remove that in future

  const SAFE_ZONE_OVERHEAD = 2;
  const ETH_TRANSFER_GAS_LIMIT = 21000;

  const activeChain = useActiveChain();

  const balance = useBalance({
    address,
    chainId: activeChain,
    enabled: !!address,
  });

  const feeData = useFeeData({
    chainId: activeChain,
  });

  if (!balance.data || !feeData.data) {
    return { value: BigInt(0), gas: BigInt(0), loading: true, ready: false };
  }

  const gas =
    BigInt(ETH_TRANSFER_GAS_LIMIT) *
    BigInt(feeData.data?.gasPrice || 0) *
    BigInt(SAFE_ZONE_OVERHEAD);
  const value = balance.data?.value - gas;

  return { value, gas, loading: false, ready: true };
};

const resolver = yupResolver(schema);

export function AmountStep() {
  const { t } = useTranslation('common');

  const form = useSendForm();
  const steps = useStepProgress<SendFormStep>();
  const account = useAccount();
  const balances = useAccountTokens({
    address: account.address,
  });

  const token = balances.data?.data.find(
    (t) => t.symbol === form.token?.symbol
  );
  // balance of the selected token in fractional units
  const tokenBalance = BigInt(token?.balance || 0);

  const wei_spendableBalance = useMaxSpendableBalance(account.address);

  const maticSpendableBalance = wei_spendableBalance.value;
  const tokenSpendableBalance = isMatic(token?.contract)
    ? maticSpendableBalance
    : tokenBalance;

  const ethers_tokenSpendableBalance = toEthers(
    tokenSpendableBalance.toString(),
    token?.decimals || 0
  );

  const isOutOfGas =
    wei_spendableBalance.ready && wei_spendableBalance.value <= 0;

  const ethers_gasCost = normalizeTokenAmount(
    toHexString(wei_spendableBalance.gas),
    MATIC_DATA.decimals
  );

  const isOutOfGasMessage = t('sendTokens.errorOutOfGas', {
    gas: ethers_gasCost,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    context: {
      decimals: form.token?.decimals,
      balance: tokenSpendableBalance,
    },
    defaultValues: {
      receiverAddress: form.receiverAddress,
      amount: form.amount,
    },
    criteriaMode: 'firstError',
    resolver: resolver,
  });

  const value = useWatch({ name: 'amount', control: control });
  const fiatValue = useFiatValue({
    value: Number(value) || 0,
    symbol: form.token?.symbol || '',
  });

  const amountError = isOutOfGas
    ? isOutOfGasMessage
    : errors.amount?.message?.toString();

  return (
    <div>
      <ModalHeading css={{ marginBottom: 4 }}>
        {t('glossary.send')}
      </ModalHeading>
      <ModalSubHeading
        css={{
          marginBottom: 16,
        }}
      >
        {form.token ? <FormSelectedAsset token={form.token} /> : null}
      </ModalSubHeading>
      <form
        onSubmit={handleSubmit((values) => {
          form.setValues({
            receiverAddress: values.receiverAddress,
            amount: values.amount,
          });
          steps.goForward();
        })}
      >
        <Controller
          control={control}
          name="receiverAddress"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 16 }}
              errorMessage={errors.receiverAddress?.message?.toString()}
            >
              <InputText
                label={t('glossary.address') || ''}
                {...field}
              ></InputText>
            </InputBase>
          )}
        ></Controller>

        <Controller
          control={control}
          name="amount"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 16 }}
              errorMessage={amountError}
              extraMessage={
                amountError ? undefined : (
                  <span>
                    {fiatValue.value
                      ? `≈ ${formatFiatValue(fiatValue.fiatValue)} USD`
                      : '≈ 0 USD'}
                  </span>
                )
              }
              controls={
                <InputButton
                  type="button"
                  onClick={() => {
                    field.onChange(ethers_tokenSpendableBalance.toString());
                  }}
                >
                  {t('glossary.max')}
                </InputButton>
              }
            >
              <InputText
                label={t('glossary.amount') || ''}
                {...field}
                autoComplete="off"
              ></InputText>
            </InputBase>
          )}
        ></Controller>

        <Buttons>
          <Button
            type="button"
            semanticType="secondary"
            onClick={() => {
              steps.goBack();
            }}
          >
            {t('glossary.back')}
          </Button>
          <Button semanticType="primary" type="submit" disabled={isOutOfGas}>
            {t('glossary.send')}
          </Button>
        </Buttons>
      </form>
    </div>
  );
}
