import tinycolor from 'tinycolor2';

// Generates new CSS gradient based on source gradient and background color
// returns CSS gradient string
export function mixGradientOnBackground(gradient: string, background: string): string {
  const newGradient = gradient.replace(
    /rgba\((\d+), (\d+), (\d+), (\d+\.?\d*)\)/g,
    (match, r, g, b, a) => {
      const color = tinycolor({ r, g, b });
      const newColor = tinycolor.mix(background, color, 100 * a);
      return newColor.toRgbString();
    }
  );

  return newGradient;
}