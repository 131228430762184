import { MerchantConfig } from '~/features/top-up';

export function MerchantLogo({
  merchant,
  ...rest
}: {
  merchant: MerchantConfig;
}) {
  return (
    <div
      css={{
        height: 84,
        display: 'inline-block',
      }}
      {...rest}
    >
      <img
        src={merchant.address_logo || ''}
        alt={merchant.address_name || ''}
        css={{
          height: '100%',
          width: 'auto',
        }}
      />
    </div>
  );
}
