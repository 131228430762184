export const ChevDownIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5328 12.7946L5.12206 8.3839C4.79663 8.05846 4.79663 7.53082 5.12206 7.20538C5.4475 6.87995 5.97514 6.87995 6.30057 7.20538L10.1221 11.0269L13.9435 7.20538C14.269 6.87995 14.7966 6.87995 15.1221 7.20538C15.4475 7.53082 15.4475 8.05846 15.1221 8.38389L10.7113 12.7946C10.3859 13.1201 9.85824 13.1201 9.5328 12.7946Z"
      fill="#3E3A42"
    />
  </svg>
);
