import { trimMiddle } from '~/shared/utils/string-helpers';

export const trimAddressForSmall = (address: string, isMd: boolean) => {
  return isMd ? address : trimMiddle(address, 12);
};

export const hasNoValue = (value: string | undefined | null | number) => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    value === '0' ||
    value === 0
  );
};
