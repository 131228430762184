import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { formatTokenBalance } from '~/entities/tokens';
import { UserToken } from '~/entities/tokens/tokens';
import { BodyPrimaryText, Title } from '~/shared/ui/kit';

const UserBalance = styled.div(({ theme }) => ({
  padding: '24px 40px 24px 24px',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.neutral[20],
}));

export function UserPaymentBalance({ token }: { token: UserToken }) {
  const theme = useTheme();

  return (
    <UserBalance>
      <div>Your balance</div>
      <span>
        <Title h={4} as={'span'}>
          {token ? formatTokenBalance(token) : '–'}
        </Title>{' '}
        <BodyPrimaryText
          css={{
            color: theme.colors.neutral[60],
          }}
        >
          {token?.symbol || ''}
        </BodyPrimaryText>
      </span>
    </UserBalance>
  );
}
