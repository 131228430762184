import { useWeb3AuthAutoReconnect } from '../../hooks/useWeb3AuthAutoReconnect';

type ReconnectingMiddlewareProps = {
  children: React.ReactNode;
  renderDuringReconnect?: React.ReactNode;
};

const EMPTY_RECONNECT = {
  willReconnect: false,
  doingReconnect: false,
};
export function ReconnectingMiddleware({
  children,
  renderDuringReconnect,
}: ReconnectingMiddlewareProps): React.ReactElement {
  const { willReconnect, doingReconnect } = useWeb3AuthAutoReconnect();

  if (willReconnect || doingReconnect) {
    return <div>{renderDuringReconnect}</div>;
  }

  return <>{children}</>;
}
