import invariant from 'tiny-invariant';
import { useAccount, useWaitForTransaction } from 'wagmi';
import {
  Transaction,
  useSingleAssetTransfer,
  useSingleNftTransaction,
  useSingleTransaction,
  useTransactionReceipt,
} from '~/entities/transactions';
import { useActiveChain } from '~/shared/ethereum/chain-provider';
import { TransactionReceipt } from 'viem';
import { AssetTransfersCategory, TransferredNft } from 'alchemy-sdk';

export function TransactionLoader({
  txHash,
  renderTransaction,
  renderLoading,
}: {
  txHash: string;
  renderTransaction: (
    data:
      | {
          transaction: undefined;
          nftTransaction: TransferredNft;
          receipt: TransactionReceipt;
        }
      | {
          transaction: Transaction;
          nftTransaction: undefined;
          receipt: TransactionReceipt;
        }
  ) => React.ReactElement;
  renderLoading: (status: 'waiting' | 'fetching') => React.ReactElement;
}) {
  const account = useAccount();

  const activeChain = useActiveChain();
  const txWait = useWaitForTransaction({
    chainId: activeChain,
    hash: txHash as `0x${string}`,
    confirmations: 1,
  });

  // console.debug('Waiting For Transaction', txWait.isSuccess);

  // fetch raw ethereum receipt, to get from/to/block info
  const receipt = useTransactionReceipt(
    { hash: txHash },
    {
      refreshInterval(latestData) {
        if (!latestData) {
          return 1000;
        }

        return 0;
      },
    }
  );

  // console.debug('Fetching transaction receipt', receipt.data);

  // use that data to fetch alchemy transfer object, containing transfer category info
  const assetTransfer = useSingleAssetTransfer(
    {
      receipt: receipt.data,
      address: account.address,
    },
    {
      refreshInterval(latestData) {
        if (!latestData) {
          return 1000;
        }

        return 0;
      },
    }
  );

  // console.debug('Fetching asset transfer', assetTransfer.data);

  // Only use one of the requests below, depending on the transfer category
  const isNftTransfer =
    assetTransfer.data &&
    assetTransfer.data.category === AssetTransfersCategory.ERC721;
  const isTokenTransfer =
    assetTransfer.data &&
    assetTransfer.data.category === AssetTransfersCategory.ERC20;

  const transactionRequest = useSingleTransaction(
    {
      hash:
        isTokenTransfer && txWait.isSuccess
          ? (txHash as `0x${string}`)
          : undefined,
    },
    {
      refreshInterval(latestData) {
        if (!latestData) {
          return 1000;
        }

        return 0;
      },
    }
  );

  const nftTransactionRequest = useSingleNftTransaction(
    {
      address: account.address,
      receipt: isNftTransfer ? receipt.data : undefined,
    },
    {
      refreshInterval(latestData) {
        if (!latestData) {
          return 1000;
        }

        return 0;
      },
    }
  );

  // console.debug(
  //   'Fetching transaction object',
  //   transactionRequest.data || nftTransactionRequest.data
  // );

  const transaction = transactionRequest.data || nftTransactionRequest.data;
  const isReady = !!transaction;

  // TODO: better loading states
  if (!txWait.isSuccess || !txWait.data) {
    return renderLoading('waiting');
  } else if (!isReady) {
    return renderLoading('fetching');
  }

  invariant(transaction, 'transaction data is not found');

  if (transactionRequest.data?.data) {
    return renderTransaction({
      transaction: transactionRequest.data.data,
      nftTransaction: undefined,
      receipt: txWait.data,
    });
  } else if (nftTransactionRequest.data) {
    return renderTransaction({
      transaction: undefined,
      nftTransaction: nftTransactionRequest.data,
      receipt: txWait.data,
    });
  }

  return renderLoading('waiting');
}
