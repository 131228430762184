import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { HTMLAttributes, useRef, useState } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { Icon, CloseIcon } from '../icon';
import { media, useMediaQuery } from '../styles/media-query';
import { ModalContentContainer } from './modal-content';

const ModalOverlay = styled.div((props) => ({
  position: 'fixed',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflow: 'auto',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(26, 9, 49, 0.24)',
  backdropFilter: 'blur(4px)',
}));

const ModalClose = (props: HTMLAttributes<HTMLButtonElement>) => {
  const theme = useTheme();
  const isMd = useMediaQuery({ min: 'md' });

  return (
    <Icon
      as={'button'}
      size={isMd ? 24 : 20}
      css={{
        color: theme.colors.neutral[60],
        position: 'absolute',
        padding: 8,
        top: 16,
        right: 16,
        borderRadius: '9999999px',
        '&:hover': {
          backgroundColor: theme.colors.neutral[20],
        },
        ...media('md', {
          top: 32,
          right: 32,
        }),
      }}
      {...props}
    >
      <CloseIcon></CloseIcon>
    </Icon>
  );
};

export type ModalConfirmationCallback = (
  confirmed: boolean,
  e: React.MouseEvent
) => void;
type ModalProps = ReactModalProps &
  (
    | {
        confirmClose: true;
        renderConfirm: (
          onConfirm: ModalConfirmationCallback
        ) => React.ReactNode;
        renderChildren?: (
          onCloseClick: (e: React.MouseEvent) => void
        ) => React.ReactNode;
      }
    | {
        confirmClose?: false;
        renderConfirm?: (
          onConfirm: ModalConfirmationCallback
        ) => React.ReactNode | undefined;
        renderChildren?: (
          onCloseClick: (e: React.MouseEvent) => void
        ) => React.ReactNode;
      }
  ) & {
    ContentComponent?: React.ComponentType<any>;
  };

const ModalChildren = styled.div<{
  isShown?: boolean;
}>(({ isShown }) => ({
  flexGrow: 1,
  display: isShown ? 'flex' : 'none',
  flexDirection: 'column',
}));

export function Modal(props: ModalProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const ContentComponent = props.ContentComponent || ModalContentContainer;

  const getConfirmation: ModalConfirmationCallback = (confirmed, e) => {
    if (confirmed) {
      props.onRequestClose?.(e);
      setShowConfirmation(false);
    } else {
      setShowConfirmation(false);
      e.preventDefault();
    }
  };

  const onCloseClick = (e: React.MouseEvent) => {
    if (props.confirmClose && showConfirmation) {
      props.onRequestClose?.(e);
      setShowConfirmation(false);
    } else if (props.confirmClose) {
      setShowConfirmation(true);
    } else {
      props.onRequestClose?.(e);
    }
  };

  const onOverlayClick = (e: React.MouseEvent) => {
    if (e.target === overlayRef.current) {
      onCloseClick(e);
    }
  };

  return (
    <ReactModal
      overlayElement={({ style, onClick, ref, ...rest }, contentElement) => (
        <ModalOverlay
          onClick={onOverlayClick}
          ref={(el) => (overlayRef.current = el)}
          style={{
            zIndex: 1,
          }}
          {...rest}
        >
          {contentElement}
        </ModalOverlay>
      )}
      contentElement={({ style, ...rest }, children) => (
        <ContentComponent {...rest}>
          {children}
          {props.confirmClose && showConfirmation
            ? props.renderConfirm(getConfirmation)
            : null}
        </ContentComponent>
      )}
      preventScroll={true}
      {...props}
    >
      <ModalClose onClick={onCloseClick} />
      <ModalChildren isShown={!showConfirmation}>
        {props.renderChildren
          ? props.renderChildren(onCloseClick)
          : props.children}
      </ModalChildren>
    </ReactModal>
  );
}
