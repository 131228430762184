import styled from '@emotion/styled';
import { CSSPropertiesWithMultiValues } from '@emotion/serialize';
import { Theme } from '@emotion/react';
import tinycolor from 'tinycolor2';
import { media } from '../styles/media-query';
import { ButtonHTMLAttributes } from 'react';

type ButtonSemanticType = 'secondary' | 'primary';

const makeActiveColor = (color: string) => {
  return tinycolor(color).darken(10).toString();
};

type CSSPartialDefinitions = CSSPropertiesWithMultiValues & {
  [key: string]: any;
};

type ButtonMixinProps = {
  theme: Theme;
  isLoading?: boolean;
  disabled?: boolean;
};

function iff(
  condition: any,
  _then: CSSPartialDefinitions,
  _else?: CSSPartialDefinitions | undefined
): CSSPartialDefinitions {
  return condition ? _then : _else || {};
}

function outlinedButton(props: ButtonMixinProps): CSSPartialDefinitions {
  return {
    border: `1px solid ${props.theme.colors.neutral[30]}`,

    ...iff(props.disabled, {
      backgroundColor: props.theme.colors.neutral[10],
      color: props.theme.colors.neutral[50],
    }),
    ...iff(!props.disabled, {
      backgroundColor: props.theme.colors.neutral[10],
      color: props.theme.colors.neutral[100],
      '&:hover': {
        backgroundColor: props.theme.colors.neutral[30],
      },
      '&:active': {
        backgroundColor: makeActiveColor(props.theme.colors.neutral[30]),
      },
    }),
  };
}

function primaryQuvButton(props: ButtonMixinProps): CSSPartialDefinitions {
  const activeColor = makeActiveColor(props.theme.colors.buttons.stop1);

  return {
    ...iff(props.disabled, {
      backgroundColor: props.theme.colors.neutral[40],
      color: props.theme.colors.neutral[10],
    }),

    ...iff(!props.disabled, {
      background: props.theme.colors.buttons.gradient,
      color: props.theme.colors.neutral[10],
      '&:hover': {
        background: `linear-gradient(127.53deg, ${activeColor} 0.51%, ${activeColor} 58.55%, ${activeColor} 76.95%, ${activeColor} 100%)`,
      },
      '&:active': {
        background: props.theme.colors.buttons.gradientActive,
      },
    }),

    ...iff(props.isLoading, {
      pointerEvents: 'none',
      opacity: 0.5,
    }),
  };
}

function secondaryButton(props: ButtonMixinProps): CSSPartialDefinitions {
  return {
    border: `none`,
    backgroundColor: 'red',
    color: props.theme.colors.neutral[10],

    '&:active': {
      backgroundColor: props.theme.colors.purple[100],
    },
    '&:hover': {
      backgroundColor: props.theme.colors.purple[100],
    },
  };
}

type ButtonProps = {
  size?: 'normal' | 'compact';
  semanticType?: ButtonSemanticType;
  isLoading?: boolean;
};

const _Button = styled.button<ButtonProps>((props) => ({
  borderRadius: props.size === 'normal' ? 20 : 12,
  display: 'inline-flex',
  height: props.size === 'normal' ? 64 : 48,
  justifyContent: 'center',
  padding: props.size === 'normal' ? '20px 32px' : '12px 24px',

  fontSize: 16,
  fontWeight: 600,
  transition: 'background-color 0.15s ease-in-out, background 0.2s ease-in-out',
  ...(props.semanticType === 'primary'
    ? primaryQuvButton(props)
    : props.semanticType === 'secondary'
    ? outlinedButton(props)
    : secondaryButton(props)),
}));

export function Button({
  size = 'normal',
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps) {
  return (
    <_Button size={size} {...props}>
      {props.isLoading ? 'Loading...' : props.children}
    </_Button>
  );
}

export const NonWrappingButtons = styled.div<{ nButtons?: number }>(
  ({ nButtons = 2, theme }) => ({
    gridGap: theme.spacer * 4,
    display: 'grid',
    gridTemplateColumns: `repeat(${nButtons}, 1fr)`,
  })
);

export const Buttons = styled.div<{ nButtons?: number; wrap?: boolean }>(
  ({ nButtons = 2, wrap = true, theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    '& > *:not(:last-of-type)': {
      marginTop: theme.spacer * 4,
    },

    ...(wrap
      ? media('md', {
          gridGap: theme.spacer * 4,
          display: 'grid',
          gridTemplateColumns: `repeat(${nButtons}, 1fr)`,
          '& > *:not(:last-of-type)': {
            marginTop: 0,
          },
        })
      : {}),
  })
);
