import BigNumber from 'bignumber.js';
import { HexString } from '../../types';

export function parseIntBalance(value: string | BigNumber, decimals: number) {
  return new BigNumber(value)
    .dividedBy(new BigNumber(10).pow(decimals))
    .toNumber();
}

export function toHexString(value: BigInt): HexString {
  return `0x${value.toString(16)}`;
}
