import { endpoint, ResponseType } from '~/shared/api';
import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { Transaction } from '.';
import { alchemy } from '~/shared/ethereum/alchemy';
import {
  AssetTransfersCategory,
  AssetTransfersResult,
  AssetTransfersWithMetadataResult,
  GetTransfersForOwnerTransferType,
  TransferredNft,
  TransactionReceipt,
} from 'alchemy-sdk';
import { toHex } from 'viem';
import { BareFetcher, PublicConfiguration } from 'swr/_internal';

type SwrConfig<T> = Partial<PublicConfiguration<T, any, BareFetcher<T>>>;

const transactionHistoryURL = (address: string) =>
  endpoint(`/v1/account/${address}/transactions`);

const singleTxURL = (hash: string) => endpoint(`/v1/transaction/${hash}`);

const ifNftTransfer = (
  transfer: AssetTransfersWithMetadataResult | TransferredNft
): transfer is TransferredNft => {
  return 'collection' in transfer;
};

const isTokenTransfer = (
  transfer: AssetTransfersWithMetadataResult | TransferredNft
) => {
  return 'category' in transfer;
};

export function useAccountTransactions(
  {
    address,
  }: {
    address: string | undefined;
  },
  swrConfig?: SwrConfig<ResponseType<AssetTransfersWithMetadataResult[]>>
) {
  const data = useSWR<ResponseType<AssetTransfersWithMetadataResult[]>>(
    () => (address ? 'transactions-' + address : null),
    async () => {
      invariant(address, 'address is required');
      const [incoming, outgoing /* nftsIncoming, nftOutgoing */] =
        await Promise.all([
          alchemy.core.getAssetTransfers({
            toAddress: address,
            withMetadata: true,
            category: [
              AssetTransfersCategory.ERC20,
              AssetTransfersCategory.ERC721,
              AssetTransfersCategory.ERC1155,
            ],
          }),
          alchemy.core.getAssetTransfers({
            fromAddress: address,
            withMetadata: true,
            category: [
              AssetTransfersCategory.ERC20,
              AssetTransfersCategory.ERC721,
              AssetTransfersCategory.ERC1155,
            ],
          }),
          // alchemy.nft.getTransfersForOwner(
          //   address,
          //   GetTransfersForOwnerTransferType.TO,
          //   {}
          // ),
          // alchemy.nft.getTransfersForOwner(
          //   address,
          //   GetTransfersForOwnerTransferType.FROM,
          //   {}
          // ),
        ]);

      const sortedByDate = [
        ...incoming.transfers,
        ...outgoing.transfers,
        // ...nftOutgoing.nfts,
        // ...nftsIncoming.nfts,
      ].sort((a, b) => {
        const aDate = ifNftTransfer(a)
          ? a.acquiredAt?.blockTimestamp
          : a.metadata.blockTimestamp;
        const bDate = ifNftTransfer(b)
          ? b.acquiredAt?.blockTimestamp
          : b.metadata.blockTimestamp;

        return new Date(bDate || 0).getTime() - new Date(aDate || 0).getTime();
      });

      return {
        data: sortedByDate,
      };
      // return fetch(transactionHistoryURL(address)).then((res) => res.json());
    },
    swrConfig
  );

  return data;
}

export function useSingleTransaction(
  { hash }: { hash: string | undefined },
  swrConfig?: SwrConfig<ResponseType<Transaction>>
) {
  const data = useSWR<ResponseType<Transaction>>(
    () => (hash ? 'transactions-' + hash : null),
    () => {
      invariant(hash, 'hash is required');

      return fetch(singleTxURL(hash)).then((res) => res.json());
    },
    swrConfig
  );

  return data;
}

export function useSingleAssetTransfer(
  {
    address,
    receipt,
  }: {
    address: string | undefined | null;
    receipt: TransactionReceipt | undefined | null;
  },
  swrConfig?: SwrConfig<AssetTransfersResult | undefined>
) {
  const isReady = !!receipt && !!address;
  const data = useSWR<AssetTransfersResult | undefined>(
    () => (isReady ? 'transactions-' + receipt.transactionIndex : null),
    async () => {
      invariant(receipt, 'receipt is required');
      invariant(address, 'address is required');

      const response = await alchemy.core.getAssetTransfers({
        fromBlock: toHex(receipt.blockNumber),
        toBlock: toHex(receipt.blockNumber),
        fromAddress: receipt.from,
        contractAddresses: [receipt.to],
        category: [
          AssetTransfersCategory.ERC20,
          AssetTransfersCategory.ERC721,
          AssetTransfersCategory.ERC1155,
          AssetTransfersCategory.SPECIALNFT,
        ],
      });

      const wantedTransfer = response.transfers.find(
        (transfer) => transfer.hash === receipt.transactionHash
      );

      return wantedTransfer;
    },
    swrConfig
  );

  return data;
}

export function useTransactionReceipt(
  { hash }: { hash: string },
  swrConfig?: Partial<
    PublicConfiguration<
      TransactionReceipt | null,
      any,
      BareFetcher<TransactionReceipt | null>
    >
  >
) {
  const data = useSWR<TransactionReceipt | null>(
    () => (hash ? 'transaction-receipt-' + hash : null),
    () => {
      invariant(hash, 'hash is required');

      return alchemy.core.getTransactionReceipt(hash);
    },
    swrConfig
  );

  return data;
}

export function useSingleNftTransaction(
  {
    address,
    receipt,
  }: {
    address: string | undefined | null;
    receipt: TransactionReceipt | undefined | null;
  },
  swrConfig?: SwrConfig<TransferredNft | undefined>
) {
  const isReady = !!receipt && !!address;
  console.debug('useSingleNFTTransaction', isReady);

  const data = useSWR<TransferredNft | undefined>(
    () => (isReady ? 'transactions-' + receipt.transactionHash : null),
    async () => {
      invariant(receipt, 'receipt is required');
      invariant(address, 'address is required');

      const transfers = await alchemy.nft.getTransfersForOwner(
        address,
        receipt.from === address
          ? GetTransfersForOwnerTransferType.FROM
          : GetTransfersForOwnerTransferType.TO,
        {
          contractAddresses: [receipt.to],
        }
      );

      const wantedTransfer = transfers.nfts.find(
        (nftTransfer) => nftTransfer.transactionHash === receipt.transactionHash
      );
      return wantedTransfer;
    },
    swrConfig
  );

  return data;
}
