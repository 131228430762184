import styled from '@emotion/styled';
import { CardListItem } from '~/shared/ui/kit';

export const Container = styled(CardListItem)((props) => ({
  padding: '0',
}));

export const IconContainer = styled.div<{
  isReceived: boolean;
}>((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: 14,
  backgroundColor: props.isReceived
    ? props.theme.colors.success[20]
    : props.theme.colors.neutral[20],
}));

export const TxValue = styled.div<{ isReceived: boolean }>((props) => ({
  color: props.isReceived
    ? props.theme.colors.success[120]
    : props.theme.colors.neutral[80],
  fontWight: 500,
  fontSize: 16,
  letterSpacing: '-0.02em',
}));

export const TxValueFiat = styled.div((props) => ({
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: '-0.02em',
  color: props.theme.colors.neutral[60],
}));
