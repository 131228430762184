import { Nft } from 'alchemy-sdk';
import { ListOfCards } from '~/shared/ui/kit';
import { NftCollections } from '../types';
import { NftCollectionShowcase } from './NftCollectionShowcase';
import { NftsEmpty } from './NftsEmpty';

type NftListProps = React.PropsWithChildren<{
  nftCollections: NftCollections;
  onOpenNft: (token: Nft) => void;
}>;

export function NftList({ nftCollections, onOpenNft, ...rest }: NftListProps) {
  if (!Object.keys(nftCollections).length) {
    return <NftsEmpty />;
  }

  // Sort collections by most recent action (sending/receiving);

  const collections = Object.values(nftCollections).sort((a, b) => {
    return (
      new Date(b.mostRecentAcquire).getTime() -
      new Date(a.mostRecentAcquire).getTime()
    );
  });

  return (
    <ListOfCards spacing={4} {...rest}>
      {collections.map((collection, i) => (
        <NftCollectionShowcase
          collection={collection}
          key={collection.contract}
          onNftClick={onOpenNft}
          isOpenByDefault={i === 0}
        />
      ))}
    </ListOfCards>
  );
}
