import { ADAPTER_STATUS } from '@web3auth/base';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { useEffect, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';
import { Web3AuthContext, Web3AuthProviderProps } from './shared';
import { useAsyncAction } from '~/shared/hooks/useAsyncStatus';
import { web3AuthInstance } from '../connector/wagmi';

export function Web3AuthProvider({
  children,
  renderNotInitialized,
}: Web3AuthProviderProps) {
  const web3auth = web3AuthInstance;
  const [status, setStatus] = useState(web3auth.status);
  const isLoggedIn = web3auth ? status === ADAPTER_STATUS.CONNECTED : false;
  const init = useAsyncAction(() => web3auth.init());

  useEffect(() => {
    const id = setInterval(() => {
      if (web3auth.status !== status) {
        setStatus(web3auth.status);
      }
    }, 300);

    return () => {
      clearInterval(id);
    };
  }, [web3auth, status]);

  useEffect(() => {
    if (!init.isLoading && !init.isSuccess) {
      init.run();
    }
  });

  const contextValue = useMemo(() => {
    if (!web3auth) return null;

    return {
      web3auth: web3auth as unknown as Web3AuthNoModal,
      isLoggedIn,
    };
  }, [isLoggedIn, web3auth]);

  const isOkStatus =
    web3auth.status === ADAPTER_STATUS.READY ||
    web3auth.status === ADAPTER_STATUS.CONNECTED;

  const isErrorStatus = web3auth.status === ADAPTER_STATUS.ERRORED;

  if (!isErrorStatus && (init.isLoading || !init.isSuccess || !isOkStatus)) {
    return renderNotInitialized();
  }

  invariant(web3auth, "Web3Auth isn't initialized yet");

  return (
    <Web3AuthContext.Provider value={contextValue}>
      {children}
    </Web3AuthContext.Provider>
  );
}
