import { Theme } from '@emotion/react';
import styled, { Interpolation } from '@emotion/styled';
import React from 'react';

const OuterCointainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
});

const Messages = styled.div((props) => ({
  padding: `${props.theme.spacer}px ${props.theme.spacer * 5}px 0 ${
    props.theme.spacer * 5
  }px`,
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 500,
  display: 'flex',
  width: '100%',
  color: props.theme.colors.neutral[50],
}));

export const ErrorMessage = styled.span((props) => ({
  color: props.theme.colors.critical[100],
  marginRight: 'auto',
}));

export const InputButton = styled.button((props) => ({
  textTransform: 'uppercase',
  color: props.theme.colors.neutral[60],
  position: 'absolute',
  right: 16,
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  padding: 4,
  '&:hover': {
    color: props.theme.colors.neutral[100],
  },
}));

const InputContainer = styled.label<{ hasError: boolean }>(
  ({ theme, hasError }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 56,
    background: theme.colors.neutral[10],
    border: `1.5px solid ${theme.colors.neutral[30]}`,
    borderRadius: 18,
    padding: `${theme.spacer * 2}px ${theme.spacer * 5}px`,
    position: 'relative',
    borderColor: hasError
      ? theme.colors.critical[20]
      : theme.colors.neutral[30],
    '& input': {
      outline: 'none',
      background: 'transparent',
    },

    '&:focus-within': {
      borderColor: `${theme.colors.neutral[100]}`,
    },
  })
);

type InputBaseProps = React.PropsWithChildren<{
  errorMessage?: string;
  extraMessage?: JSX.Element;
  inputContainerStyles?: Interpolation<Theme>;
  controls?: JSX.Element;
}>;

export function InputBase({
  children,
  errorMessage,
  extraMessage,
  inputContainerStyles,
  controls,
  ...rest
}: InputBaseProps) {
  const hasMessage = !!errorMessage || !!extraMessage;
  return (
    <OuterCointainer {...rest}>
      <InputContainer hasError={!!errorMessage} css={inputContainerStyles}>
        {children}
        {controls}
      </InputContainer>
      {hasMessage && (
        <Messages>
          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
          {extraMessage ? (
            <div
              css={{
                marginLeft: 'auto',
              }}
            >
              {extraMessage}
            </div>
          ) : null}
        </Messages>
      )}
    </OuterCointainer>
  );
}
