import styled from '@emotion/styled';
import { ENV_NETWORK } from '~/app/config';
import { NearPayWidget, OnRampConfig } from '~/features/top-up';

const OnramperWidget = styled.iframe(({ theme }) => ({}));

const nearpayUrlPrefix = ENV_NETWORK === 'testnet' ? 'stage-widget' : 'widget';

// ?toCurrency=USDT-MATIC&toWallet=0xff1A8Aa1618dcdE99df89179e67bE15B04c385AD&signature=0316357a70a1d83d81a9718ada226b54be53222a51ce577dafa0fa22caca00d9&apiKey=d6bfacf2-0deb-4ce4-ad7d-0ba267bb0ed3%26

export function OnrampWidget({
  address,
  onrampConfig,
  ...rest
}: {
  address: string;
  onrampConfig: OnRampConfig;
}) {
  return (
    <NearPayWidget
      params={{
        toWallet: address,
        toCurrency: 'USDT-MATIC',
        apiKey: onrampConfig.nearpay.api_key,
      }}
      localSign={true}
      {...rest}
    />
  );
}
