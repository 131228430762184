import { makeAutoObservable } from 'mobx';
import { useEffect } from 'react';
import { checkResponseCode, endpoint } from '~/shared/api';
import { useUserInfo } from '~/shared/ethereum/web3auth';

/*
PUT https://quv-backend.rc.aws.quv.io/v1/account
Content-Type: application/json

{
  "id_token": "<id token>"
}
*/
async function submitWeb3AuthToken(token: string) {
  const url = endpoint('/v1/account');

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id_token: token,
    }),
  }).then(checkResponseCode);

  return response.json();
}

class AppModel {
  web3AuthIdToken: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async submitWeb3AuthIdToken(token: string) {
    const response = await submitWeb3AuthToken(token);
  }
}

export const appModel = new AppModel();

export function useWeb3AuthTokenSubmit() {
  const userInfo = useUserInfo();
  const idToken = userInfo.data?.idToken;

  useEffect(() => {
    if (idToken) {
      appModel.submitWeb3AuthIdToken(idToken);
    }
  }, [idToken]);
}
