export const CheckOutlinedIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8ZM4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24ZM33.3514 16.5257C34.1657 17.2721 34.2207 18.5372 33.4743 19.3514L22.4743 31.3514C22.0844 31.7768 21.5297 32.0131 20.9528 31.9994C20.3759 31.9858 19.833 31.7237 19.4636 31.2804L14.4636 25.2804C13.7564 24.4318 13.8711 23.1707 14.7196 22.4636C15.5682 21.7564 16.8293 21.8711 17.5364 22.7196L21.0717 26.962L30.5257 16.6486C31.2721 15.8343 32.5372 15.7793 33.3514 16.5257Z"
      fill="currentColor"
    />
  </svg>
);
