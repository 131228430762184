import React, { PropsWithChildren, useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import ChainedBackend from 'i18next-chained-backend';

export default i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next);

i18n.init({
  fallbackLng: 'en',
  debug: true,
  backend: {
    backends: [
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`./translations/${language}/${namespace}.json`)
      ),
    ],
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export const i18nContext = React.createContext<{
  i18n: typeof i18n;
  language: string;
}>({
  i18n: i18n,
  language: 'en',
});

export function i18nProvider({ children }: PropsWithChildren) {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const callback = (lng: string) => {
      setLanguage(lng);
    };

    i18n.on('languageChanged', callback);
    return () => i18n.off('languageChanged', callback);
  }, []);

  return (
    <i18nContext.Provider
      value={{
        i18n: i18n,
        language: language,
      }}
    >
      {children}
    </i18nContext.Provider>
  );
}
