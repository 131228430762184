import { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { QuvioOrder } from '~/features/top-up';
import { MerchantBuyLink } from '~/features/top-up/merchant';
import {
  BodyPrimaryText,
  Button,
  Buttons,
  CardBase,
  InputBase,
  InputText,
  LinkButton,
  SecondaryMaterial,
  SublineText,
} from '~/shared/ui/kit';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';
import {
  PayWithQuvWhiteButton,
  PayWithQuvBlackButton,
  PayWithQuvButton,
  PayWithQuvBrandedButton,
} from './pay-with-quv';
import { validateEmail } from '~/shared/validation';
import { ENV_WHITELABEL_NAME } from '~/app/config';

const defaultValues: Partial<QuvioOrder> & { secret_key: string } = {
  amount: '',
  currency: 'USDT',
  order_id: '',
  redirect_url: '',
  email: '',
  sumsub_token: '',
  secret_key: '',
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .typeError('Amount is not a number')
    .positive(),
  order_id: yup.string().required(),
  secret_key: yup.string().required(),
  currency: yup.string().required(),
  email: yup
    .string()
    .email()
    .test({
      test: (email) => (email ? validateEmail(email) : true),
      message: "That's not a valid email",
    }),
});

const resolver = yupResolver(schema);

// get hex uuid
const getUuid = () => {
  const hex = '0123456789abcdef';
  let output = '0x';
  for (let i = 0; i < 32; ++i) {
    output += hex.charAt(Math.floor(Math.random() * hex.length));
  }
  return output;
};

export function MerchantPlayground() {
  const [params, setParams] = useState<
    Partial<QuvioOrder> & { secret_key?: string }
  >({});

  const theme = useTheme();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    reValidateMode: 'onChange',
    resolver: resolver,
    mode: 'onChange',
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
  });

  const isValid =
    params.amount && params.currency && params.order_id && params.secret_key;
  const validParameters = isValid
    ? (params as Pick<QuvioOrder, 'currency' | 'order_id' | 'amount'>)
    : null;

  const watch = useWatch({
    control,
    name: [
      'currency',
      'order_id',
      'amount',
      'email',
      'sumsub_token',
      'redirect_url',
    ],
  });

  useEffect(() => {
    setParams({});
  }, watch);

  return (
    <CardBase
      css={{ width: '100%', maxWidth: '520px', margin: '50px auto 20px auto' }}
    >
      <form onSubmit={handleSubmit(setParams)} css={{ marginBottom: 24 }}>
        <Controller
          control={control}
          name="sumsub_token"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 16 }}
              errorMessage={errors.sumsub_token?.message?.toString()}
            >
              <InputText
                label="Sumsub token"
                autoComplete="off"
                {...field}
              ></InputText>
            </InputBase>
          )}
        ></Controller>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 16 }}
              errorMessage={errors.email?.message?.toString()}
            >
              <InputText
                label="Email"
                autoComplete="off"
                {...field}
              ></InputText>
            </InputBase>
          )}
        ></Controller>

        <Controller
          control={control}
          name="amount"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 16 }}
              errorMessage={errors.amount?.message?.toString()}
            >
              <InputText
                label="Amount"
                autoComplete="off"
                {...field}
              ></InputText>
            </InputBase>
          )}
        ></Controller>
        <Controller
          control={control}
          name="currency"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 4 }}
              errorMessage={errors.currency?.message?.toString()}
              extraMessage={
                <span>
                  For development environment you can use USDT currency
                </span>
              }
            >
              <InputText label="Currency" {...field}></InputText>
            </InputBase>
          )}
        ></Controller>
        <BodyPrimaryText
          as="p"
          css={{
            marginBottom: 16,
            color: theme.colors.neutral[60],
            fontSize: 12,
          }}
        ></BodyPrimaryText>
        <Controller
          control={control}
          name="order_id"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 16 }}
              errorMessage={errors.order_id?.message?.toString()}
              extraMessage={
                <LinkButton
                  as="button"
                  type="button"
                  onClick={() => {
                    const uid = getUuid();
                    setValue('order_id', uid);
                  }}
                >
                  Randomize
                </LinkButton>
              }
            >
              <InputText
                label="Order Id"
                autoComplete="off"
                {...field}
              ></InputText>
            </InputBase>
          )}
        ></Controller>
        <Controller
          control={control}
          name="redirect_url"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 16 }}
              errorMessage={errors.redirect_url?.message?.toString()}
            >
              <InputText label="Redirect Url" {...field}></InputText>
            </InputBase>
          )}
        ></Controller>
        <Controller
          control={control}
          name="secret_key"
          render={({ field }) => (
            <InputBase
              css={{ marginBottom: 16 }}
              errorMessage={errors.secret_key?.message?.toString()}
            >
              <InputText
                label="Secret Key"
                type={'password'}
                autoComplete={'off'}
                {...field}
              ></InputText>
            </InputBase>
          )}
        ></Controller>

        <Buttons nButtons={1}>
          <Button semanticType="primary">Generate Link</Button>
        </Buttons>
      </form>
      <div css={{ textAlign: 'center' }}>
        {validParameters ? (
          <MerchantBuyLink
            orderId={validParameters.order_id}
            currency={validParameters.currency}
            amount={validParameters.amount}
            sumsub_token={params.sumsub_token || ''}
            redirect_url={params.redirect_url || ''}
            client_secret={params.secret_key || ''}
            email={params.email || ''}
            render={(link) => {
              return (
                <div>
                  <LinkButton
                    as={Link}
                    // @ts-ignore
                    to={link}
                    target="_blank"
                  >
                    Buy Link
                  </LinkButton>

                  {ENV_WHITELABEL_NAME === 'Quv.io' ? (
                    <SecondaryMaterial css={{}}>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 16,
                        }}
                      >
                        <PayWithQuvButton link={link} />
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 16,
                        }}
                      >
                        <PayWithQuvWhiteButton link={link} />
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 16,
                        }}
                      >
                        <PayWithQuvBlackButton link={link} />
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 16,
                        }}
                      >
                        <PayWithQuvBrandedButton link={link} />
                      </div>
                      <SublineText as="p">
                        Just copy HTML of the button you like and paste it to
                        your website
                      </SublineText>
                    </SecondaryMaterial>
                  ) : null}
                </div>
              );
            }}
          />
        ) : (
          <p>Link will appear here</p>
        )}
      </div>
    </CardBase>
  );
}

export default MerchantPlayground;
