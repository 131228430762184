import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Nft } from 'alchemy-sdk';
import {
  AttentionIcon,
  CheckOutlinedIcon,
  Icon,
  media,
  useMediaQuery,
} from '~/shared/ui/kit';
import { NftItemCard } from './NftItemCard';

const StatusContainer = styled.div<{ status: 'success' | 'error' }>(
  ({ theme, status }) => ({
    width: 104,
    display: 'flex',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    paddingRight: 12,

    right: 0,
    top: 0,
    position: 'absolute',
    backgroundColor:
      status === 'success'
        ? theme.colors.success[100]
        : theme.colors.critical[100],

    zIndex: -1,
    ...media('md', {
      paddingRight: 16,
      borderRadius: 20,
    }),
    ...media('lg', {
      paddingRight: 16,
      borderRadius: 24,
    }),
  })
);

export function NftStatus({
  nft,
  status,
  ...rest
}: {
  nft: Nft;
  status: 'success' | 'error';
}) {
  const theme = useTheme();
  const isLarge = useMediaQuery({
    min: 'md',
  });

  return (
    <div {...rest}>
      <div css={{ position: 'relative', zIndex: 1 }}>
        <NftItemCard
          nft={nft}
          css={{
            width: 'calc(100% - 48px)',
            ...media('md', {
              width: 'calc(100% - 64px)',
            }),
          }}
        />
        <StatusContainer status={status}>
          <Icon
            size={isLarge ? 32 : 24}
            css={{
              color: theme.colors.background,
            }}
          >
            {status === 'success' ? <CheckOutlinedIcon /> : <AttentionIcon />}
          </Icon>
        </StatusContainer>
      </div>
    </div>
  );
}
