import { ERC20TokenSymbol } from './types';

export type Token = {
  decimals: number;
  name: string;
  symbol: ERC20TokenSymbol | string;
};

export type UserToken = Token & {
  balance: `0x${string}`;
  contract: `0x${string}` | null;
  logo: string | null;
};

export const MATIC_TOKEN: Token = {
  decimals: 18,
  name: 'Matic',
  symbol: 'MATIC',
};

export const mockTokens: UserToken[] = [
  {
    decimals: 18,
    name: 'Matic Token',
    symbol: 'MATIC',
    balance: '0x01fc123',
    contract: null,
    logo: null,
  },
  {
    decimals: 18,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    balance: '0x003fadafa',
    contract: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    logo: null,
  },
  {
    decimals: 6,
    name: '(PoS) Tether USD',
    symbol: 'USDT',
    balance: '0x0004feffe',
    contract: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    logo: null,
  },
];
