import { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { ChevDownIcon, Icon } from '~/shared/ui/kit';
import { NftCollection } from '../types';
import { NftThumbnail } from './NftThumbnail';
import { Nft } from 'alchemy-sdk';
import { NftCollectionTitle } from './NftCollectionTitle';

const NftItemsWrapper = styled.div((props: { isOpen: boolean }) => ({
  display: props.isOpen ? 'grid' : 'none',
  flexWrap: 'wrap',
  gridTemplateColumns: `repeat(3, 1fr)`,
  gap: '11px',
}));

const NftCollectionHeader = styled.div({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
});

const NftCollectionAmount = styled.span((props) => ({
  position: 'relative',
  top: '-5px',
  color: props.theme.colors.neutral[60],
  fontSize: 12,
  marginLeft: 8,
  letterSpacing: -0.24,
}));

type NftCollectionProps = React.PropsWithChildren<{
  collection: NftCollection;
  onNftClick: (token: Nft) => void;
  isOpenByDefault?: boolean;
}>;

export function NftCollectionShowcase({
  collection,
  onNftClick,
  isOpenByDefault,
}: NftCollectionProps) {
  const { nfts, name, img } = collection;
  const [isOpen, setIsOpen] = useState(isOpenByDefault || false);

  const actionsEl = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <NftCollectionHeader
        onClick={(e) => {
          if (e.target === actionsEl.current) {
            return;
          }

          setIsOpen(!isOpen);
        }}
      >
        <div
          css={{
            display: 'flex',
          }}
        >
          <NftCollectionTitle collection={collection}></NftCollectionTitle>
          {!!nfts.length && (
            <NftCollectionAmount>{nfts.length}</NftCollectionAmount>
          )}
        </div>
        <Icon css={{ transform: isOpen ? '' : 'rotate(-0.25turn)' }} size={24}>
          <ChevDownIcon />
        </Icon>
      </NftCollectionHeader>

      <NftItemsWrapper
        isOpen={isOpen}
        css={{
          marginTop: 8,
        }}
      >
        {nfts.map((token) => (
          <NftThumbnail
            token={token}
            key={token.tokenId}
            onClick={() => onNftClick(token)}
          />
        ))}
      </NftItemsWrapper>
    </div>
  );
}
