import { Link, LinkProps, useMatch } from 'react-router-dom';
import styled from '@emotion/styled';

export const _NavigationLink = styled(Link)<{
  isActive: boolean;
}>((props) => ({
  color: props.isActive
    ? props.theme.colors.neutral[100]
    : props.theme.colors.neutral[60],
  padding: '8px 20px',
  height: '40px',
  backgroundColor: props.isActive
    ? props.theme.colors.neutral[30]
    : 'transparent',
  borderRadius: '99999px',

  fontWeight: 700,
  '&:hover': {
    color: props.theme.colors.neutral[80],
  },
}));
export function NavigationLink(props: LinkProps) {
  const match = useMatch(props.to as string);

  const isActive = match !== null;
  return <_NavigationLink {...props} isActive={isActive} />;
}

export const NavigationLinks = styled.ul((props) => ({
  '> *:not(:last-of-type)': {
    marginRight: 24,
  },
}));
