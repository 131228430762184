import { observer } from 'mobx-react';
import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { MerchantApi } from './api';
import { MerchantModel } from './model';
import { AccessToken } from './types';
export * from './api';

type MerchantContextValue = {
  isAuthenticated: boolean;
  login: (ticket: string) => void;
  logout: () => void;
  api: MerchantApi;
  token: AccessToken | null;
};

export const MerchantContext = createContext<MerchantContextValue>({
  isAuthenticated: false,
  token: null,
  login: () => {},
  logout: () => {},
  api: new MerchantApi(null, () => {}),
});

export function _MerchantApiProvider({
  children,
  context,
}: PropsWithChildren<{ model: MerchantModel; context: MerchantContextValue }>) {
  return (
    <MerchantContext.Provider value={context}>
      {children}
    </MerchantContext.Provider>
  );
}

export const MerchantApiProvider = observer(
  ({ model, ...rest }: PropsWithChildren<{ model: MerchantModel }>) => {
    return _MerchantApiProvider({
      model,
      context: model.context,
      ...rest,
    });
  }
);

export const useMerchantContext = () => {
  return useContext(MerchantContext);
};
