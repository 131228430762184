import styled from '@emotion/styled';

export const MarkdownZone = styled('div')(({ theme }) => ({
  p: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.colors.neutral[100],
  },
  b: {
    fontWeight: 700,
  },
  a: {
    color: theme.colors.purple[120],
    fontWeight: 700,
  },
}));
