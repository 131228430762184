import { HTMLAttributes } from 'react';
import { Token } from '..';
import {
  formatFiatValue,
  formatTokenBalance,
  normalizeTokenBalance,
} from '../utils';
import { TokenFiatBalance } from '../shared/styles';
import { TokenIcon } from './TokenIcon';
import { useFiatValue } from '~/shared/fiat-converter';
import { OutlinedCardListItem } from '~/shared/ui/kit';

type TokenItemProps = HTMLAttributes<HTMLDivElement> & {
  token: Token;
  isClickable?: boolean;
};

export function TokenItem({
  token,
  isClickable = false,
  ...rest
}: TokenItemProps) {
  const fiatValue = useFiatValue({
    value: normalizeTokenBalance(token),
    symbol: token.symbol,
  });

  return (
    <OutlinedCardListItem
      title={token.name}
      image={<TokenIcon token={token} />}
      isClickable={isClickable}
      controls={
        <>
          <div>{formatTokenBalance(token)}</div>
          <TokenFiatBalance>
            {fiatValue.symbol}
            {fiatValue.fiatValue === null
              ? ' –'
              : formatFiatValue(fiatValue.fiatValue)}
          </TokenFiatBalance>
        </>
      }
      {...rest}
    ></OutlinedCardListItem>
  );
}
