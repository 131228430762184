import { Token, useAccountTokens } from '~/entities/tokens';
import { useStepProgress } from '~/shared/hooks/useStepProgress';
import { ModalHeading, ModalSubHeading } from '~/shared/ui/kit';
import { useAccount } from 'wagmi';
import { useSendForm } from './form';
import { SelectToken } from './ui/select-token';
import { useTranslation } from 'react-i18next';

export function SelectTokenStep() {
  const { t } = useTranslation('common');
  const account = useAccount();
  const tokens = useAccountTokens({
    address: account.address,
  });

  const form = useSendForm();
  const steps = useStepProgress();

  return (
    <>
      <ModalHeading>{t('glossary.send')}</ModalHeading>
      <ModalSubHeading
        css={{
          marginBottom: 16,
        }}
      >
        {t('sendTokens.chooseAsset')}
      </ModalSubHeading>
      <SelectToken
        tokens={tokens.data?.data || []}
        onSelectToken={(token: Token) => {
          form.setValues({
            token,
          });
          steps.goForward();
        }}
      />
    </>
  );
}
