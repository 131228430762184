import {
  BigNumber as EBigNumber,
  FixedNumber,
  formatFixed,
  parseFixed,
} from '@ethersproject/bignumber';

export const toEthers = (
  value: EBigNumber | string,
  decimals: number
): FixedNumber => {
  return FixedNumber.fromString(formatFixed(value, decimals));
};

export const toWei = (value: string, decimals: number): EBigNumber => {
  const trimmed = FixedNumber.from(value).round(decimals).toString();
  const valueNumber = parseFixed(trimmed, decimals);

  return valueNumber;
};

export const toWeiBigInt = (value: string, decimals: number): bigint => {
  return BigInt(toWei(value, decimals).toString());
};
