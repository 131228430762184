import { px } from '~/shared/utils/css-helpers';
import styled from '@emotion/styled';
import { media } from '../styles/media-query';

const shadow = '0px 8px 20px rgba(39, 16, 69, 0.04);';

export const CardCarcass = styled.div(({ theme }) => ({
  boxShadow: shadow,
  backgroundColor: theme.colors.neutral[10],
}));

export const CardBase = styled(CardCarcass)(({ theme }) => ({
  paddingLeft: theme.spacer * 5,
  paddingRight: theme.spacer * 5,
  paddingTop: theme.spacer * 10,
  paddingBottom: theme.spacer * 10,

  borderRadius: theme.spacer * 9,
  ...media('md', {
    paddingLeft: theme.spacer * 10,
    paddingRight: theme.spacer * 10,
  }),
}));

export const CardTitle = styled.h3(({ theme }) => ({
  fontSize: 20,
  lineHeight: px(28),
  fontWeight: 900,
  color: theme.colors.neutral[100],
}));
