export const PayViaQuvGradient = () => (
  <svg
    width="134"
    height="24"
    viewBox="0 0 134 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.0909 0H3.81818C1.71286 0 0 1.71286 0 3.81818V20.1818C0 22.2871 1.71286 24 3.81818 24H31.0909C33.1962 24 34.9091 22.2871 34.9091 20.1818V3.81818C34.9091 1.71286 33.1962 0 31.0909 0ZM3.81818 2.18182H31.0909C31.9932 2.18182 32.7273 2.91593 32.7273 3.81818V6H2.18182V3.81818C2.18182 2.91593 2.91593 2.18182 3.81818 2.18182ZM31.0909 21.8182H3.81818C2.91593 21.8182 2.18182 21.0841 2.18182 20.1818V9.84416H32.7273V20.1818C32.7273 21.0841 31.9932 21.8182 31.0909 21.8182Z"
      fill="#7725D9"
    />
    <path
      d="M8.1369 18.5454H7.04599C6.44353 18.5454 5.95508 18.057 5.95508 17.4545V16.3636C5.95508 15.7612 6.44353 15.2727 7.04599 15.2727H8.1369C8.73935 15.2727 9.22781 15.7612 9.22781 16.3636V17.4545C9.22781 18.057 8.73935 18.5454 8.1369 18.5454Z"
      fill="#7725D9"
    />
    <path
      opacity="0.8"
      d="M50.5852 16L47.9572 10.24H49.4212L51.4252 14.932H50.5612L52.5772 10.24H53.9812L51.3532 16H50.5852ZM54.7704 16V10.24H56.0904V16H54.7704ZM55.4304 9.184C55.2064 9.184 55.0184 9.108 54.8664 8.956C54.7224 8.804 54.6504 8.616 54.6504 8.392C54.6504 8.168 54.7224 7.98 54.8664 7.828C55.0184 7.676 55.2064 7.6 55.4304 7.6C55.6624 7.6 55.8504 7.676 55.9944 7.828C56.1384 7.98 56.2104 8.168 56.2104 8.392C56.2104 8.616 56.1384 8.804 55.9944 8.956C55.8504 9.108 55.6624 9.184 55.4304 9.184ZM59.9829 16.12C59.4629 16.12 58.9909 15.988 58.5669 15.724C58.1509 15.46 57.8189 15.104 57.5709 14.656C57.3309 14.2 57.2109 13.692 57.2109 13.132C57.2109 12.564 57.3309 12.056 57.5709 11.608C57.8189 11.152 58.1509 10.792 58.5669 10.528C58.9909 10.256 59.4629 10.12 59.9829 10.12C60.4229 10.12 60.8109 10.216 61.1469 10.408C61.4909 10.592 61.7629 10.848 61.9629 11.176C62.1629 11.504 62.2629 11.876 62.2629 12.292V13.948C62.2629 14.364 62.1629 14.736 61.9629 15.064C61.7709 15.392 61.5029 15.652 61.1589 15.844C60.8149 16.028 60.4229 16.12 59.9829 16.12ZM60.1989 14.872C60.6869 14.872 61.0789 14.708 61.3749 14.38C61.6789 14.052 61.8309 13.632 61.8309 13.12C61.8309 12.776 61.7629 12.472 61.6269 12.208C61.4909 11.944 61.2989 11.74 61.0509 11.596C60.8109 11.444 60.5269 11.368 60.1989 11.368C59.8789 11.368 59.5949 11.444 59.3469 11.596C59.1069 11.74 58.9149 11.944 58.7709 12.208C58.6349 12.472 58.5669 12.776 58.5669 13.12C58.5669 13.464 58.6349 13.768 58.7709 14.032C58.9149 14.296 59.1069 14.504 59.3469 14.656C59.5949 14.8 59.8789 14.872 60.1989 14.872ZM61.7469 16V14.452L61.9749 13.048L61.7469 11.656V10.24H63.0669V16H61.7469Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M94.5536 17.675H96.6612V21H80.3155C79.6947 21 79.1229 20.8417 78.6001 20.525C78.0936 20.2083 77.6852 19.7917 77.3748 19.275C77.0644 18.7417 76.9092 18.1583 76.9092 17.525V6.475C76.9092 5.84167 77.0644 5.26667 77.3748 4.75C77.6852 4.21667 78.0936 3.79167 78.6001 3.475C79.1229 3.15833 79.6947 3 80.3155 3H91.1473C91.7681 3 92.3317 3.15833 92.8382 3.475C93.361 3.79167 93.7776 4.21667 94.088 4.75C94.3984 5.26667 94.5536 5.84167 94.5536 6.475V17.675ZM80.4381 17.675H90.9757C91.0574 17.675 91.1309 17.65 91.1963 17.6C91.2616 17.5333 91.2943 17.4583 91.2943 17.375V6.625C91.2943 6.54167 91.2616 6.475 91.1963 6.425C91.1309 6.35833 91.0574 6.325 90.9757 6.325H80.4381C80.3564 6.325 80.2829 6.35833 80.2175 6.425C80.1685 6.475 80.144 6.54167 80.144 6.625V17.375C80.144 17.4583 80.1685 17.5333 80.2175 17.6C80.2829 17.65 80.3564 17.675 80.4381 17.675Z"
      fill="url(#paint0_linear_10_8016)"
    />
    <path
      d="M101.925 21C101.304 21 100.732 20.8417 100.209 20.525C99.7029 20.2083 99.2945 19.7917 98.9841 19.275C98.69 18.7583 98.543 18.1833 98.543 17.55V6.5H101.753V17.425C101.753 17.5083 101.778 17.5833 101.827 17.65C101.892 17.7 101.966 17.725 102.047 17.725H109.473C109.554 17.725 109.62 17.7 109.669 17.65C109.734 17.5833 109.767 17.5083 109.767 17.425V6.5H112.977V17.55C112.977 18.1833 112.822 18.7583 112.511 19.275C112.217 19.7917 111.817 20.2083 111.311 20.525C110.804 20.8417 110.232 21 109.595 21H101.925Z"
      fill="url(#paint1_linear_10_8016)"
    />
    <path
      d="M114.798 6.5L122.616 21H125.556L133.398 6.5H129.698L124.111 17.025L118.499 6.5H114.798Z"
      fill="url(#paint2_linear_10_8016)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10_8016"
        x1="76.9092"
        y1="3.21209"
        x2="91.4976"
        y2="38.3809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8329DD" />
        <stop offset="0.583333" stop-color="#3707BF" />
        <stop offset="0.76831" stop-color="#14008E" />
        <stop offset="1" stop-color="#000852" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10_8016"
        x1="76.9092"
        y1="3.21209"
        x2="91.4976"
        y2="38.3809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8329DD" />
        <stop offset="0.583333" stop-color="#3707BF" />
        <stop offset="0.76831" stop-color="#14008E" />
        <stop offset="1" stop-color="#000852" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_10_8016"
        x1="76.9092"
        y1="3.21209"
        x2="91.4976"
        y2="38.3809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8329DD" />
        <stop offset="0.583333" stop-color="#3707BF" />
        <stop offset="0.76831" stop-color="#14008E" />
        <stop offset="1" stop-color="#000852" />
      </linearGradient>
    </defs>
  </svg>
);
