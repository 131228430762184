import { analytics, useTracker } from '~/shared/analytics';
import { BodyPrimaryText, Icon, LinkButton, Title } from '~/shared/ui/kit';
import { Login } from '~/widgets/login';
import { useBuyPageContext } from './shared';
import { useReturnToMerchant } from './ui/BackButton';
import { MerchantFavicon } from './ui/MerchantFavicon';
import { MerchantLogo } from './ui/MerchantLogo';
import { PaymentAmount } from './ui/PaymentAmount';
import { PaymentHeader } from './ui/PaymentHeader';
import { SecurityBanner } from './ui/SecurityBanner';
import { UserAvatarPlaceholder } from './ui/user-avatar-placeholder';
import { PaymentViewProps, ViewContainer } from './ui/ViewContainer';
import { useTranslation } from 'react-i18next';
import { SetStepOnMount } from '~/shared/hooks/useStepProgress';
import { PayWithQuvioStep } from './views/shared';
import { AppLogoFilled, whiteLabelConfig } from '~/white-label';
import { EVENT_ID, pushDeferredEvent } from '~/shared/analytics/analytics';

export function NotAuthorizedView(props: PaymentViewProps) {
  const { params, merchant } = useBuyPageContext();
  const callback = useReturnToMerchant(params);
  const tracker = useTracker();
  const { t } = useTranslation('common');

  analytics.useOnMountEvent('buy_tokens.login');

  return (
    <SetStepOnMount<PayWithQuvioStep> step="login">
      <ViewContainer
        {...props}
        css={{
          position: 'relative',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <MerchantLogo merchant={merchant} css={{ marginBottom: 32 }} />
          <Title
            h={3}
            as={'h3'}
            css={{
              marginBottom: 8,
            }}
          >
            {t('paymentFlow.notAuthorizedTitle', {
              merchantName: merchant.address_name,
              appName: whiteLabelConfig.name,
            })}
          </Title>
          <BodyPrimaryText
            as={'p'}
            css={{
              marginBottom: 32,
              textAlign: 'center',
              maxWidth: 330,
            }}
          >
            {t('paymentFlow.notAuthorizedDescription', {
              merchantName: merchant.address_name,
            })}
          </BodyPrimaryText>

          <PaymentHeader
            css={{ marginBottom: 32, width: '100%' }}
            amount={
              <PaymentAmount
                currency={params.currency}
                amount={params.amount}
              />
            }
          >
            <UserAvatarPlaceholder />
            <Icon>
              <AppLogoFilled />
            </Icon>
            <MerchantFavicon merchant={merchant} />
          </PaymentHeader>

          <Login
            css={{
              width: '100%',
            }}
            initialEmail={params.email}
            onBeforeLoginRedirect={(provider) => {
              pushDeferredEvent(EVENT_ID.LOGIN, 'buy_tokens.login.success', {
                provider: provider,
              });
            }}
            onLoginFailure={(provider, message) => {
              tracker.track('buy_tokens.login.failure', {
                provider: provider,
                message: message,
              });
            }}
            onLoginStart={(provider) => {
              tracker.track('buy_tokens.login.start', {
                provider: provider,
              });
            }}
            onLoginSuccess={(provider) => {
              tracker.track('buy_tokens.login.success', {
                provider: provider,
              });
            }}
          ></Login>

          <LinkButton
            onClick={callback}
            css={{ marginTop: 32, textAlign: 'center' }}
          >
            {t('paymentFlow.cancelAndReturnToMerchant')}
          </LinkButton>

          <SecurityBanner css={{ marginTop: 32 }} />
        </div>
      </ViewContainer>
    </SetStepOnMount>
  );
}
