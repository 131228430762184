import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Recaptcha } from '~/shared/recaptcha';

import { Button, Buttons, InputBase, InputText } from '~/shared/ui/kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncAction } from '~/shared/hooks/useAsyncStatus';
import { ErrorMessage } from '~/shared/ui/kit/input-base/input-base';
import { isValidEmail } from '~/shared/validation/is-valid-email';
import { useTranslation } from 'react-i18next';
import { sendLoginEmail } from '../api';
import { errorResponseMessage, isFailedFetch } from '~/shared/api';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const schema: yup.ObjectSchema<{
  email: string;
  captchaToken: string;
}> = yup.object().shape({
  email: yup
    .string()
    .required('Type your email here')
    .email("That's not a valid email")
    .test(isValidEmail()),
  captchaToken: yup.string().required("You must prove you're not a robot"),
});
const resolver = yupResolver(schema);

export function EmailStep({
  initialEmail,
  onDone,
}: {
  initialEmail?: string;
  onDone: (data: { email: string; ticket: string }) => void;
}) {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { t } = useTranslation('common');
  const sendLoginEmailAction = useAsyncAction(sendLoginEmail);
  const captchaAction = useAsyncAction((captcha: ReCAPTCHA) => {
    return captcha.executeAsync();
  });

  const [submitError, setSubmitError] = useState<string | null>(null);
  const form = useForm({
    resolver,
    defaultValues: {
      email: initialEmail || '',
      captchaToken: '',
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: { email: string; captchaToken: string }) => {
    setSubmitError(null);
    try {
      const result = await sendLoginEmailAction.run(data);
      onDone({ email: data.email, ticket: result.data.ticket || '' });
    } catch (error) {
      if (isFailedFetch(error)) {
        setSubmitError(errorResponseMessage(error));
      } else {
        setSubmitError(t('errors.unknown'));
      }
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Controller
        control={form.control}
        name="email"
        render={({ field, fieldState }) => {
          return (
            <InputBase errorMessage={fieldState.error?.message}>
              <InputText
                label={t('login.emailFormLabel') || ''}
                {...field}
              ></InputText>
            </InputBase>
          );
        }}
      ></Controller>
      <Controller
        control={form.control}
        name="captchaToken"
        render={({ field }) => {
          return (
            <Recaptcha
              ref={captchaRef}
              onChange={field.onChange}
              css={{ marginTop: 24 }}
              size="invisible"
            />
          );
        }}
      ></Controller>

      {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
      <Buttons nButtons={1} css={{ marginTop: 24 }}>
        <Button
          semanticType="primary"
          // disabled={!form.formState.isValid}
          isLoading={sendLoginEmailAction.isLoading || captchaAction.isLoading}
          type="button"
          onClick={async () => {
            if (!captchaRef.current) {
              throw new Error('Captcha ref is not set');
            }

            await captchaAction.run(captchaRef.current);
            form.handleSubmit(onSubmit)();
          }}
        >
          {t('login.signInButton')}
        </Button>
      </Buttons>
    </form>
  );
}
