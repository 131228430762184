import useSWR from 'swr';
import invariant from 'tiny-invariant';
import {
  getConfig,
  getMerchantConfig,
  QuvioOrder,
  validateTransfer,
} from './api';

export function useOnRampConfig() {
  const data = useSWR('on-ramp-config', () => getConfig(), {
    refreshInterval: 1000 * 60 * 60,
  });

  return data;
}

export function useQuvioValidation(params: QuvioOrder | null) {
  const data = useSWR(
    params ? `quvio-validation-${params.signature}` : false,
    () => validateTransfer(params as QuvioOrder),
    {
      refreshInterval: 1000 * 60 * 60,
      errorRetryCount: 3,
      errorRetryInterval: 1000 * 5,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return data;
}

export function useMerchantConfig(params: QuvioOrder | null | undefined) {
  const canRunQuery = !!params;
  const config = useSWR(
    () => (canRunQuery ? 'buy-merchant-config' + params.client_id : false),
    () => {
      invariant(!!params, 'params are required');
      return getMerchantConfig(params);
    },
    {
      refreshInterval: 1000 * 60 * 60,
      errorRetryCount: 3,
      errorRetryInterval: 1000,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return config;
}
