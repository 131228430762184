import styled from '@emotion/styled';
import { media } from '../styles/media-query';

export const AppMargin = styled.div({
  margin: '0 8px',
  ...media(
    { min: 'md' },
    {
      margin: '0 16px',
    }
  ),
});

export const AppMarginDesktopOnly = styled.div({
  margin: '0 0',
  ...media(
    { min: 'md' },
    {
      margin: '0 16px',
    }
  ),
});
