import { ReceiveQr } from '~/features/receive/receive';
import { Modal } from '~/shared/ui/kit';
import { Props as ReactModalProps } from 'react-modal';

export function ReceiveModal(props: ReactModalProps) {
  return (
    <Modal
      {...props}
      css={{
        width: 480,
        maxWidth: '100%',
      }}
    >
      <ReceiveQr />
    </Modal>
  );
}
