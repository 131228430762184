import {
  erc721ABI,
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
} from 'wagmi';
import { useActiveChain } from '~/shared/ethereum/chain-provider';
import { HexString } from '~/shared/types';
import { getContract } from '@wagmi/core';
import { encodeFunctionData } from 'viem';
import { SendTransactionResult } from 'wagmi/dist/actions';
import { Nft } from 'alchemy-sdk';
import { SendConfig, useFeeEstimation } from '~/shared/signing-transactions';

export async function prepareTransferParameters({
  from,
  to,
  token,
}: {
  token: Nft;
  to: HexString;
  from: HexString;
}): Promise<SendConfig> {
  const contract = getContract({
    address: token.contract.address as HexString,
    abi: erc721ABI,
  });

  if (!contract) {
    throw new Error(
      'prepareTransferParameters: Contract is null. Provided token has no contract address. Impossible to populate transaction.'
    );
  }

  const data = encodeFunctionData({
    abi: erc721ABI,
    functionName: 'safeTransferFrom',
    args: [from, to, BigInt(token.tokenId)],
  });

  return {
    from,
    to,
    value: BigInt(1),
    data: data,
  };
}

export function useNftTransfering({
  from,
  to,
  token,
}: {
  from: HexString;
  to: HexString;
  token: Nft;
}): {
  error: Error | null | undefined;
  fee: ReturnType<typeof useFeeEstimation>;
  config?: SendConfig;
  sender: ReturnType<typeof useContractWrite>;
  submit?: () => Promise<SendTransactionResult>;
} {
  const activeChain = useActiveChain();
  const account = useAccount();

  const prepNftTransfer = usePrepareContractWrite({
    chainId: activeChain,
    address: token.contract.address as HexString,
    enabled: !!token,
    abi: erc721ABI,
    functionName: 'safeTransferFrom',
    args: [from, to, BigInt(token.tokenId)],
  });

  const data = encodeFunctionData({
    abi: erc721ABI,
    functionName: 'safeTransferFrom',
    args: [from, to, BigInt(token.tokenId)],
  });

  const tokenConfig: SendConfig | undefined = account.address
    ? {
        to,
        from: account.address,
        value: BigInt(0),
        data,
      }
    : undefined;

  const fee = useFeeEstimation(tokenConfig, 'erc721transfer');
  const nftTransfer = useContractWrite(prepNftTransfer.config);

  return {
    fee: fee,
    error: fee.error || nftTransfer.error || prepNftTransfer.error,
    config: tokenConfig,
    sender: nftTransfer,
    submit: nftTransfer.writeAsync,
  };
}
