import { useActiveChain } from '~/shared/ethereum/chain-provider';
import { Icon, LinkButton, LinkOutIcon } from '~/shared/ui/kit';
import { PropsWithChildren } from 'react';
import { polygon, polygonMumbai } from 'wagmi/chains';
import { useTranslation } from 'react-i18next';

export function ViewInExplorerMini({
  txHash,
  size,
  ...rest
}: PropsWithChildren<{
  txHash: string;
  size?: number;
}>) {
  const activeChain = useActiveChain();

  const href =
    activeChain === polygon.id
      ? `https://polygonscan.com/tx/${txHash}`
      : activeChain === polygonMumbai.id
      ? `https://www.oklink.com/ru/amoy/tx/${txHash}`
      : null;

  if (!href) {
    return <></>;
  }

  return (
    <LinkButton href={href} target="_blank" {...rest}>
      <Icon size={size}>
        <LinkOutIcon />
      </Icon>
    </LinkButton>
  );
}

export function ViewInExplorer({
  txHash,
  ...rest
}: PropsWithChildren<{
  txHash: string;
}>) {
  const { t } = useTranslation('common');
  const activeChain = useActiveChain();
  const text = t('glossary.viewInExplorer');

  if (activeChain === polygon.id) {
    return (
      <LinkButton
        href={`https://polygonscan.com/tx/${txHash}`}
        target="_blank"
        {...rest}
      >
        {text}
        <Icon css={{ marginLeft: 12 }}>
          <LinkOutIcon />
        </Icon>
      </LinkButton>
    );
  } else if (activeChain === polygonMumbai.id) {
    return (
      <LinkButton
        href={`https://www.oklink.com/ru/amoy/tx/${txHash}`}
        target="_blank"
        {...rest}
      >
        {text}
        <Icon css={{ marginLeft: 12 }}>
          <LinkOutIcon />
        </Icon>
      </LinkButton>
    );
  }

  return null;
}
