export type PayWithQuvioStep = 'login' | 'check' | 'details' | 'transfer';

export const steps: {
  title: string;
  key: PayWithQuvioStep;
}[] = [
  {
    title: 'Login',
    key: 'login',
  },
  {
    title: 'Check',
    key: 'check',
  },
  {
    title: 'Details',
    key: 'details',
  },

  {
    title: 'Transfer',
    key: 'transfer',
  },
];

export const stepNames: PayWithQuvioStep[] = steps.map((step) => step.key);
