import { observer } from 'mobx-react-lite';
import { NftItemCard } from '~/entities/nfts/ui/NftItemCard';
import { NftTransferFormValues, NftTransferModel } from './model';
import { Nft } from 'alchemy-sdk';
import {
  Button,
  Buttons,
  InputBase,
  InputText,
  ModalHeading,
} from '~/shared/ui/kit';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { isValidAddress } from '~/shared/validation/is-valid-address';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStepProgress } from '~/shared/hooks/useStepProgress';

const schema = yup.object({
  recipient: yup.string().required().test(isValidAddress()),
});
const resolver = yupResolver(schema);

function _AddressStep({
  nft,
  formValues,
  onSubmit,
  onCancel,
}: {
  nft: Nft;
  formValues: NftTransferFormValues;
  onSubmit: (values: Partial<NftTransferFormValues>) => void;
  onCancel: (e: React.MouseEvent) => void;
}) {
  const steps = useStepProgress();

  const form = useForm({
    defaultValues: {
      recipient: formValues.recipient,
    },
    resolver,
  });

  const handleSubmit = ({ recipient }: { recipient: string }) => {
    onSubmit({
      recipient,
    });
    steps.goForward();
  };

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      css={{ minHeight: 420, display: 'flex', flexDirection: 'column' }}
    >
      <ModalHeading css={{ marginBottom: 16 }}>Transfer</ModalHeading>
      <NftItemCard
        nft={nft}
        css={{
          marginBottom: 40,
        }}
      />
      <Controller
        control={form.control}
        name="recipient"
        render={({ field }) => {
          return (
            <InputBase>
              <InputText label="Address" {...field} />
            </InputBase>
          );
        }}
      ></Controller>

      <Buttons
        nButtons={2}
        css={{
          marginTop: 'auto',
          paddingTop: 24,
        }}
      >
        <Button type="button" semanticType="secondary" onClick={onCancel}>
          Back
        </Button>
        <Button
          type="submit"
          semanticType="primary"
          disabled={!form.formState.isValid}
        >
          Continue
        </Button>
      </Buttons>
    </form>
  );
}

export const AddressStep = observer(
  ({
    nftTransferModel,
    onClose,
  }: {
    nftTransferModel: NftTransferModel;
    onClose: (e: React.MouseEvent) => void;
  }) => {
    if (!nftTransferModel.token) {
      return <div>Nft is null</div>;
    }

    return (
      <_AddressStep
        nft={nftTransferModel.token}
        formValues={nftTransferModel.formValues}
        onSubmit={(values: Partial<NftTransferFormValues>) =>
          nftTransferModel.updateFormValues(values)
        }
        onCancel={onClose}
      ></_AddressStep>
    );
  }
);
