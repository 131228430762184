import { useTheme } from '@emotion/react';

type ClockIconProps = {
  color?: string;
};

export const ClockIcon = ({ color }: ClockIconProps) => {
  const theme = useTheme();
  const _color = color || theme.colors.neutral[80];
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 4.5V12H12.5"
        stroke={_color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.075 19.4999C5.40726 22.5086 6.74105 25.3184 8.86217 27.4779C10.9833 29.6375 13.7687 31.0215 16.7709 31.4077C19.7732 31.794 22.8182 31.16 25.4168 29.6076C28.0155 28.0552 30.017 25.6745 31.0999 22.8478C32.1829 20.0212 32.2844 16.9126 31.3882 14.0212C30.492 11.1299 28.6501 8.6237 26.1583 6.90505C23.6665 5.1864 20.6694 4.35507 17.6483 4.54455C14.6273 4.73404 11.7575 5.93336 9.5 7.9499L5 11.9999"
        stroke={_color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 10.5V18L24.5 21"
        stroke={_color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
