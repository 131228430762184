import { useTranslation } from 'react-i18next';
import { ActionMenu, ActionMenuItem } from '~/shared/ui/kit';

/**
 * P.S. Новые языки, которые нужно будет потом добавить:
- Испаниский (латиноамериканский),
- Португальский (бразильский).
 */
const languages = [
  {
    code: 'en',
    flag: '🇺🇸',
    name: 'English',
  },
  {
    code: 'es',
    flag: '🇪🇸',
    name: 'Español',
  },
  {
    code: 'br',
    flag: '🇧🇷',
    name: 'Português',
  },
];
export function ChangeLanguage({ ...rest }) {
  const { i18n } = useTranslation();
  const currentLanguage =
    languages.find((lang) => lang.code === i18n.language) || languages[0];
  return (
    <ActionMenu
      actions={languages.map((language) => (
        <ActionMenuItem
          key={language.code}
          onClick={() => {
            i18n.changeLanguage(language.code);
          }}
        >
          <span>{language.name}</span>
          <span>{language.flag}</span>
        </ActionMenuItem>
      ))}
      {...rest}
    >
      <div>{currentLanguage.flag}</div>
    </ActionMenu>
  );
}
