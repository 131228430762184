import { QuvioOrder } from '~/features/top-up';
import { useBuyPageContext } from './shared';
import { PayWithQuvioStep } from './views/shared';
import { ConfirmationView, PaymentView, StatusView } from './views';
import { Route, Routes } from 'react-router-dom';
import { ValidateOrder } from './validate-order';
import { PaymentViewProps } from './ui/ViewContainer';
import { SetStepOnMount } from '~/shared/hooks/useStepProgress';

function _AuthorizedView({
  params,
  topRightSlot,
  ...rest
}: PaymentViewProps & { params: QuvioOrder }) {
  return (
    <Routes>
      <Route
        path=""
        element={
          <ValidateOrder params={params}>
            <SetStepOnMount<PayWithQuvioStep> step="check">
              <ConfirmationView topRightSlot={topRightSlot} {...rest} />
            </SetStepOnMount>
          </ValidateOrder>
        }
      />
      <Route
        path=":transferId/top-up"
        element={
          <SetStepOnMount<PayWithQuvioStep> step="details">
            <PaymentView />
          </SetStepOnMount>
        }
        {...rest}
      />
      <Route
        path=":transferId/status"
        element={
          // step is called inside as different statuses render different steps
          <StatusView topRightSlot={topRightSlot} {...rest} />
        }
      />
    </Routes>
  );
}

export function AuthorizedView(props: PaymentViewProps) {
  const { params } = useBuyPageContext();

  return <_AuthorizedView params={params} {...props} />;
}
