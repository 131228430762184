export function UserAvatarPlaceholder() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="10" fill="#D9D9D9" />
      <rect
        width="32"
        height="32"
        rx="10"
        fill="url(#paint0_linear_1698_27501)"
      />
      <path
        d="M24 25C24 23.6044 24 22.9067 23.8278 22.3389C23.44 21.0605 22.4395 20.06 21.1611 19.6722C20.5933 19.5 19.8956 19.5 18.5 19.5H13.5C12.1044 19.5 11.4067 19.5 10.8389 19.6722C9.56045 20.06 8.56004 21.0605 8.17224 22.3389C8 22.9067 8 23.6044 8 25M20.5 11.5C20.5 13.9853 18.4853 16 16 16C13.5147 16 11.5 13.9853 11.5 11.5C11.5 9.01472 13.5147 7 16 7C18.4853 7 20.5 9.01472 20.5 11.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1698_27501"
          x1="-2.58628e-07"
          y1="0.377049"
          x2="35.4018"
          y2="27.5716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8329DD" />
          <stop offset="0.583333" stopColor="#3707BF" />
          <stop offset="0.76831" stopColor="#14008E" />
          <stop offset="1" stopColor="#000852" />
        </linearGradient>
      </defs>
    </svg>
  );
}
