import styled from '@emotion/styled';
import { Nft } from 'alchemy-sdk';
import { ImagePreview } from '~/shared/ui/kit';

const Img = styled.img({});

const AspectRatio = styled.div<{ aspectRatio: number }>(({ aspectRatio }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: `${(1 / aspectRatio) * 100}%`,
}));

const AspectRatioContent = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

const OutlinedIcon = styled.div(({ color, theme }) => ({
  width: '100%',
  height: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '28px',
  background: color || theme.colors.background,
}));

export function NftMedia({ token, ...rest }: { token: Nft }) {
  const fullImage = token.image.pngUrl;

  if (!fullImage) {
    return (
      <div {...rest}>
        <AspectRatio aspectRatio={1}>
          <AspectRatioContent>
            <OutlinedIcon>
              <ImagePreview />
            </OutlinedIcon>
          </AspectRatioContent>
        </AspectRatio>
      </div>
    );
  }

  return <Img src={fullImage} alt={token.name} {...rest} />;
}
