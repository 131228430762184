export const KeyIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4223 19.5934C29.5238 18.4904 30.3147 17.1163 30.7152 15.6098C31.1157 14.1034 31.1117 12.5179 30.7035 11.0135C30.2953 9.50911 29.4974 8.13904 28.3903 7.04164C27.2833 5.94424 25.9063 5.15835 24.3984 4.76332C22.8905 4.36829 21.305 4.37811 19.8021 4.79179C18.2992 5.20546 16.9321 6.00835 15.8387 7.11938C14.7454 8.23041 13.9645 9.61025 13.5749 11.1196C13.1854 12.6289 13.201 14.2143 13.6202 15.7157L4.86133 24.4738L5.21289 29.0336L11.008 30.6205L12.5057 27.3862L16.2625 25.3675L17.1449 22.7463L19.7662 21.8638C21.2807 22.2878 22.8807 22.3009 24.402 21.9019C25.9233 21.5029 27.3109 20.7061 28.4223 19.5934Z"
      stroke="#837F8A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.9088 13.8679C25.1755 13.8679 26.2024 12.8411 26.2024 11.5744C26.2024 10.3076 25.1755 9.28076 23.9088 9.28076C22.6421 9.28076 21.6152 10.3076 21.6152 11.5744C21.6152 12.8411 22.6421 13.8679 23.9088 13.8679Z"
      stroke="#837F8A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.21289 29.0335L15.7837 18.4634"
      stroke="#837F8A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
