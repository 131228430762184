import { CheckIcon, Icon } from '../icon';
import {
  StepContainer,
  StepName,
  StepNumberContainer,
  StepNumber,
  StepsContainer,
  LeftLineJoin,
  RightLineJoin,
} from './styles';
import { StepState, Step } from './types';

function getStateStep(
  index: number,
  currentStep: number,
  stepsCount: number
): StepState {
  if (index < 0) return 'void';
  if (index >= stepsCount) return 'void';

  return index < currentStep
    ? 'finished'
    : index === currentStep
    ? 'active'
    : 'next';
}

function StepNode({
  index,
  steps,
  currentStep,
}: {
  steps: Step[];
  currentStep: number;
  index: number;
}) {
  const state = getStateStep(index, currentStep, steps.length);
  const step = steps[index];

  const isFinished = state === 'finished';

  return (
    <StepContainer state={state}>
      <StepNumberContainer>
        <LeftLineJoin
          prevState={getStateStep(index - 1, currentStep, steps.length)}
          nextState={getStateStep(index, currentStep, steps.length)}
        />
        <StepNumber state={state}>
          {isFinished ? (
            <Icon>
              <CheckIcon />
            </Icon>
          ) : (
            index + 1
          )}
        </StepNumber>
        <RightLineJoin
          prevState={getStateStep(index, currentStep, steps.length)}
          nextState={getStateStep(index + 1, currentStep, steps.length)}
        />
      </StepNumberContainer>
      <StepName state={state}>{step.title}</StepName>
    </StepContainer>
  );
}

export function StepProgress({
  steps,
  currentStep,
  ...rest
}: {
  steps: Step[];
  currentStep: number;
}) {
  return (
    <StepsContainer stepsCount={steps.length} {...rest}>
      {steps.map((step, index) => {
        return (
          <StepNode
            steps={steps}
            index={index}
            currentStep={currentStep}
            key={step.title}
          ></StepNode>
        );
      })}
    </StepsContainer>
  );
}
