import { Token } from '~/entities/tokens';
import { TokenIcon } from '~/entities/tokens/ui/TokenIcon';
import { BodyPrimaryText } from '~/shared/ui/kit';
import { useTheme } from '@emotion/react';

export function FormSelectedAsset({ token, ...rest }: { token: Token }) {
  const theme = useTheme();

  return (
    <span
      css={{
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.neutral[80],
      }}
      {...rest}
    >
      <TokenIcon token={token} size={24} css={{ marginRight: 8 }}></TokenIcon>
      <BodyPrimaryText>{token.name}</BodyPrimaryText>
    </span>
  );
}
