import { px } from '~/shared/utils/css-helpers';
import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import { PropsWithChildren } from 'react';
import { Icon } from '../icon';
import { media } from '../styles/media-query';

type ActionProps = PropsWithChildren<
  HTMLAttributes<HTMLButtonElement> & { icon: JSX.Element }
>;

function _Action({ icon, children, ...rest }: ActionProps) {
  return (
    <button {...rest}>
      {icon}
      {children}
    </button>
  );
}

export const Action = styled(_Action)((props) => ({
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: props.theme.colors.neutral[20],

  fontWeight: 500,
  color: props.theme.colors.neutral[80],
  lineHeight: 1,

  '&:hover': {
    backgroundColor: props.theme.colors.neutral[30],
  },

  ...media(
    { max: 'md' },
    {
      padding: `${px(props.theme.spacer * 2)} ${px(props.theme.spacer * 2)}`,
      fontSize: 14,
      [Icon.toString()]: {
        marginBottom: props.theme.spacer,
      },
    }
  ),
  ...media('md', {
    flexDirection: 'row',
    padding: `${px(props.theme.spacer * 5)} ${px(props.theme.spacer * 6)}`,
    fontSize: 18,
    [Icon.toString()]: {
      marginRight: props.theme.spacer * 2.5,
    },
  }),
}));

type ActionGridProps = PropsWithChildren<{}>;

function _ActionGrid({ children, ...rest }: ActionGridProps) {
  return <div {...rest}>{children}</div>;
}

// TODO: make delimiters half-height instead of full-height
export const ActionGrid = styled(_ActionGrid)((props) => ({
  '--action-grid-border-radius': '20px',
  border: `1px solid ${props.theme.colors.neutral[30]}`,

  display: 'grid',
  gridTemplateColumns: `repeat(${React.Children.count(props.children)}, 1fr)`,

  [`${Action.toString()}:not(:first-of-type)`]: {
    borderLeft: `1px solid ${props.theme.colors.neutral[30]}`,
  },

  [`${Action.toString()}:first-of-type`]: {
    borderTopLeftRadius: 'var(--action-grid-border-radius)',
    borderBottomLeftRadius: 'var(--action-grid-border-radius)',
  },

  [`${Action.toString()}:last-of-type`]: {
    borderTopRightRadius: 'var(--action-grid-border-radius)',
    borderBottomRightRadius: 'var(--action-grid-border-radius)',
  },

  borderRadius: 'var(--action-grid-border-radius)',
  ...media(
    { max: 'md' },
    {
      '--action-grid-border-radius': '14px',
    }
  ),
}));
