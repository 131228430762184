import mixpanel from 'mixpanel-browser';
import { ENV_ANALYTICS_KEY } from '~/app/config';

// create an instance of the mixpanel client
mixpanel.init(ENV_ANALYTICS_KEY);

export const Analytics = {
  identify(id: string) {
    mixpanel.identify(id);
  },
  track: (event: string, properties?: any) => {
    mixpanel.track(event, properties);
  },
};

export function prefixedAnalytics(prefix: string) {
  return {
    track: (event: string, properties?: any) => {
      Analytics.track(`${prefix}.${event}`, properties);
    },
  };
}

// IDs are unique, there could not be two events with the same ID
export enum EVENT_ID {
  LOGIN,
}

type DeferredAnalyticsEvent = {
  eventId: EVENT_ID;
  event: string;
  properties?: any;
};

export function pushDeferredEvent(
  eventId: EVENT_ID,
  event: string,
  properties?: any
) {
  try {
    const deferredEvents: DeferredAnalyticsEvent[] = JSON.parse(
      localStorage.getItem('deferredEvents') || '[]'
    );
    // replace event if it already exists
    const index = deferredEvents.findIndex(
      (event: any) => event.eventId === eventId
    );

    if (index !== -1) {
      deferredEvents[index] = { eventId, event, properties };
    } else {
      deferredEvents.push({ eventId, event, properties });
    }

    localStorage.setItem('deferredEvents', JSON.stringify(deferredEvents));
  } catch (error) {
    // ignore LS errors
    // errors may happen, when client is in incognito mode or LS usage is restricted by browser settings
    return;
  }
}

// Remove the event from the list of deferred events and return it
export function retrieveDefferedEvent(
  eventId: EVENT_ID
): null | DeferredAnalyticsEvent {
  try {
    const deferredEvents = JSON.parse(
      localStorage.getItem('deferredEvents') || '[]'
    );
    const index = deferredEvents.findIndex(
      (event: any) => event.eventId === eventId
    );
    if (index === -1) {
      return null;
    }
    const event: DeferredAnalyticsEvent = deferredEvents[index];
    deferredEvents.splice(index, 1);
    localStorage.setItem('deferredEvents', JSON.stringify(deferredEvents));
    return event;
  } catch (error) {
    // ignore LS errors
    // errors may happen, when client is in incognito mode or LS usage is restricted by browser settings
    return null;
  }
}
