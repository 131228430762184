import { PropsWithChildren } from 'react';
import { QuvioOrder, useQuvioValidation } from '~/features/top-up';
import {
  firstErrorMessage,
  isFailedFetch,
  isValidationError,
} from '~/shared/api';
import {
  BodyPrimaryText,
  Button,
  Buttons,
  CardBase,
  CurtainLoader,
  Title,
} from '~/shared/ui/kit';
import { useReturnToMerchant } from '../ui/BackButton';
import { useTranslation } from 'react-i18next';

export function ValidateOrder(
  props: PropsWithChildren<{ params: QuvioOrder }>
) {
  const { t } = useTranslation('common');

  const validation = useQuvioValidation(props.params);
  const returnToMerchant = useReturnToMerchant(props.params);

  if (isValidationError(validation.data) || validation.error) {
    return (
      <CardBase
        css={{
          width: '100%',
          maxWidth: '520px',
          margin: '50px auto 20px auto',
        }}
      >
        <Title h={3} as={'h3'} css={{ marginBottom: 24 }}>
          {t('paymentFlow.validationFailedTitle')}
        </Title>
        <div css={{ marginBottom: 36 }}>
          <BodyPrimaryText as="p" css={{ marginBottom: 16 }}>
            {t('paymentFlow.validationFailedMessage')}{' '}
          </BodyPrimaryText>
          {isFailedFetch(validation.error) ? (
            <div>
              <BodyPrimaryText as="p" css={{ fontWeight: 700 }}>
                • {firstErrorMessage(validation.error.response?.detail || [])}
              </BodyPrimaryText>
            </div>
          ) : null}
        </div>
        <Buttons nButtons={1}>
          <Button semanticType="primary" onClick={returnToMerchant}>
            {t('paymentFlow.returnToMerchant')}
          </Button>
        </Buttons>
      </CardBase>
    );
  } else if (validation.data?.data.valid) {
    return <>{props.children}</>;
  }

  return <CurtainLoader text={t('paymentFlow.validationLoader')} />;
}
