import { Wallet, PopulatedTransaction } from 'ethers';

import invariant from 'tiny-invariant';
import { SafeEventEmitterProvider } from '@web3auth/base';
import { TransactionConfig, TransactionRequest } from './types';
import { Alchemy } from 'alchemy-sdk';
import { defaultGasLimit } from './gas';
import { SendConfig } from '~/features/send-tokens';
import { TransactionSerializableEIP2930 } from 'viem';
import { privateKeyToAccount } from 'viem/accounts';
import { HexString } from '../types';

function removeUndefined(obj: any) {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * Sets the gas price to {percentage} higher (or lower) than the current gas price
 * Example: if gas price is 100 gwei and percentage is 1.4, the gas price will be set to 140 gwei
 * @param gasPrice
 * @param percentage
 * @returns
 */
function adjustGasPrice(gasPrice: string, percentage: number) {
  return (
    (BigInt(gasPrice) * BigInt(Math.round(percentage * 100))) / BigInt(100)
  );
}

// @Deprecated
function normalizeTransactionRequest(
  config: TransactionRequest
): TransactionConfig {
  const preparedTxConfig = removeUndefined({
    type: config.type,
    chainId: config.chainId,
    nonce: config.nonce
      ? Number.parseInt(config.nonce?.toString() || '0')
      : undefined,
    gasPrice: config.gasPrice?.toString(),
    gasLimit: config.gasLimit,
    to: config.to,
    value: config.value,
    data: config.data,
    accessList: config.accessList,
  });
  return preparedTxConfig;
}
// @Deprecated
// use signTransactionLocally instead
export async function signTransaction({
  alchemy,
  config,
  provider,
}: {
  alchemy: Alchemy;
  provider: SafeEventEmitterProvider;
  config: PopulatedTransaction;
}) {
  const pk = await provider.request<string>({
    method: 'private_key',
  });
  invariant(pk, "Couldn't get private key");
  const wallet = new Wallet(pk);
  const from = await wallet.getAddress();
  const nonce = await provider.request<string>({
    method: 'eth_getTransactionCount',
    params: [from, 'latest'],
  });

  const chain = await alchemy.core.getNetwork();

  invariant(nonce, "Couldn't get nonce");

  const formattedTxConfig = normalizeTransactionRequest(config);
  const withChain = {
    ...formattedTxConfig,
    chainId: chain.chainId,
    nonce: nonce,
  };

  const signedTransaction = await wallet.signTransaction(withChain);
  return signedTransaction;
}

/**
 * Signs transaction locally using private key from the current provider
 * @returns raw signed transaction ready to be sent to the network
 */
export async function signTransactionLocally({
  provider,
  alchemy,
  config,
  chainId,
  transferType = 'unknown',
}: {
  provider: SafeEventEmitterProvider;
  config: SendConfig;
  alchemy: Alchemy;
  chainId: number;
  transferType?: 'erc20transfer' | 'transfer' | 'unknown';
}): Promise<string> {
  const gasPrice = await alchemy.core.getGasPrice();
  const pk = await provider.request<string>({
    method: 'private_key',
  });
  const nonce = await provider.request<string>({
    method: 'eth_getTransactionCount',
    params: [config.from, 'latest'],
  });

  invariant(pk, "Couldn't get private key");
  invariant(nonce, "Couldn't get nonce");

  const account = privateKeyToAccount(('0x' + pk) as HexString);

  let gasEstimations;

  try {
    gasEstimations = BigInt(
      (await alchemy.core.estimateGas(config)).toString()
    );
  } catch (error) {
    gasEstimations = defaultGasLimit(transferType);
  }

  const withChain: TransactionSerializableEIP2930 = {
    ...config,
    chainId: chainId,
    // adjust gas price by 40% to make sure it is never underpriced
    gasPrice: adjustGasPrice(gasPrice.toHexString(), 1.4),
    // maxFeePerGas: adjustGasPrice(gasPrice.toHexString(), 1.4),
    gas: gasEstimations,
    nonce: parseInt(nonce),
  };

  const signedTransaction = await account.signTransaction(withChain);
  return signedTransaction;
}
