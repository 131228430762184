import styled from '@emotion/styled';
import { media } from '../styles/media-query';

export const ModalHeading = styled.h2((props) => ({
  fontSize: 20,
  fontWeight: 900,
  lineHeight: '117%',
  letterSpacing: '-0.02em',
  color: props.theme.colors.neutral[100],
  ...media('lg', {
    fontSize: 24,
  }),
}));

export const ModalSubHeading = styled.p((props) => ({
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: '-0.02em',
  color: props.theme.colors.neutral[60],
}));
