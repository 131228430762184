import { useTheme } from '@emotion/react';

export const CheckboxIcon = ({ checked }: { checked: boolean }) => {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!checked && (
        <rect
          width="24"
          height="24"
          rx="6"
          stroke={theme.colors.neutral[40]}
          strokeWidth={2}
          strokeLinejoin="round"
        />
      )}
      {checked && (
        <>
          <rect width="24" height="24" rx="6" fill="#1D1C1E" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7071 7.29289C20.0976 7.68342 20.0976 8.31658 19.7071 8.70711L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L10 15.5858L18.2929 7.29289C18.6834 6.90237 19.3166 6.90237 19.7071 7.29289Z"
            fill="white"
          />
        </>
      )}
    </svg>
  );
};
