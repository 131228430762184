import {
  checkResponseCode,
  endpoint,
  ResponseType,
  ValidationResponseType,
} from '~/shared/api';
import { WidgetParams } from '@nearpay/nearpay-sdk/dist/interfaces/widget-parameters';
import { TransferStatus } from '~/entities/merchant/types';

export type OnRampConfig = {
  nearpay: {
    api_key: string;
    environment: 'stage' | 'production';
    currencies: {
      [currencyTicker: string]: { network: string; commodity: string };
    };
  };
  wert: {
    partner_id: string;
    environment: 'sandbox' | 'production';
    currencies: {
      [currencyTicker: string]: { network: string; commodity: string };
    };
  };
};

// export interface WidgetParams {
//     toCurrency?: string;
//     toAmount?: string;
//     toWallet?: string;
//     merchantOrderId?: string;
//     apiKey?: string;
//     contractCall?: ContractCall;
// }

/*
1) valdiate received params
2) create a transction and submit it to backend
3) receive txUUID and generate a NearPay widget params object
4) sign params object using Quvio backend
5) Show and iFrame with parameters
*/

export type QuvioOrder = {
  // This is Quvio's signature
  signature: string;
  amount: string;
  currency: string;
  order_id: string;
  client_id: string;
  redirect_url?: string;
  email?: string;
  sumsub_token?: string;
};

const configURL = endpoint('/v1/on-ramp/config');
export async function getConfig() {
  const response = (await fetch(configURL).then((res) =>
    res.json()
  )) as ResponseType<OnRampConfig>;

  return response;
}

export type MerchantConfig = {
  receiving_address: string;
  widget: 'nearpay' | 'wert';
  is_active: boolean;
  address_name: string | null;
  address_logo: string | null;
  address_icon: string | null;
};

export async function getMerchantConfig(
  params: QuvioOrder
): Promise<ResponseType<MerchantConfig>> {
  const merchantConfigURL = endpoint('/v1/merchant/info');
  const response = await fetch(merchantConfigURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      order_id: params.order_id,
      amount: params.amount,
      currency: params.currency,
      signature: params.signature,
      client_id: params.client_id,
      email: params.email || '',
      sumsub_token: params.sumsub_token || '',
    }),
  }).then(checkResponseCode);

  return response.json();
}

type QuvioValidateResponse =
  | ResponseType<{
      valid: boolean;
    }>
  | ValidationResponseType;

export async function validateTransfer(
  params: QuvioOrder
): Promise<QuvioValidateResponse> {
  const validateTransferURL = endpoint('/v1/merchant/order');
  const response = await fetch(validateTransferURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      order_id: params.order_id,
      amount: params.amount,
      currency: params.currency,
      signature: params.signature,
      client_id: params.client_id,
      email: params.email || '',
      sumsub_token: params.sumsub_token || '',
    }),
  }).then(checkResponseCode);

  return response.json();
}

const createTransferURL = endpoint('/v1/transfer');
type CreateTransferResponse = {
  unique_id: string;
};
export async function createTransfer({
  rawTx,
  orderId,
  sender,
  clientId,
}: {
  rawTx: string;
  sender: string;
  orderId: string;
  clientId: string;
}): Promise<ResponseType<CreateTransferResponse>> {
  const response = await fetch(createTransferURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      raw_tx: rawTx,
      order_id: orderId,
      sender,
      client_id: clientId,
    }),
  });

  return response.json();
}

type SignResponse = ResponseType<{
  link: string;
}>;
const signParamsURL = endpoint('/v1/on-ramp/signature');
export async function signTopUpParameters(
  parameters: WidgetParams & {
    email?: string;
    sumsub_token?: string;
  }
): Promise<SignResponse> {
  const response = await fetch(signParamsURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input_data: {
        toCurrency: parameters.toCurrency,
        toAmount: parameters.toAmount,
        toWallet: parameters.toWallet,
        merchantOrderId: parameters.merchantOrderId,
        email: parameters.email,
        sumsub_token: parameters.sumsub_token,
      },
    }),
  });

  return response.json();
}

const getTransferStatusURL = (orderId: string) =>
  endpoint(`/v1/transfer/${orderId}`);
type TransferStatusResponse = ResponseType<{
  unique_id: string;
  status: TransferStatus;
}>;

export async function getTransferStatus(
  transferId: string
): Promise<TransferStatusResponse> {
  const response = await fetch(getTransferStatusURL(transferId));

  return response.json();
}
