import { findLocaleFromLanguage } from '~/features/i18n';

function prefixZero(num: number) {
  return num < 10 ? `0${num}` : num;
}

// formats ISO datestring into YYYY MMM DD hh:mm
export const formatIsoDate = (date: string, language: string) => {
  const locale = findLocaleFromLanguage(language);
  const d = new Date(date);

  return `${d.getFullYear()} ${d.toLocaleString(locale, {
    month: 'short',
  })} ${prefixZero(d.getDate())} ${prefixZero(d.getHours())}:${prefixZero(
    d.getMinutes()
  )}`;
};
