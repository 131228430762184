import { px } from '~/shared/utils/css-helpers';
import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
export { CopyIcon } from './icons/copy';
export { ReceiveIcon } from './icons/receive';
export { SendIcon } from './icons/send';
export { RequestIcon } from './icons/request';
export { TxInIcon } from './icons/tx-in';
export { TxOutIcon } from './icons/tx-out';
export { ClockIcon } from './icons/clock';
export { ChevDownIcon } from './icons/chev-down';
export { WalletIcon } from './icons/wallet';
export { LogoutIcon } from './icons/logout';
export { CloseIcon } from './icons/close';
export { CheckOutlinedIcon } from './icons/check-outlined';
export { LinkOutIcon } from './icons/link-out';
export { AttentionIcon } from './icons/attention';
export { KeyIcon } from './icons/key';
export { CheckboxIcon } from './icons/checkbox';
export { CheckIcon } from './icons/check';
export { ImagePreview } from './icons/image-preview';
export { UserIcon } from './icons/user';

const setSize = (size?: number | string) =>
  size
    ? {
        [`& > svg`]: {
          width: size,
          height: size,
        },
        [`& > img`]: {
          width: size,
          height: size,
        },
      }
    : {};
export const Icon = styled.i(({ size }: { size?: number | string }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...setSize(size),
}));

export type IconSize = 'small' | 'medium' | 'large';
function _OutlinedIcon({
  children,
  ...rest
}: PropsWithChildren<{ color?: string; size?: IconSize | number }>) {
  return <i {...rest}>{children}</i>;
}

export const OutlinedIcon = styled(_OutlinedIcon)(
  ({ color, theme, size = 'large' }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:
      size === 'large'
        ? px(20)
        : size === 'medium'
        ? px(14)
        : size === 'small'
        ? px(10)
        : size / 4,
    width:
      size === 'large'
        ? 68
        : size === 'medium'
        ? 44
        : size === 'small'
        ? 32
        : size,
    height:
      size === 'large'
        ? 68
        : size === 'medium'
        ? 44
        : size === 'small'
        ? 32
        : size,
    //border: `1px solid ${theme.colors.neutral[20]}`,
    padding:
      size === 'large'
        ? 16
        : size === 'medium'
        ? 10
        : size === 'small'
        ? 6
        : size / 8,
    background: color || theme.colors.background,
  })
);
