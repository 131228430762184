import { ListOfCards } from '~/shared/ui/kit';
import { Token } from '..';
import { TokenListItem } from './TokenListItem';

type TransactionListProps = React.PropsWithChildren<{
  tokens: Token[];
}>;

export function TokenList({ tokens, ...rest }: TransactionListProps) {
  return (
    <ListOfCards spacing={4} {...rest}>
      {tokens.map((token) => (
        <TokenListItem
          isClickable={false}
          key={token.contract}
          token={token}
          fiatExchangeRate={12.33}
        />
      ))}
    </ListOfCards>
  );
}
