import { MarkdownZone, SecondaryMaterial } from '~/shared/ui/kit';
import shieldIconSvg from './shield-icon.svg';
import { useTranslation, Trans } from 'react-i18next';
import { whiteLabelConfig } from '~/white-label';

export function SecurityBanner({ ...rest }) {
  const { t } = useTranslation('common');
  return (
    <SecondaryMaterial
      css={{
        padding: '10px 10px 10px 16px',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
      }}
      {...rest}
    >
      <img
        src={shieldIconSvg}
        css={{
          width: '32px',
          height: '32px',
          boxShadow:
            '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
        }}
      />
      <MarkdownZone css={{ marginLeft: '12px', fontSize: '14px' }}>
        <Trans
          i18nKey={'paymentFlow.securityBanner'}
          t={t}
          components={[<a />]}
          values={{
            appName: whiteLabelConfig.name,
          }}
        ></Trans>
      </MarkdownZone>
    </SecondaryMaterial>
  );
}
