import {
  StepProgressProvider,
  useStepProgress,
} from '~/shared/hooks/useStepProgress';
import { AddressStep } from './address-step';
import { ConfirmStep } from './confirm-step';
import { NftTransferFormValues, nftTransferModel } from './model';
import { observer } from 'mobx-react-lite';
import { TransferFormStep, transferFormSteps } from './types';
import { TransactionReceipt } from '~/entities/transactions/ui/TransactionReceipt/transaction-receipt';

function _TransferForm({
  formValues,
  onClose,
}: {
  formValues: NftTransferFormValues;
  onClose: (e: React.MouseEvent) => void;
}) {
  const steps = useStepProgress<TransferFormStep>();

  if (formValues.transactionHash) {
    return (
      <TransactionReceipt
        txHash={formValues.transactionHash}
        onClose={onClose}
      />
    );
  }

  if (steps.step === 'enterRecipient') {
    return (
      <AddressStep nftTransferModel={nftTransferModel} onClose={onClose} />
    );
  } else if (steps.step === 'confirm') {
    return <ConfirmStep nftTransferModel={nftTransferModel} />;
  }

  return <div></div>;
}

export const NftTransferForm = observer(
  ({ onClose }: { onClose: (e: React.MouseEvent) => void }) => {
    return (
      <StepProgressProvider
        steps={transferFormSteps}
        initialStep="enterRecipient"
      >
        <_TransferForm
          onClose={onClose}
          formValues={nftTransferModel.formValues}
        />
      </StepProgressProvider>
    );
  }
);
