import { AppLogo } from '~/white-label';

import styled from '@emotion/styled';
import { AccountMenu } from '../account-menu';
import { AccountMenu as MerchantAccountMenu } from '~/features/merchant/logout';
import {
  NavigationLink,
  NavigationLinks,
  useMediaQuery,
} from '~/shared/ui/kit';
import { useMerchantSettings } from '~/pages/merchant-zone/data-hooks';
import { ChangeLanguage } from '~/features/i18n/change-language';
import { useTranslation } from 'react-i18next';
import { HamburgerMenu } from '~/shared/ui/kit/hamburger-menu';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
const shadow =
  '0px 38px 18px rgba(167, 188, 211, 0.01), 0px 18px 15px rgba(167, 188, 211, 0.04), 0px 6px 11px rgba(167, 188, 211, 0.06), 0px 2px 6px rgba(167, 188, 211, 0.04), 0px 0px 0px rgba(167, 188, 211, 0.06)';

const HEADER_HEIGHT = 72;

const Container = styled.div((props) => ({
  height: HEADER_HEIGHT,
  boxShadow: shadow,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: props.theme.colors.neutral[10],
  padding: '0 16px',
}));

const InnerContainer = styled.div((props) => ({
  maxWidth: 1280,
  padding: '16px 0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export function Header() {
  return (
    <Container>
      <InnerContainer>
        <Link
          to="/"
          css={{
            marginRight: 28,
            flexShrink: 0,
          }}
        >
          <AppLogo
            css={{
              width: 93,
            }}
          />
        </Link>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChangeLanguage
            css={{
              marginRight: 8,
            }}
          />
          <AccountMenu />
        </div>
      </InnerContainer>
    </Container>
  );
}

const HeaderMobileMenu = styled.div((props) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  backgroundColor: props.theme.colors.neutral[10],
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 16px',
}));

export function MerchantHeader() {
  const settings = useMerchantSettings();
  const [isOpened, setIsOpened] = useState(false);

  const { t } = useTranslation('merchant');
  const isMd = useMediaQuery({ min: 'md' });
  const location = useLocation();

  useEffect(() => {
    setIsOpened(false);
  }, [location.pathname]);

  const navigationLinks = (
    <NavigationLinks
      css={{
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: isMd ? 'row' : 'column',
      }}
    >
      <NavigationLink to="/merchant/dashboard">
        {t('navigation.dashboard')}
      </NavigationLink>
      <NavigationLink to="/merchant/dashboard/transactions">
        {t('navigation.transactions')}
      </NavigationLink>
      {settings.data?.data.client_id ? (
        <NavigationLink to="/merchant/dashboard/playground">
          {t('navigation.playground')}
        </NavigationLink>
      ) : null}
    </NavigationLinks>
  );

  return (
    <Container
      css={{
        position: 'relative',
      }}
    >
      <InnerContainer>
        <AppLogo css={{ marginRight: 28, flexShrink: 0, width: 93 }} />
        {isMd ? (
          <>
            {navigationLinks}
            <div css={{ flexGrow: 1 }} />
            <ChangeLanguage
              css={{
                marginRight: 8,
              }}
            />
            <MerchantAccountMenu />
          </>
        ) : (
          <>
            <div css={{ flexGrow: 1 }} />
            <MerchantAccountMenu />

            <HamburgerMenu
              isOpened={isOpened}
              onClick={() => {
                setIsOpened(!isOpened);
              }}
            />
          </>
        )}
      </InnerContainer>
      {!isMd && isOpened ? (
        <HeaderMobileMenu>
          {navigationLinks}
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginTop: 40,
            }}
          >
            <ChangeLanguage
              css={{
                marginRight: 8,
              }}
            />
          </div>
        </HeaderMobileMenu>
      ) : null}
    </Container>
  );
}

export function TransparentHeader({ ...rest }) {
  return (
    <div
      css={{
        padding: '10px 20px',
        display: 'flex',
        height: 60,
      }}
      {...rest}
    >
      <ChangeLanguage
        css={{
          marginLeft: 'auto',
          position: 'relative',
          zIndex: 1,
        }}
      />
    </div>
  );
}
