import { ColorThemeType } from '../theme-provider';
import { CSSPropertiesWithMultiValues } from '@emotion/serialize';
import styled from '@emotion/styled';

const LETTER_SPACING = '-0.02em';

export const sublineText = (
  theme: ColorThemeType
): CSSPropertiesWithMultiValues => ({
  color: theme.colors.neutral[60],
  fontSize: 14,
  fontWeight: 500,
});

export const jakartaText = (
  theme: ColorThemeType
): CSSPropertiesWithMultiValues => ({
  fontFamily: 'Plus Jakarta Sans',
});

export const SublineText = styled.span(({ theme }) => sublineText(theme));

export const Title = styled.span<{
  h: 1 | 2 | 3 | 4 | 5 | 6;
}>(({ theme, h, ...rest }) => ({
  fontWeight: 700,
  letterSpacing: LETTER_SPACING,
  color: theme.colors.neutral[100],
  lineHeight: 1.333,
  fontSize:
    h === 2
      ? 28
      : h === 3
      ? 24
      : h === 4
      ? 20
      : h === 5
      ? 16
      : h === 6
      ? 14
      : 16,
}));

export const BodyPrimaryText = styled.span<{}>(({ theme }) => ({
  letterSpacing: LETTER_SPACING,
  fontWeight: 500,
  lineHeight: 1.5,
  color: theme.colors.neutral[80],
}));

export const MarkdownLink = styled.a<{}>(({ theme }) => ({
  color: theme.colors.purple[120],
}));
