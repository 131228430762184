export const CopyIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2857 5.71429V11.7857C14.2857 13.1664 13.1664 14.2857 11.7857 14.2857H5.71429M14.2857 5.71429V5C14.2857 3.61929 13.1664 2.5 11.7857 2.5H5C3.61929 2.5 2.5 3.61929 2.5 5V11.7857C2.5 13.1664 3.61929 14.2857 5 14.2857H5.71429M14.2857 5.71429H15C16.3807 5.71429 17.5 6.83357 17.5 8.21429V15C17.5 16.3807 16.3807 17.5 15 17.5H8.21429C6.83358 17.5 5.71429 16.3807 5.71429 15V14.2857"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
