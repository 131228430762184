import type { PropsWithChildren } from 'react';
import type { Transaction } from '../..';
import type { FiatBalance } from '~/shared/fiat-converter';
import { NftTransactionItem } from './NftTransactionItem';
import { TokenTransactionItem } from './TokenTransactionItem';

type TransactionItemProps = PropsWithChildren<{
  tx: Transaction;
  account: string;
  fiatValue?: FiatBalance;
}>;

/**
 * Detects which transaction type and routes to corresponding component
 * @param param0
 * @returns
 */
export function TransactionItem({
  account,
  tx,
  ...rest
}: TransactionItemProps) {
  const isNft = tx.category === 'erc721';

  if (isNft) {
    return <NftTransactionItem tx={tx} account={account} {...rest} />;
  }

  return <TokenTransactionItem tx={tx} account={account} {...rest} />;
}
