const languageToLocaleMap: { [k: string]: string } = {
  en: 'en-US',
  es: 'es-ES',
  pt: 'pt-BR',
  br: 'pt-BR',
};

// Finds the locale from the language
// language will be in the format en, es, pt
// supports en-US, es-ES, pt-BR
export function findLocaleFromLanguage(language: string) {
  return languageToLocaleMap[language] || 'en-US';
}
