import { MerchantTransaction, useMerchantContext } from '~/entities/merchant';
import useSWR from 'swr';
import styled from '@emotion/styled';
import { TransferStatus } from '~/entities/merchant/types';
import {
  BodyPrimaryText,
  CardBase,
  ChevDownIcon,
  Icon,
  IconButton,
  LinkButton,
  Spinner,
} from '~/shared/ui/kit';
import { useFiatValue } from '~/shared/fiat-converter';
import { formatIsoDate } from '~/shared/utils/date-helpers';
import { useState } from 'react';
import { TransactionsEmpty } from '~/entities/transactions/ui/TransactionsEmpty';
import { useTranslation } from 'react-i18next';

const TableHeaderCell = styled.th(({ theme }) => ({
  textAlign: 'left',
  fontWeight: 500,
  color: theme.colors.neutral[60],
  paddingBottom: 16,
}));
const TableBodyCell = styled.td(({ theme }) => ({
  padding: '0px 0px 16px 0px',
}));
const TransactionStatus = styled.div<{ status: TransferStatus }>(
  ({ status, theme }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor:
      status === TransferStatus.ORDER_COMPLETED
        ? theme.colors.success[20]
        : status < 0
        ? theme.colors.critical[20]
        : theme.colors.info[20],
    color:
      status === TransferStatus.ORDER_COMPLETED
        ? theme.colors.success[120]
        : status < 0
        ? theme.colors.critical[120]
        : theme.colors.info[120],
    fontWeight: 500,
    padding: '4px 12px',
    borderRadius: '10px',
  })
);
const transactionStatusText: { [key in TransferStatus]: string } = {
  [TransferStatus.NONE]: '',
  [TransferStatus.ORDER_COMPLETED]: 'Completed',
  [TransferStatus.ORDER_BLOCKCHAIN_ERROR]: 'Error: blockchain',
  [TransferStatus.ORDER_CREATED]: 'Created',
  [TransferStatus.ORDER_FIAT_ACCEPTED]: 'Fiat accepted',
  [TransferStatus.ORDER_FIAT_ERROR]: 'Error: fiat',
  [TransferStatus.ORDER_FIAT_COMPLETED]: 'Fiat completed',
  [TransferStatus.BLOCKCHAIN_WAITING_FOR_CONFIRMATION]:
    'Blockchain confirmation',
};

const Container = styled.div({
  maxWidth: 884,
  width: '100%',
  margin: '34px auto 0 auto',
});

const TransactionsTable = styled.table({
  width: '100%',
});

const TxValueFiat = styled.div((props) => ({
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: '-0.02em',
  color: props.theme.colors.neutral[60],
}));

function TransactionTableItem({
  transaction,
}: {
  transaction: MerchantTransaction;
}) {
  const { i18n } = useTranslation();
  const fiatValue = useFiatValue({
    value: Number(transaction.formatted_amount),
    symbol: transaction.currency,
  });

  return (
    <tr>
      <TableBodyCell>
        {formatIsoDate(transaction.created_at, i18n.language)}
      </TableBodyCell>
      <TableBodyCell>{transaction.email}</TableBodyCell>
      <TableBodyCell>
        <TransactionStatus status={transaction.status}>
          {transactionStatusText[transaction.status]}
        </TransactionStatus>
      </TableBodyCell>
      <TableBodyCell
        css={{
          textAlign: 'right',
        }}
      >
        {transaction.formatted_amount} {transaction.currency}
        <TxValueFiat>{fiatValue.formatted}</TxValueFiat>
      </TableBodyCell>
    </tr>
  );
}

const calcMaxPage = (total: number, perPage: number) => {
  return Math.ceil(total / perPage);
};
export function MerchantTransactions() {
  const { t } = useTranslation('merchant');

  const { api, token } = useMerchantContext();

  const [page, setPage] = useState(0);

  const {
    data: transactions,
    isLoading,
    error,
  } = useSWR(
    ['transactions', page, token?.access_token],
    ([key, page]) => api.getTransactions(page + 1),
    {}
  );

  const isEmpty = transactions?.total === 0;
  const hasPages = transactions && transactions?.total > transactions?.size;

  const maxPage = calcMaxPage(
    transactions?.total || 0,
    transactions?.size || 1
  );

  const pagination = {
    canGoBack: page > 0,
    canGoForward: page < maxPage - 1,
  };

  const dateTimeHeaderText = t('transactions.dateTimeHeader');
  const emailHeaderText = t('transactions.emailHeader');
  const statusHeaderText = t('transactions.statusHeader');
  const amountHeaderText = t('transactions.amountHeader');

  return (
    <Container>
      <CardBase
        css={{
          overflow: 'auto',
        }}
      >
        <TransactionsTable
          css={{
            minWidth: 620,
          }}
        >
          <thead>
            <tr>
              <TableHeaderCell>{dateTimeHeaderText}</TableHeaderCell>
              <TableHeaderCell>{emailHeaderText}</TableHeaderCell>
              <TableHeaderCell>{statusHeaderText}</TableHeaderCell>
              <TableHeaderCell>{amountHeaderText}</TableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {(transactions?.data || []).map((transaction) => (
              <TransactionTableItem
                transaction={transaction}
                key={transaction.unique_id}
              />
            ))}
          </tbody>
        </TransactionsTable>

        {isLoading || error ? (
          <div
            css={{
              minHeight: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Spinner />
            {error ? (
              <BodyPrimaryText>
                There was an error loading transactions, retrying...
              </BodyPrimaryText>
            ) : null}
          </div>
        ) : isEmpty ? (
          <TransactionsEmpty />
        ) : null}

        {hasPages ? (
          <div
            css={{
              marginTop: 24,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton
              disabled={!pagination.canGoBack}
              onClick={() => setPage(page - 1)}
              css={{
                visibility: pagination.canGoBack ? 'visible' : 'hidden',
              }}
            >
              <Icon
                css={{
                  transform: 'rotate(90deg)',
                }}
              >
                <ChevDownIcon />
              </Icon>
            </IconButton>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1,
              }}
            >
              <LinkButton>
                Page {page + 1} of {maxPage}
              </LinkButton>
            </div>
            <IconButton
              disabled={!pagination.canGoForward}
              onClick={() => setPage(page + 1)}
              css={{
                visibility: pagination.canGoForward ? 'visible' : 'hidden',
              }}
            >
              <Icon
                css={{
                  transform: 'rotate(-90deg)',
                }}
              >
                <ChevDownIcon />
              </Icon>
            </IconButton>
          </div>
        ) : null}
      </CardBase>
    </Container>
  );
}
