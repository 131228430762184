import styled from '@emotion/styled';

export const IconButton = styled.button(({ theme }) => ({
  width: 60,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.colors.neutral[30]}`,
  borderRadius: '50%',
  padding: 12,

  '& > *': {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },

  '&:hover': {
    backgroundColor: theme.colors.neutral[20],
  },
}));
