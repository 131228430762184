import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

import {
  BodyPrimaryText,
  Button,
  Buttons,
  LinkButton,
  MarkdownLink,
  SublineText,
  useMediaQuery,
} from '~/shared/ui/kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncAction } from '~/shared/hooks/useAsyncStatus';
import { ErrorMessage } from '~/shared/ui/kit/input-base/input-base';
import { Trans, useTranslation } from 'react-i18next';
import { loginUser } from '../api';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { OpenloginLoginParams } from '@web3auth/openlogin-adapter';
import { web3AuthInstance } from '~/shared/ethereum/web3auth/connector/wagmi';
import OtpInput18 from 'react18-input-otp';
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { errorResponseMessage, isFailedFetch } from '~/shared/api';
import { Checkbox } from '~/shared/ui/kit/checkbox';
import { whiteLabelConfig } from '~/white-label';

const schema: yup.ObjectSchema<{
  ticket: string;
}> = yup.object().shape({
  ticket: yup.string().required('Type your OTP here').length(6),
  legal: yup.boolean().oneOf([true], 'You must accept the terms'),
});

const resolver = yupResolver(schema);

export function OtpStep({
  email,
  ticket,
  onBeforeLoginRedirect,
  onDone,
  onGoBack,
  onError,
}: {
  email: string;
  ticket: string;
  onBeforeLoginRedirect: () => void;
  onDone: () => void;
  onGoBack: () => void;
  onError: (error: string) => void;
}) {
  const theme = useTheme();
  const isMd = useMediaQuery({ min: 'md' });
  const { t } = useTranslation('common');
  const loginUserAction = useAsyncAction(loginUser);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const form = useForm({
    resolver,
    defaultValues: {
      ticket: ticket,
      legal: true,
    },
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: { ticket: string }) => {
    setSubmitError(null);

    try {
      // Fetch JWT token from backend
      const result = await loginUserAction.run({
        email,
        ticket: data.ticket,
      });

      const token = result.data.id_token;

      onBeforeLoginRedirect();
      // Submit JWT token to Openlogin
      const promise = web3AuthInstance.connectTo<OpenloginLoginParams>(
        WALLET_ADAPTERS.OPENLOGIN,
        {
          loginProvider: 'jwt',
          appState: window.location.search,
          extraLoginOptions: {
            id_token: token,
            verifierIdField: 'email',
          },
        }
      );

      console.debug('Openlogin login promise', promise);

      if (promise) {
        await promise;
      }

      onDone();
    } catch (error: any) {
      // HACK: ignoring this error. It's a bug, probably inside of Openlogin
      if (
        error.message.includes(`can't access property "then", target is null`)
      ) {
        return;
      } else if (
        error.message.includes(
          'Failed to connect with wallet. Already connected'
        )
      ) {
        window.location.reload();
      } else if (isFailedFetch(error)) {
        const message = errorResponseMessage(error);
        setSubmitError(message);
        onError(message);
      } else {
        setSubmitError(t('errors.unknown'));
        onError(error.message);
      }
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <BodyPrimaryText
        css={{
          color: theme.colors.neutral[60],
          textAlign: 'center',
          marginBottom: 16,
        }}
        as="p"
      >
        <Trans
          t={t}
          i18nKey={'login.otpSentDescription'}
          components={[<b />]}
          values={{ email }}
        ></Trans>
      </BodyPrimaryText>
      <Controller
        control={form.control}
        name="ticket"
        render={({ field, fieldState }) => {
          const errorMessage = fieldState.error?.message || submitError;
          return (
            <div>
              <OtpInput18
                value={field.value}
                onChange={field.onChange}
                numInputs={6}
                containerStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                inputStyle={{
                  fontSize: isMd ? 32 : 24,
                  width: isMd ? 48 : 32,
                  height: isMd ? 60 : 42,
                  margin: '0 4px',
                  border: `1px solid ${theme.colors.neutral[30]}`,
                  flexGrow: 1,
                  borderRadius: isMd ? 12 : 8,
                }}
              ></OtpInput18>
              {errorMessage && (
                <ErrorMessage as="div" css={{ marginTop: 6 }}>
                  {errorMessage}
                </ErrorMessage>
              )}
            </div>
          );
        }}
      ></Controller>
      <Controller
        control={form.control}
        name="legal"
        render={({ field, fieldState }) => {
          return (
            <Checkbox
              {...field}
              css={{
                marginTop: 24,
              }}
            >
              <SublineText
                css={{
                  color: theme.colors.neutral[60],
                  lineHeight: 1,
                }}
              >
                <Trans
                  t={t}
                  i18nKey={'login.legalAgreement'}
                  components={[
                    <MarkdownLink
                      href={whiteLabelConfig.tosEmailLogin}
                      target="_blank"
                    ></MarkdownLink>,
                  ]}
                ></Trans>
              </SublineText>
            </Checkbox>
          );
        }}
      ></Controller>
      <Buttons nButtons={1} css={{ marginTop: 12 }}>
        <Button
          semanticType="primary"
          disabled={!form.formState.isValid}
          isLoading={loginUserAction.isLoading}
        >
          {t('login.signInButton')}
        </Button>
      </Buttons>
      <LinkButton
        onClick={onGoBack}
        css={{
          display: 'block',
          textAlign: 'center',
          marginTop: 12,
        }}
      >
        Cancel
      </LinkButton>
    </form>
  );
}
